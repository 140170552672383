@import url('https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese');

// MATERIAL COLOR
$red: #F44336;
$pink: #E91E63;
$purple: #9C27B0;
$deeppurple: #673AB7;
$indigo: #3F51B5;
$blue: #2196F3;
$lightblue: #03A9F4;
$cyan: #00BCD4;
$teal: #009688;
$green: #367639;
$lightgreen: #8dc63f;
$lime: #CDDC39;
$yellow: #FFEB3B;
$amber: #FFC107;
$orange: #FF9800;
$deeporange: #FF5722;
$brown: #946b3a;
$lbrown: #d69b28;
$dbrown: #7b2e00;
$grey: #212121;
$bluegrey: #263238;

// CUSTOM COLOR
$white: #fff;
$black: #000;
$lblack: #222;
$main: #333;
$silver: #999999;
$red: #ed1c24;
$pink: #dd0031;
$blue: #0067ac;
$bluebg: #0d1936;
$green: #3b9e18;
$facebook: #3b5998;
$google: #dd4c3b;
$youtube: #ff0018;
$twitter: #55acee;
$linkedin: #007ab9;

// MATERIAL VARIABLE
@mixin flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex; }
@mixin clearfix {
	content: '';
	display: table;
	width: 100%;
	clear: both; }
@mixin flatbutton {
	display: inline-block;
	@include fs(14rem);
	text-transform: uppercase;
	font-weight: 500;
	font-family: 'roboto';
	padding: 0 16px;
	line-height: 36px;
	@include bor-rad(2px);
	&:hover {
		background: color-rgba(lighten($black,62%),0.2); }
	&:active {
		background: color-rgba(lighten($black,62%),0.4); }
	&:focus {
		background: color-rgba($black,0.12); } }
@mixin material-card {
	@include bor-rad(2px);
	box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12); }
@mixin header-sd {
	box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28); }

@mixin mainbutton {
	display: inline-block;
	// +fs(14rem)
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	padding: 0 20px;
	line-height: 36px;
	background: $green;
	color: $white;
	// +bor-rad(50px)
	.fa {
		margin-right: 5px; }
	&:hover {
		background: lighten($red,10%);
		color: $white; } }

@mixin fs($size) {
	font-size: ($size / 16); }
body {
	margin: 0;
	padding: 0;
	@include fs(15rem);
	font-family: 'Muli';
	color: $main;
	line-height: 1.5;
	// padding-top: 100px
	@include media-breakpoint-down(sm) {
		@include fs(14rem); } }
html {
	overflow: auto;
	max-width: 100%;
	width: 100%; }
.fullpage, #wrapper {
	overflow: hidden;
	max-width: 1600px;
	width: 100%;
	background: $white;
	margin: 0 auto;
	@include transition(all,0.5s);
	&.open {
		overflow: visible;
		@include transform(translateX(280px)); } }
a {
	@include transition(all,0.5s); }
a, a:hover {
	&:focus, &:active, &:hover {
		outline: none;
		text-decoration: none; } }
input,button, select, textarea {
	@include transition(all,0.5s);
	&:hover, &:active, &:focus {
		outline: none; } }
button {
	border: none;
	background: none;
	margin: 0;
	padding: 0;
	@include transition(all,0.5s); }
img, iframe {
	vertical-align: middle; }
main {
	clear: both;
	&:before, &:after {
		content: '';
		display: table;
		width: 100%;
		clear: both; } }

strong {
	span {
		font-weight: bold; } }

// .flex, .flex-wrap
/// clear: both

header {
	// .container
	// 	+media-breakpoint-down(lg)
	// 		width: 100%
 }	// 		max-width: 100%
.container {
	@include media-breakpoint-up(xl) {
		max-width: 1170px;
		width: 100%; } }

.content {
	line-height: 1.5;
	// +transition(all,0.5s)
	color: $main;
	@include fs(15rem);
	@include media-breakpoint-down(md) {
		@include fs(14rem); }
	@include media-breakpoint-down(sm) {
		@include fs(13rem); }
	// a
	// 	color: darken($white,60%)
	// 	&:after
	// 		content: '\f105'
	// 		font-family: 'fontawesome'
	// 		margin-left: 5px
	// 		color: $red
	// 	&:hover
	ul, ol, p, h1, h2, h3, h4, h5, h6, img, figure {
		margin-bottom: 15px;
		line-height: 1.5;
		@include media-breakpoint-down(xs) {
			margin-bottom: 10px; } }
	figure {
		margin: 0 auto 15px;
		text-align: center;
		@include media-breakpoint-down(xs) {
			margin-bottom: 10px; }
		img {
			margin-bottom: 5px; }
		figcaption {
			// background: darken($white,5%)
			padding: 5px 0;
			text-align: center;
			@include fs(13rem);
			font-style: italic;
			color: darken($white,60%); } }
	ul {
		padding-left: 15px;
		li {
			@include pos(rel,null);
			&:before {
				content: '';
				display: block;
				width: 5px;
				height: 5px;
				@include bor-rad(50px);
				background: $green;
				@include pos(abs, 8px null null -10px); } } }
	h4 {
		@include fs(18rem);
		line-height: 1.5;
		font-weight: 700;
		color: $green; }
	h5 {
		@include fs(16rem);
		line-height: 1.5;
		color: $black;
		font-weight: 600; } }

.col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
	@include transition(all,0.5s); }
.nopadding {
	padding: 0; }
