.gallery-page {
	padding: 40px 0;
	.gallery-list {
		.album {
			width: 33.33%;
			.pic {
				display: block;
				@include pos(rel,null);
				overflow: hidden;
				&:before {
					content: '\f03e';
					font-family: 'fontawesome';
					@include pos(abs, 0 null 0 0);
					right: 0;
					z-index: 5;
					background: color-rgba($green,0.5);
					@include fs(28rem);
					color: $white;
					@include flexbox;
					justify-content: center;
					align-items: center;
					opacity: 0;
					@include transition(all,0.5s); }
				img {
					width: 100%;
					@include transition(all,10s); }
				&:hover {
					img {
						@include transform(scale3d(1.2,1.2,1)); }
					&:before {
						opacity: 1; } } } }
		.video-col {
			margin-bottom: 30px;
			figure {
				.video-img {
					display: block;
					@include pos(rel,null);
					overflow: hidden;
					img {
						width: 100%;
						@include transition(all,10s); }
					&:before {
						content: '\f04b';
						font-family: 'fontawesome';
						@include pos(abs, 0 null 0 0);
						right: 0;
						@include flexbox;
						justify-content: center;
						align-items: center;
						opacity: 0;
						@include fs(42rem);
						color: $white;
						background: color-rgba($green,0.5);
						@include transition(all,0.5s); } }
				figcaption {
					padding: 10px 0;
					font-weight: 600;
					&:before {
						content: '\f1c8';
						font-family: 'fontawesome';
						margin-right: 5px;
						font-weight: normal; } }
				&:hover {
					.video-img {
						&:before {
							opacity: 1; } } } } } } }
