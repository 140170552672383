body {
	padding-top: 100px;
	@include media-breakpoint-down(lg) {
		padding-top: 60px; } }
.homepage {
	padding-top: 0;
	@include media-breakpoint-down(lg) {
		padding-top: 60px; } }
header {
	@include pos(fix, 0 0 null 0);
	z-index: 1000;
	padding: 10px 0 0;
	@include transition(all,0.5s);
	background: $white;
	@include media-breakpoint-down(lg) {
		padding-top: 0; }
	.headertop {
		@include media-breakpoint-down(lg) {
			float: right;
			padding: 15px 0;
			.container {
				max-width: unset;
				width: auto; } } }
	.social {
		float: left;
		@include media-breakpoint-down(lg) {
			display: none; }
		a {
			display: inline-block;
			@include fs(20rem);
			color: $black;
			line-height: 30px;
			&:hover {
				color: $green; }
			+ a {
				margin-left: 10px; } } }
	.colright {
		float: right;
		.search {
			float: left;
			.searchwrap {
				@include flexbox;
				@include bor-rad(5px);
				width: 220px;
				border: 1px solid $black;
				@include media-breakpoint-down(xs) {
					width: 100px; }
				.searchbox {
					@include flexbox; }
				input {
					background: none;
					line-height: 26px;
					border: none;
					color: $black;
					width: calc(100% - 30px);
					padding: 0px 10px; }
				.searchbutton {
					width: 30px;
					// height: 30px
					line-height: 26px;
					text-align: center;
					color: $black; } } }
		.language {
			float: left;
			margin-left: 20px;
			a {
				display: inline-block;
				@include fs(15rem);
				font-weight: 700;
				color: $main;
				+ a {
					margin-left: 15px;
					@include pos(rel,null);
					line-height: 30px;
					&:before {
						content: '';
						display: block;
						width: 1px;
						height: 10px;
						background: $black;
						@include pos(abs,10px null null -6px); } }
				&.active {
					color: $green; } } } }
	.headermain {
		@include pos(rel,null);
		@include media-breakpoint-down(lg) {
			float: left;
			.container {
				max-width: unset;
				width: auto; } } }
	.btn-showmenu {
		float: left;
		@include fs(24rem);
		margin: 10px 15px 10px 0; }
	.overlay {
		@include pos(fix, 0 null 0 0);
		right: 0;
		background: $black;
		opacity: 0.7;
		display: none; }
	.sitelogo {
		@include pos(abs, 0 null 10px 50%);
		@include transform(translateX(-50%));
		@include media-breakpoint-down(lg) {
			@include pos(static,unset);
			@include transform(translateX(0));
			float: left;
			padding: 10px 0; }
		@include media-breakpoint-down(xs) {
			padding: 17px 0; }
		a {
			display: block;
			img {
				height: 50px;
				@include media-breakpoint-down(lg) {
					height: 40px; }
				@include media-breakpoint-down(xs) {
					height: 20px; } } } }
	.menu {
		@include media-breakpoint-down(lg) {
			@include pos(fix,0 null 0 0);
			width: 280px;
			background: $white;
			@include transition(all,0.5s);
			@include transform(translateX(-280px));
			&.open {
				@include transform(translateX(0)); } }
		.sitelogo {
			@include media-breakpoint-down(lg) {
				float: none;
				padding: 20px 0;
				border-bottom: 2px solid $green;
				text-align: center;
				img {
					height: 50px; } } }
		.menucontent {}
		.menuleft {
			float: left;
			@include media-breakpoint-down(lg) {
				float: none; } }
		.menuright {
			float: right;
			@include media-breakpoint-down(lg) {
				float: none; } }
		ul.menulink {
			> li {
				float: left;
				@include media-breakpoint-down(lg) {
					float: none; }
				+ li {
					margin-left: 40px;
					@include media-breakpoint-down(lg) {
						margin-left: 0; } }
				> a {
					display: block;
					color: $black;
					@include fs(15rem);
					font-weight: 700;
					line-height: 20px;
					padding: 20px 0;
					@include media-breakpoint-down(lg) {
						padding: 15px 25px;
						border-bottom: 1px solid darken($white,10%); } }
				&.hassub {
					@include pos(rel,null);
					.btn-showsub {
						display: none;
						@include fs(18rem);
						width: 30px;
						height: 30px;
						text-align: center;
						line-height: 30px;
						@include pos(abs, 7px 10px null null);
						@include media-breakpoint-down(lg) {
							display: block; } }
					.sub {
						background: color-rgba($green,0.9);
						@include pos(abs, 100% null null 50%);
						@include transform(translateX(-50%));
						min-width: 250px;
						margin-top: 20px;
						visibility: hidden;
						opacity: 0;
						@include transition(all,0.5s);
						@include media-breakpoint-down(lg) {
							@include pos(static,unset);
							display: none;
							margin-top: 0;
							visibility: visible;
							opacity: 1;
							background: darken($white,5%);
							@include transform(translateX(0));
							@include transition(all,0s); }
						> ul {
							> li {
								@include pos(rel,null);
								> a {
									display: block;
									padding: 10px 15px;
									color: rgba($white,0.8);
									@include media-breakpoint-down(lg) {
										padding: 10px 25px;
										color: $main; } }
								&:hover {
									background: color-rgba($black,0.1);
									color: $white; }
								.nano {
									display: none;
									@include pos(abs, 0 null null 100%);
									min-width: 250px;
									background: color-rgba(darken($green,10%),0.9);
									ul {
										li {
											a {
												display: block;
												padding: 10px 15px;
												color: rgba($white,0.8);
												@include media-breakpoint-down(lg) {
													padding: 10px 25px;
													color: $main; }
												&:hover {
													background: color-rgba($white,0.1); } } } } }
								&:hover {
									.nano {
										display: block; } } } } } }
				&:hover {
					@include media-breakpoint-up(xl) {
						> a {
							color: $green; }
						.sub {
							margin-top: 0;
							visibility: visible;
							opacity: 1; } } }
				&.active {
					@include media-breakpoint-up(xl);
					> a {
						color: $green; } } } } }
	&.minimal {
		background: $white;
		box-shadow: 0 3px 30px color-rgba($black,0.1); } }
.homepage {
	header {
		background: transparent;
		@include media-breakpoint-down(lg) {
			background: $white; }
		&.minimal {
			background: $white; } } }
