footer {
	background: $bluebg;
	.footertop {
		padding: 60px 0 40px; }
	.footercol {
		color: $white;
		margin-bottom: 20px; }
	.title {
		@include fs(24rem);
		color: #47c63d;
		margin-bottom: 20px;
		&:after {
			content: '';
			display: block;
			width: 70px;
			height: 3px;
			background: #314575;
			margin-top: 5px; } }
	.contact-detail {
		ul {
			li {
				color: $white;
				@include fs(15rem);
				@include pos(rel,null);
				padding-left: 25px;
				line-height: 1.8;
				.fa {
					@include pos(abs, 2px null null 0);
					@include fs(18rem); }
				a {
					color: $white;
					&:hover {
						color: $green; } } } } }
	.footerlink {
		ul {
			li {
				line-height: 1.8;
				a {
					color: $white;
					&:hover {
						color: $green; } } } } }
	.subscribewrap {
		.module-title {
			color: $white;
			margin-bottom: 10px; }
		.subscribefrm {
			@include pos(rel,null);
			input {
				background: color-rgba($white,0.1);
				border: none;
				@include bor-rad(100px);
				line-height: 40px;
				padding: 0px 20px;
				color: $white;
				width: 100%; }
			.subscribebutton {
				@include fs(0);
				width: 36px;
				height: 36px;
				@include bor-rad(100px);
				background: #47c63d;
				line-height: 36px;
				text-align: center;
				color: $white;
				@include pos(abs, 2px 2px null null);
				&:before {
					content: '\f00c';
					font-family: 'fontawesome';
					@include fs(18rem); } } } }
	.footerbottom {
		border-top: 1px solid color-rgba($white,0.1);
		.copyright {
			@include fs(14rem);
			color: color-rgba($white,0.5);
			padding: 10px 0;
			float: left; }
		.footernav {
			float: right;
			ul {
				li {
					display: inline-block;
					padding: 10px 0;
					+ li {
						margin-left: 25px; }
					a {
						color: color-rgba($white,0.5);
						@include fs(14rem);
						&:hover {
							color: $white; } } } } } } }

.up {
	width: 40px;
	height: 40px;
	@include bor-rad(50px);
	background: $green;
	color: $white;
	line-height: 40px;
	text-align: center;
	@include pos(fix, null 20px 20px null);
	@include transform(translateX(100px));
	cursor: pointer;
	@include transition(all,0.5s);
	&.active {
		@include transform(translateX(0)); }
	&:hover {
		background: lighten($green,10%); } }
