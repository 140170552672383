.product-page {
	padding: 40px 0 0; }
.product-category {
	.catecol {
		margin-bottom: 40px;
		figure {
			.cateimg {
				display: block;
				margin-bottom: 15px;
				overflow: hidden;
				img {
					width: 100%;
					@include transition(all,0.3s); } }
			figcaption {
				.catename {
					@include fs(20rem);
					margin-bottom: 10px;
					a {
						color: darken($white,40%);
						&:hover {
							color: $main; } } }
				.cateproduct {
					li {
						display: inline-block;
						@include fs(13rem);
						color: darken($white,60%);
						a {
							color: darken($white,60%);
							&:hover {
								color: $main; } }
						+ li {
							&:before {
								content: ' | '; } } } } }
			&:hover {
				.cateimg {
					img {
						@include transform(scale3d(1.1,1.1,1)); } }
				figcaption {
					.catename {
						a {
							color: $red; } } } } } } }

.category-intro {
	margin-bottom: 60px;
	width: 80%;
	@include media-breakpoint-down(lg) {
		width: 100%; }
	.page-catename {
		@include fs(32rem);
		color: darken($white,60%);
		margin-bottom: 20px; }
	.pagedes {
		color: darken($white,60%);
		@include fs(14rem);
		line-height: 1.8; } }
.product-list {
	.row {
		margin: 0; }
	.col-xs-12 {
		padding: 0; } }
.productcol {
	padding-bottom: 30px;
	margin-bottom: 40px;
	border-bottom: 1px solid darken($white,10%);
	figure {
		text-align: center;
		.colorimg {
			display: block;
			.item {} }
		.productimg {
			display: flex;
			height: 200px;
			justify-content: center;
			align-items: center;
			@include pos(rel,null);
			img {
				max-width: 80%;
				max-height: 60%;
				@include transition(all,0.3s); } }
		.promotion {
			width: 50px;
			height: 50px;
			line-height: 50px;
			@include bor-rad(50%);
			z-index: 5;
			color: $white;
			@include fs(16rem);
			font-family: 'Dancing Script';
			@include pos(abs, null 0 0 null);
			background: $green; }
		figcaption {
			.productname {
				@include fs(23rem);
				margin-bottom: 5px;
				@include media-breakpoint-down(lg) {
					@include fs(20rem); }
				@include media-breakpoint-down(md) {
					@include fs(18rem); }
				@include media-breakpoint-down(sm) {
					@include fs(16rem); }
				@include media-breakpoint-down(xs) {
					@include fs(14rem); }
				a {
					color: $main; } }
			.productcate {
				@include fs(13rem);
				color: darken($white,60%);
				margin-bottom: 20px;
				@include media-breakpoint-down(sm) {
					@include fs(12rem); } }
			.colorslide {
				width: 70%;
				margin: 0px auto 15px;
				// display: flex
				// justify-content: center
				.slick-prev, .slick-next {
					@include pos(abs, 50% null null null);
					@include transform(translateY(-50%));
					@include fs(0rem);
					color: darken($white,60%);
					&:before {
						content: '';
						font-family: 'fontawesome';
						@include fs(14rem); } }
				.item {
					// padding: 0px 10px
					// display: flex
 }					// flex-wrap: wrap
				.color {
					width: 24px;
					height: 24px;
					border: 1px solid darken($white,20%);
					@include bor-rad(50%);
					margin-bottom: 5px; }
				.new {
					width: 24px;
					@include fs(8rem);
					text-align: center;
					background: $red;
					color: $white;
					text-transform: uppercase;
					line-height: 1;
					padding: 1px; } }
			.productprice {
				display: flex;
				justify-content: center;
				align-items: center;
				.old {
					color: darken($white,40%);
					text-decoration: line-through; }
				.new {
					@include fs(18rem);
					color: $red;
					margin-right: 10px; } } }
		&:hover {
			.productimg {
				img {
					@include transform(scale3d(1.1,1.1,1)); } }
			figcaption {
				.productname {
					a {
						color: $red; } } } } } }

.product-nav {
	width: 20%;
	// padding: 15px 15px
	padding: 0px 20px;
	margin-bottom: 20px;
	@include media-breakpoint-down(xs) {
		width: 100%;
		order: 2;
		margin-bottom: 10px; }
	.item {
		padding: 2px 0;
		.img {
			border: 1px solid transparent;
			@include transition(all,0.3s);
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			img {
				max-width: 100%;
				max-height: 100%; } }
		&.slick-current {
			.img {
				border: 1px solid $red; } } } }
.product-slide {
	width: 80%;
	margin-bottom: 60px;
	@include media-breakpoint-down(xs) {
		width: 100%;
		order: 1; }
	.item {
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				max-width: 100%;
				max-height: 100%; } } } }
.page-productname {
	padding: 0 0 20px;
	@include fs(32rem);
	color: $red;
	border-bottom: 1px solid darken($white,10%);
	margin-bottom: 20px;
	.productcate {
		color: $main; } }
.product-info {
	// padding-top: 10px
	margin-bottom: 40px;
	@include media-breakpoint-down(lg) {
		margin-bottom: 20px; }
	.product-overview {
		padding: 20px 0;
		border-bottom: 1px dashed darken($white,10%);
		ul {
			margin-bottom: 0;
			li {
				@include fs(13rem);
				@include pos(rel,null);
				padding-left: 15px;
				color: darken($white,50%);
				line-height: 1.8;
				&:before {
					content: '\f111';
					font-family: 'fontawesome';
					color: $red;
					@include pos(abs, 7px null null 0);
					@include fs(2rem); }
				@include media-breakpoint-down(lg) {
					@include fs(12rem); } } } }
	.product-properties {
		padding: 20px 0;
		border-bottom: 1px dashed darken($white,10%);
		a {
			+ a {
				margin-left: 10px; } } }
	.product-color {
		padding: 20px 0;
		border-bottom: 1px dashed darken($white,10%);
		label {
			@include fs(14rem);
			margin-bottom: 5px; }
		.nav-tabs {
			margin: 0;
			padding: 0;
			border: none;
			.nav-item {
				margin: 0;
				padding: 0;
				border: none;
				+ .nav-item {
					margin-left: 10px; }
				.nav-link {
					width: 26px;
					height: 26px;
					padding: 0;
					margin: 0 0 5px;
					@include bor-rad(50%);
					border: 1px solid darken($white,20%);
					&.active {
						border-color: $red; } }
				.new {
					@include fs(9rem);
					text-transform: uppercase;
					color: $white;
					background: $red;
					line-height: 1;
					text-align: center;
					padding: 1px; } } }
		// width: 50%
		// border-bottom: 1px dashed darken($white,40%)
		// .label
		// 	display: block
		// 	width: 100%
		// 	margin-bottom: 5px
		// .color-slide
		// 	.item
		// 		padding: 0px 2px
		// 		cursor: pointer
		// 		.color
		// 			display: block
		// 			border: 1px solid darken($white,20%)
		// 			padding: 3px
		// 			background: darken($white,3%)
		// 			// +bor-rad(3px)
		// 		.color-img
		// 			display: block
		// 			width: 24px
		// 			height: 24px
		// 			img
		// 				border: 2px solid darken($white,20%)
		// 		&.slick-current
		// 			.color
 }		// 				border-color: $blue
	.product-size {
		width: 50%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		padding: 15px 0;
		// border-bottom: 1px dashed darken($white,40%)
		.label {
			display: block;
			width: 100%;
			@include fs(14rem);
			margin-bottom: 5px;
			font-weight: 500; }
		.size, li {
			// display: inline-block
			// vertical-align: middle
			margin-right: 5px;
			margin-bottom: 5px;
			a {
				display: block;
				// width: 40px
				min-width: 32px;
				line-height: 30px;
				padding: 0px 10px;
				// +bor-rad(3px)
				@include fs(13rem);
				border: 1px solid darken($white,20%);
				// +bor-rad(3px)
				text-align: center;
				// background: $white
				@include transition(all,0.5s);
				cursor: pointer;
				color: darken($white,40%);
				font-weight: bold;
				// + .size
				// 	margin-left: 5px
				&:hover {
					color: $blue; }
				&.active {
					border-color: $blue;
					color: $blue; } } } }
	.product-price {
		padding: 20px 0;
		.new {
			color: $red;
			@include fs(28rem); } }
	.share {
		margin-bottom: 20px; } }
.product-news {
	margin-bottom: 40px;
	.news-list {
		.newscol {
			margin-bottom: 30px;
			figure {
				.newsimg {
					display: block;
					margin-bottom: 20px;
					img {
						width: 100%; } }
				figcaption {
					.newsname {
						@include fs(20rem);
						margin-bottom: 10px;
						a {
							color: darken($white,60%);
							&:hover {
								color: $main; } } }
					.newsdes {
						@include fs(14rem);
						color: darken($white,60%);
						&:hover {
							color: $red; }
						&:after {
							content: '\f105';
							font-family: 'fontawesome';
							margin-left: 5px;
							color: $red; } } } } } } }
.product-description {
	margin-bottom: 40px; }
.other-product {
	margin-bottom: 40px; }
