.topbar {
	background: $green;
	ul {
		padding: 5px 0;
		white-space: nowrap;
		text-align: center;
		li {
			display: inline-block;
			a {
				display: block;
				line-height: 36px;
				@include bor-rad(50px);
				padding: 0px 25px;
				color: $white; }
			&:hover {
				a {
					background: color-rgba($white,0.1); } }
			&.active {
				a {
					background: $white;
					color: $green; } } } } }
