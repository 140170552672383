.news-page {
	padding: 40px 0; }
.newslist {
	article {
		margin-bottom: 30px; }
	.newscol {
		@include pos(rel,null);
		height: 100%;
		&:after {
			content: '';
			display: block;
			width: 0;
			height: 2px;
			background: $green;
			@include pos(abs, null null 0 0);
			@include transition(all,0.5s); }
		figure {
			@include pos(rel,null);
			.newsimg {
				display: block;
				@include pos(rel,null);
				overflow: hidden;
				img {
					// +transition(all,0s)
					@include transition(all,10s); }
				&:after {
					content: '\f0c1';
					font-family: 'fontawesome';
					background: color-rgba($green,0.5);
					@include pos(abs, 0 null 0 0);
					right: 0;
					@include flexbox;
					justify-content: center;
					align-items: center;
					color: $white;
					@include fs(18rem);
					align-content: center;
					text-transform: uppercase;
					font-style: italic;
					@include transform(translateY(-100%));
					@include transition(all,0.5s); }
				time {
					display: inline-block;
					@include pos(abs, null null 0 0);
					background: color-rgba($bluebg,0.7);
					color: $white;
					@include fs(13rem);
					font-weight: 700;
					line-height: 30px;
					padding: 0px 10px; } }
			figcaption {
				padding: 20px 0;
				.newsname {
					font-weight: 600;
					@include fs(16rem);
					margin-bottom: 10px;
					min-height: 48px;
					line-height: 1.5;
					a {
						color: $bluebg; } }
				.newsdes {
					margin-bottom: 10px;
					@include fs(14rem);
					color: darken($white,40%); }
				.link {
					color: $green;
					&:after {
						content: '\f18e';
						font-family: 'fontawesome';
						margin-left: 5px;
						color: $black; } } } }
		&:hover {
			&:after {
				width: 100%; }
			figure {
				.newsimg {
					img {
						@include transform(scale3d(1.4,1.4,1));
						@include transition(all,10s); }
					&:after {
						@include transform(translateY(0)); } }
				figcaption {
					&:after {
 }						// width: 100%
					.link {
						letter-spacing: 1px;
						&:after {
							color: $green; } } } } } } }

.oversea {
	article {
		margin-bottom: 30px; }
	.newscol {
		figure {
			@include pos(rel,null);
			.newsimg {
				display: block;
				@include pos(rel,null);
				overflow: hidden;
				img {
					// +transition(all,0s)
					@include transition(all,10s); }
				&:after {
					content: '\f0c1';
					font-family: 'fontawesome';
					background: color-rgba($green,0.5);
					@include pos(abs, 0 null 0 0);
					right: 0;
					@include flexbox;
					justify-content: center;
					align-items: center;
					color: $white;
					@include fs(32rem);
					align-content: center;
					text-transform: uppercase;
					font-style: italic;
					@include transform(translateY(-100%));
					@include transition(all,0.5s); } }
			figcaption {
				padding: 20px 0;
				&:after {
					content: '';
					display: block;
					width: 0;
					height: 2px;
					background: $green;
					@include pos(abs, null null 0 0);
					@include transition(all,0.5s); }
				.newsname {
					// text-transform: uppercase
					font-weight: 600;
					@include fs(20rem);
					margin-bottom: 10px;
					a {
						color: $bluebg; } }
				.link {
					color: $green;
					&:after {
						content: '\f18e';
						font-family: 'fontawesome';
						margin-left: 5px;
						color: $black; } } }
			&:hover {
				.newsimg {
					img {
						@include transform(scale3d(1.4,1.4,1));
						@include transition(all,10s); }
					&:after {
						@include transform(translateY(0)); } }
				figcaption {
					&:after {
						width: 100%; }
					.link {
						letter-spacing: 1px;
						&:after {
							color: $green; } } } } } } }
.pages {
	.pagination {
		width: 100%;
		text-align: center;
		li {
			display: inline-block;
			a, span {
				display: block;
				width: 36px;
				height: 36px;
				line-height: 36px;
				background: $bluebg;
				color: $white;
				font-weight: 600;
				text-align: center; }
			&.active {
				a,span {
					background: $green; } } } } }

.newsmain-img {
	margin-bottom: 20px;
	@include pos(rel,null);
	img {
		width: 100%; }
	figcaption {
		background: color-rgba($black,0.5);
		padding: 15px;
		@include pos(abs, null 0 0 0);
		.share {
			img {
				width: unset; } } } }
.newspage-name {
	@include fs(24rem);
	color: $white;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 1.5;
	margin-bottom: 10px;
	&:after {
		content: '';
		display: block;
		width: 60px;
		height: 1px;
		background: $white;
		margin-top: 5px; } }
.newsdetail {
	padding-bottom: 10px;
	border-bottom: 1px solid darken($white,10%);
	margin-bottom: 20px;
	time {
		display: inline-block;
		text-transform: uppercase;
		@include fs(12rem);
		font-weight: 700;
		background: lighten($green,50%);
		@include bor-rad(2px);
		border: 1px solid $green;
		color: $green;
		line-height: 1;
		padding: 3px 5px;
		margin-right: 15px;
		&:before {
			content: '\f017';
			font-family: 'fontawesome';
			margin-right: 3px;
			font-weight: normal; } }
	.share {
		display: inline-block;
		vertical-align: middle; } }
.register-info {
	@include bor-rad(5px);
	border: 2px dashed $red;
	background: lighten($green,62%);
	padding: 20px 30px;
	.module-title {
		color: black;
		font-weight: 600;
		@include fs(16rem);
		color: $green;
		margin-bottom: 15px;
		&:before {
			content: '\f044';
			font-family: 'fontawesome';
			font-weight: normal;
			margin-right: 5px; } }
	.form-group {
		margin-bottom: 10px;
		@include flexbox;
		flex-wrap: wrap;
		label {
			width: 150px;
			padding: 10px 0; }
		input, textarea {
			width: calc(100% - 150px);
			@include bor-rad(3px);
			border: 1px solid darken(white,15%);
			padding: 10px 15px;
			&:hover {
				border-color: darken($white,30%); }
			&:focus {
				border-color: $green; } } }
	input[type="reset"] {
		display: none; }
	input[type="submit"] {
		@include mainbutton;
		border: none;
		margin-left: 150px;
		margin-top: 10px; } }
.btn-backpage {
	display: block;
	margin-top: 40px;
	// +fs(18rem)
	line-height: 40px;
	font-weight: 600;
	color: $green;
	// +bor-rad(3px)
	margin-bottom: 40px;
	background: darken($white,5%);
	@include media-breakpoint-down(md) {
		margin-top: 0; }
	&:before {
		content: '\f104';
		font-family: 'fontawesome';
		margin: 0 10px; }
	&:hover {
		background: $bluebg;
		color: $white; } }

.othertitle {
	@include fs(18rem);
	color: $green;
	font-weight: 700;
	margin-bottom: 15px;
	background: darken($white,3%);
	border-bottom: 2px solid $bluebg;
	span {
		display: inline-block;
		font-weight: 700;
		background: $bluebg;
		color: $white;
		padding: 5px 10px; } }
.sidebar-category {
	margin-bottom: 40px;
	&.only {
		margin-top: 40px; }
	&.cate2 {
		margin-top: 40px;
		@include media-breakpoint-down(md) {
			margin-top: 0; } }
	ul {
		li {
			border-bottom: 1px solid darken($white,10%);
			a {
				display: block;
				color: darken($white,60%);
				padding: 10px 0;
				&:hover {
					color: $green;
					letter-spacing: 1px; } }
			&.active {
				a {
					color: $green;
					font-weight: 700;
					&:before {
						content: '\f105';
						font-family: 'fontawesome';
						margin-right: 5px; } } } } } }
.other-news {
	margin-bottom: 40px;
	&.newspost {
		margin-top: 40px;
		@include media-breakpoint-down(md) {
			margin-top: 0; } }
	ul {
		.news {
			border-bottom: 1px solid darken($white,10%);
			padding: 10px 0;
			figure {
				@include flexbox;
				flex-wrap: wrap;
				.newsimg {
					width: 80px;
					img {
						max-width: 100%;
						max-height: 100%; } }
				figcaption {
					width: calc(100% - 80px);
					padding-left: 10px;
					.newsname {
						font-weight: 700;
						@include fs(14rem);
						a {
							color: $main;
							&:hover {
								color: $green; } } } } } } } }
.us-info {
	border-top: 1px dashed $green;
	margin-top: 15px;
	padding-top: 15px;
	margin-bottom: 30px;
	p, ul {
		margin-bottom: 10px; }
	a {
		color: $main;
		font-weight: bold;
		&:hover {
			color: $red; } } }
.side-album {
	margin-bottom: 40px;
	.albumlist {
		@include flexbox;
		flex-wrap: wrap;
		margin: 0px -2px;
		.pic {
			width: 33.33%;
			padding: 2px;
			a {
				display: block;
				overflow: hidden;
				@include pos(rel,null);
				&:before {
					content: '\f03e';
					font-family: 'fontawesome';
					@include flexbox;
					justify-content: center;
					align-items: center;
					// +fs(18rem)
					color: $white;
					@include pos(abs, 0 null 0 0);
					right: 0;
					background: color-rgba($green,0.5);
					@include transform(translateY(-100%));
					@include transition(all,0.5s);
					z-index: 5; }
				img {
					width: 100%;
					@include transition(all,5s); }
				&:hover {
					&:before {
						@include transform(translateY(0%)); }
					img {
						@include transform(scale3d(1.2,1.2,1)); } } } } }
	.btn-viewmore {
		color: darken($white,60%);
		text-transform: uppercase;
		font-style: italic;
		@include fs(13rem);
		display: inline-block;
		margin-top: 10px;
		&:after {
			content: '\f105';
			font-family: 'fontawesome';
			margin-left: 5px; }
		&:hover {
			color: $green;
			letter-spacing: 2px; } } }
.news-video {
	margin-bottom: 40px;
	.video {
		border-bottom: 1px solid darken($white,10%);
		figure {
			.videoimg {
				display: block;
				@include pos(rel,null);
				&:before {
					content: '\f04b';
					font-family: 'fontawesome';
					@include pos(abs, 0 null 0 0);
					right: 0;
					background: color-rgba($black,0.2);
					@include flexbox;
					justify-content: center;
					align-items: center;
					@include fs(32rem);
					color: $white; }
				img {
					width: 100%; } }
			figcaption {
				padding: 10px 0;
				.videoname {
					font-weight: 600;
					padding-left: 20px;
					@include pos(rel,null);
					line-height: 1.5;
					@include fs(14rem);
					&:before {
						content: '\f03d';
						font-family: 'fontawesome';
						margin-right: 5px;
						@include pos(abs, 0 null null 0);
						font-weight: normal;
						@include fs(12rem);
						color: darken($white,40%); }
					a {
						color: $main;
						&:hover {
							color: $green; } } } } } }
	.btn-viewmore {
		color: darken($white,60%);
		text-transform: uppercase;
		font-style: italic;
		@include fs(13rem);
		display: inline-block;
		margin-top: 10px;
		&:after {
			content: '\f105';
			font-family: 'fontawesome';
			margin-left: 5px; }
		&:hover {
			color: $red;
			letter-spacing: 2px; } } }

.blog-page {
	padding: 40px 0; }




.newsdetail {
	margin-bottom: 20px;
	.newsinfo {
		margin-top: -20px;
		padding: 10px 0;
		border-bottom: 1px solid darken($white,10%);
		time, .share {
			display: inline-block; }
		time {
			margin-right: 15px;
			@include fs(12rem);
			background: darken($white,10%);
			color: $main;
			font-weight: 500;
			padding: 2px 8px;
			@include bor-rad(3px); } } }

.other-news {
	ul {
		li {
			a {
				display: block;
				color: darken($white,60%);
				padding: 10px 0;
				border-bottom: 1px dashed darken($white,10%);
				&:before {
					content: '\f1ea';
					font-family: 'fontawesome';
					margin-right: 5px; }
				&:hover {
					color: $green; } } } } }

.sortmenu {
	padding-top: 40px;
	h4 {
		@include fs(28rem);
		font-weight: 700;
		text-transform: uppercase;
		color: $main;
		margin-bottom: 30px;
		float: left;
		&:after {
			content: '';
			display: block;
			width: 30px;
			height: 3px;
			background: $green;
			margin-top: 10px; } }
	select {
		padding: 10px 15px;
		@include bor-rad(5px);
		border: 1px solid darken($white,10%);
		float: right;
		// margin-top: -10px
		&:hover {
			border-color: $blue; } } }
