@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
html {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

body div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

*:before, *:after {
  box-sizing: inherit;
}

img, object, embed {
  max-width: 100%;
}

html {
  overflow-y: scroll;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word;
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}

.ie7 input[type="checkbox"] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td {
  vertical-align: top;
}

td img {
  vertical-align: top;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

.clickable, label {
  cursor: pointer;
}

input[type=button], input[type=submit], input[type=file] {
  cursor: pointer;
}

button {
  cursor: pointer;
  margin: 0;
}

input, select, textarea {
  margin: 0;
}

button, input[type=button] {
  width: auto;
  overflow: visible;
}

.ie7 img {
  -ms-interpolation-mode: bicubic;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.mrt-xs-0r {
  margin-top: 0rem;
}

@media (min-width: 544px) {
  .mrt-sm-0r {
    margin-top: 0rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-0r {
    margin-top: 0rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-0r {
    margin-top: 0rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-0r {
    margin-top: 0rem;
  }
}

.mrb-xs-0r {
  margin-bottom: 0rem;
}

@media (min-width: 544px) {
  .mrb-sm-0r {
    margin-bottom: 0rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-0r {
    margin-bottom: 0rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-0r {
    margin-bottom: 0rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-0r {
    margin-bottom: 0rem;
  }
}

.pdl-xs-0r {
  padding-left: 0rem;
}

@media (min-width: 544px) {
  .pdl-sm-0r {
    padding-left: 0rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-0r {
    padding-left: 0rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-0r {
    padding-left: 0rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-0r {
    padding-left: 0rem;
  }
}

.pdr-xs-0r {
  padding-right: 0rem;
}

@media (min-width: 544px) {
  .pdr-sm-0r {
    padding-right: 0rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-0r {
    padding-right: 0rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-0r {
    padding-right: 0rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-0r {
    padding-right: 0rem;
  }
}

.mrt-xs-1r {
  margin-top: 1rem;
}

@media (min-width: 544px) {
  .mrt-sm-1r {
    margin-top: 1rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-1r {
    margin-top: 1rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-1r {
    margin-top: 1rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-1r {
    margin-top: 1rem;
  }
}

.mrb-xs-1r {
  margin-bottom: 1rem;
}

@media (min-width: 544px) {
  .mrb-sm-1r {
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-1r {
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-1r {
    margin-bottom: 1rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-1r {
    margin-bottom: 1rem;
  }
}

.pdl-xs-1r {
  padding-left: 1rem;
}

@media (min-width: 544px) {
  .pdl-sm-1r {
    padding-left: 1rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-1r {
    padding-left: 1rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-1r {
    padding-left: 1rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-1r {
    padding-left: 1rem;
  }
}

.pdr-xs-1r {
  padding-right: 1rem;
}

@media (min-width: 544px) {
  .pdr-sm-1r {
    padding-right: 1rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-1r {
    padding-right: 1rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-1r {
    padding-right: 1rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-1r {
    padding-right: 1rem;
  }
}

.mrt-xs-2r {
  margin-top: 2rem;
}

@media (min-width: 544px) {
  .mrt-sm-2r {
    margin-top: 2rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-2r {
    margin-top: 2rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-2r {
    margin-top: 2rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-2r {
    margin-top: 2rem;
  }
}

.mrb-xs-2r {
  margin-bottom: 2rem;
}

@media (min-width: 544px) {
  .mrb-sm-2r {
    margin-bottom: 2rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-2r {
    margin-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-2r {
    margin-bottom: 2rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-2r {
    margin-bottom: 2rem;
  }
}

.pdl-xs-2r {
  padding-left: 2rem;
}

@media (min-width: 544px) {
  .pdl-sm-2r {
    padding-left: 2rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-2r {
    padding-left: 2rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-2r {
    padding-left: 2rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-2r {
    padding-left: 2rem;
  }
}

.pdr-xs-2r {
  padding-right: 2rem;
}

@media (min-width: 544px) {
  .pdr-sm-2r {
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-2r {
    padding-right: 2rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-2r {
    padding-right: 2rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-2r {
    padding-right: 2rem;
  }
}

.mrt-xs-3r {
  margin-top: 3rem;
}

@media (min-width: 544px) {
  .mrt-sm-3r {
    margin-top: 3rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-3r {
    margin-top: 3rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-3r {
    margin-top: 3rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-3r {
    margin-top: 3rem;
  }
}

.mrb-xs-3r {
  margin-bottom: 3rem;
}

@media (min-width: 544px) {
  .mrb-sm-3r {
    margin-bottom: 3rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-3r {
    margin-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-3r {
    margin-bottom: 3rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-3r {
    margin-bottom: 3rem;
  }
}

.pdl-xs-3r {
  padding-left: 3rem;
}

@media (min-width: 544px) {
  .pdl-sm-3r {
    padding-left: 3rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-3r {
    padding-left: 3rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-3r {
    padding-left: 3rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-3r {
    padding-left: 3rem;
  }
}

.pdr-xs-3r {
  padding-right: 3rem;
}

@media (min-width: 544px) {
  .pdr-sm-3r {
    padding-right: 3rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-3r {
    padding-right: 3rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-3r {
    padding-right: 3rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-3r {
    padding-right: 3rem;
  }
}

.mrt-xs-4r {
  margin-top: 4rem;
}

@media (min-width: 544px) {
  .mrt-sm-4r {
    margin-top: 4rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-4r {
    margin-top: 4rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-4r {
    margin-top: 4rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-4r {
    margin-top: 4rem;
  }
}

.mrb-xs-4r {
  margin-bottom: 4rem;
}

@media (min-width: 544px) {
  .mrb-sm-4r {
    margin-bottom: 4rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-4r {
    margin-bottom: 4rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-4r {
    margin-bottom: 4rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-4r {
    margin-bottom: 4rem;
  }
}

.pdl-xs-4r {
  padding-left: 4rem;
}

@media (min-width: 544px) {
  .pdl-sm-4r {
    padding-left: 4rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-4r {
    padding-left: 4rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-4r {
    padding-left: 4rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-4r {
    padding-left: 4rem;
  }
}

.pdr-xs-4r {
  padding-right: 4rem;
}

@media (min-width: 544px) {
  .pdr-sm-4r {
    padding-right: 4rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-4r {
    padding-right: 4rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-4r {
    padding-right: 4rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-4r {
    padding-right: 4rem;
  }
}

.mrt-xs-5r {
  margin-top: 5rem;
}

@media (min-width: 544px) {
  .mrt-sm-5r {
    margin-top: 5rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-5r {
    margin-top: 5rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-5r {
    margin-top: 5rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-5r {
    margin-top: 5rem;
  }
}

.mrb-xs-5r {
  margin-bottom: 5rem;
}

@media (min-width: 544px) {
  .mrb-sm-5r {
    margin-bottom: 5rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-5r {
    margin-bottom: 5rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-5r {
    margin-bottom: 5rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-5r {
    margin-bottom: 5rem;
  }
}

.pdl-xs-5r {
  padding-left: 5rem;
}

@media (min-width: 544px) {
  .pdl-sm-5r {
    padding-left: 5rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-5r {
    padding-left: 5rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-5r {
    padding-left: 5rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-5r {
    padding-left: 5rem;
  }
}

.pdr-xs-5r {
  padding-right: 5rem;
}

@media (min-width: 544px) {
  .pdr-sm-5r {
    padding-right: 5rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-5r {
    padding-right: 5rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-5r {
    padding-right: 5rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-5r {
    padding-right: 5rem;
  }
}

.mrt-xs-6r {
  margin-top: 6rem;
}

@media (min-width: 544px) {
  .mrt-sm-6r {
    margin-top: 6rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-6r {
    margin-top: 6rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-6r {
    margin-top: 6rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-6r {
    margin-top: 6rem;
  }
}

.mrb-xs-6r {
  margin-bottom: 6rem;
}

@media (min-width: 544px) {
  .mrb-sm-6r {
    margin-bottom: 6rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-6r {
    margin-bottom: 6rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-6r {
    margin-bottom: 6rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-6r {
    margin-bottom: 6rem;
  }
}

.pdl-xs-6r {
  padding-left: 6rem;
}

@media (min-width: 544px) {
  .pdl-sm-6r {
    padding-left: 6rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-6r {
    padding-left: 6rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-6r {
    padding-left: 6rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-6r {
    padding-left: 6rem;
  }
}

.pdr-xs-6r {
  padding-right: 6rem;
}

@media (min-width: 544px) {
  .pdr-sm-6r {
    padding-right: 6rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-6r {
    padding-right: 6rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-6r {
    padding-right: 6rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-6r {
    padding-right: 6rem;
  }
}

.mrt-xs-7r {
  margin-top: 7rem;
}

@media (min-width: 544px) {
  .mrt-sm-7r {
    margin-top: 7rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-7r {
    margin-top: 7rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-7r {
    margin-top: 7rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-7r {
    margin-top: 7rem;
  }
}

.mrb-xs-7r {
  margin-bottom: 7rem;
}

@media (min-width: 544px) {
  .mrb-sm-7r {
    margin-bottom: 7rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-7r {
    margin-bottom: 7rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-7r {
    margin-bottom: 7rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-7r {
    margin-bottom: 7rem;
  }
}

.pdl-xs-7r {
  padding-left: 7rem;
}

@media (min-width: 544px) {
  .pdl-sm-7r {
    padding-left: 7rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-7r {
    padding-left: 7rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-7r {
    padding-left: 7rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-7r {
    padding-left: 7rem;
  }
}

.pdr-xs-7r {
  padding-right: 7rem;
}

@media (min-width: 544px) {
  .pdr-sm-7r {
    padding-right: 7rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-7r {
    padding-right: 7rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-7r {
    padding-right: 7rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-7r {
    padding-right: 7rem;
  }
}

.mrt-xs-8r {
  margin-top: 8rem;
}

@media (min-width: 544px) {
  .mrt-sm-8r {
    margin-top: 8rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-8r {
    margin-top: 8rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-8r {
    margin-top: 8rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-8r {
    margin-top: 8rem;
  }
}

.mrb-xs-8r {
  margin-bottom: 8rem;
}

@media (min-width: 544px) {
  .mrb-sm-8r {
    margin-bottom: 8rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-8r {
    margin-bottom: 8rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-8r {
    margin-bottom: 8rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-8r {
    margin-bottom: 8rem;
  }
}

.pdl-xs-8r {
  padding-left: 8rem;
}

@media (min-width: 544px) {
  .pdl-sm-8r {
    padding-left: 8rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-8r {
    padding-left: 8rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-8r {
    padding-left: 8rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-8r {
    padding-left: 8rem;
  }
}

.pdr-xs-8r {
  padding-right: 8rem;
}

@media (min-width: 544px) {
  .pdr-sm-8r {
    padding-right: 8rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-8r {
    padding-right: 8rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-8r {
    padding-right: 8rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-8r {
    padding-right: 8rem;
  }
}

.mrt-xs-9r {
  margin-top: 9rem;
}

@media (min-width: 544px) {
  .mrt-sm-9r {
    margin-top: 9rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-9r {
    margin-top: 9rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-9r {
    margin-top: 9rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-9r {
    margin-top: 9rem;
  }
}

.mrb-xs-9r {
  margin-bottom: 9rem;
}

@media (min-width: 544px) {
  .mrb-sm-9r {
    margin-bottom: 9rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-9r {
    margin-bottom: 9rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-9r {
    margin-bottom: 9rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-9r {
    margin-bottom: 9rem;
  }
}

.pdl-xs-9r {
  padding-left: 9rem;
}

@media (min-width: 544px) {
  .pdl-sm-9r {
    padding-left: 9rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-9r {
    padding-left: 9rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-9r {
    padding-left: 9rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-9r {
    padding-left: 9rem;
  }
}

.pdr-xs-9r {
  padding-right: 9rem;
}

@media (min-width: 544px) {
  .pdr-sm-9r {
    padding-right: 9rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-9r {
    padding-right: 9rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-9r {
    padding-right: 9rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-9r {
    padding-right: 9rem;
  }
}

.mrt-xs-10r {
  margin-top: 10rem;
}

@media (min-width: 544px) {
  .mrt-sm-10r {
    margin-top: 10rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-10r {
    margin-top: 10rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-10r {
    margin-top: 10rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-10r {
    margin-top: 10rem;
  }
}

.mrb-xs-10r {
  margin-bottom: 10rem;
}

@media (min-width: 544px) {
  .mrb-sm-10r {
    margin-bottom: 10rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-10r {
    margin-bottom: 10rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-10r {
    margin-bottom: 10rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-10r {
    margin-bottom: 10rem;
  }
}

.pdl-xs-10r {
  padding-left: 10rem;
}

@media (min-width: 544px) {
  .pdl-sm-10r {
    padding-left: 10rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-10r {
    padding-left: 10rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-10r {
    padding-left: 10rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-10r {
    padding-left: 10rem;
  }
}

.pdr-xs-10r {
  padding-right: 10rem;
}

@media (min-width: 544px) {
  .pdr-sm-10r {
    padding-right: 10rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-10r {
    padding-right: 10rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-10r {
    padding-right: 10rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-10r {
    padding-right: 10rem;
  }
}

.mrt-xs-11r {
  margin-top: 11rem;
}

@media (min-width: 544px) {
  .mrt-sm-11r {
    margin-top: 11rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-11r {
    margin-top: 11rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-11r {
    margin-top: 11rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-11r {
    margin-top: 11rem;
  }
}

.mrb-xs-11r {
  margin-bottom: 11rem;
}

@media (min-width: 544px) {
  .mrb-sm-11r {
    margin-bottom: 11rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-11r {
    margin-bottom: 11rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-11r {
    margin-bottom: 11rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-11r {
    margin-bottom: 11rem;
  }
}

.pdl-xs-11r {
  padding-left: 11rem;
}

@media (min-width: 544px) {
  .pdl-sm-11r {
    padding-left: 11rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-11r {
    padding-left: 11rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-11r {
    padding-left: 11rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-11r {
    padding-left: 11rem;
  }
}

.pdr-xs-11r {
  padding-right: 11rem;
}

@media (min-width: 544px) {
  .pdr-sm-11r {
    padding-right: 11rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-11r {
    padding-right: 11rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-11r {
    padding-right: 11rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-11r {
    padding-right: 11rem;
  }
}

.mrt-xs-12r {
  margin-top: 12rem;
}

@media (min-width: 544px) {
  .mrt-sm-12r {
    margin-top: 12rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-12r {
    margin-top: 12rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-12r {
    margin-top: 12rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-12r {
    margin-top: 12rem;
  }
}

.mrb-xs-12r {
  margin-bottom: 12rem;
}

@media (min-width: 544px) {
  .mrb-sm-12r {
    margin-bottom: 12rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-12r {
    margin-bottom: 12rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-12r {
    margin-bottom: 12rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-12r {
    margin-bottom: 12rem;
  }
}

.pdl-xs-12r {
  padding-left: 12rem;
}

@media (min-width: 544px) {
  .pdl-sm-12r {
    padding-left: 12rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-12r {
    padding-left: 12rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-12r {
    padding-left: 12rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-12r {
    padding-left: 12rem;
  }
}

.pdr-xs-12r {
  padding-right: 12rem;
}

@media (min-width: 544px) {
  .pdr-sm-12r {
    padding-right: 12rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-12r {
    padding-right: 12rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-12r {
    padding-right: 12rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-12r {
    padding-right: 12rem;
  }
}

.mrt-xs-13r {
  margin-top: 13rem;
}

@media (min-width: 544px) {
  .mrt-sm-13r {
    margin-top: 13rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-13r {
    margin-top: 13rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-13r {
    margin-top: 13rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-13r {
    margin-top: 13rem;
  }
}

.mrb-xs-13r {
  margin-bottom: 13rem;
}

@media (min-width: 544px) {
  .mrb-sm-13r {
    margin-bottom: 13rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-13r {
    margin-bottom: 13rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-13r {
    margin-bottom: 13rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-13r {
    margin-bottom: 13rem;
  }
}

.pdl-xs-13r {
  padding-left: 13rem;
}

@media (min-width: 544px) {
  .pdl-sm-13r {
    padding-left: 13rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-13r {
    padding-left: 13rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-13r {
    padding-left: 13rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-13r {
    padding-left: 13rem;
  }
}

.pdr-xs-13r {
  padding-right: 13rem;
}

@media (min-width: 544px) {
  .pdr-sm-13r {
    padding-right: 13rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-13r {
    padding-right: 13rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-13r {
    padding-right: 13rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-13r {
    padding-right: 13rem;
  }
}

.mrt-xs-14r {
  margin-top: 14rem;
}

@media (min-width: 544px) {
  .mrt-sm-14r {
    margin-top: 14rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-14r {
    margin-top: 14rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-14r {
    margin-top: 14rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-14r {
    margin-top: 14rem;
  }
}

.mrb-xs-14r {
  margin-bottom: 14rem;
}

@media (min-width: 544px) {
  .mrb-sm-14r {
    margin-bottom: 14rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-14r {
    margin-bottom: 14rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-14r {
    margin-bottom: 14rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-14r {
    margin-bottom: 14rem;
  }
}

.pdl-xs-14r {
  padding-left: 14rem;
}

@media (min-width: 544px) {
  .pdl-sm-14r {
    padding-left: 14rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-14r {
    padding-left: 14rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-14r {
    padding-left: 14rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-14r {
    padding-left: 14rem;
  }
}

.pdr-xs-14r {
  padding-right: 14rem;
}

@media (min-width: 544px) {
  .pdr-sm-14r {
    padding-right: 14rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-14r {
    padding-right: 14rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-14r {
    padding-right: 14rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-14r {
    padding-right: 14rem;
  }
}

.mrt-xs-15r {
  margin-top: 15rem;
}

@media (min-width: 544px) {
  .mrt-sm-15r {
    margin-top: 15rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-15r {
    margin-top: 15rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-15r {
    margin-top: 15rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-15r {
    margin-top: 15rem;
  }
}

.mrb-xs-15r {
  margin-bottom: 15rem;
}

@media (min-width: 544px) {
  .mrb-sm-15r {
    margin-bottom: 15rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-15r {
    margin-bottom: 15rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-15r {
    margin-bottom: 15rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-15r {
    margin-bottom: 15rem;
  }
}

.pdl-xs-15r {
  padding-left: 15rem;
}

@media (min-width: 544px) {
  .pdl-sm-15r {
    padding-left: 15rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-15r {
    padding-left: 15rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-15r {
    padding-left: 15rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-15r {
    padding-left: 15rem;
  }
}

.pdr-xs-15r {
  padding-right: 15rem;
}

@media (min-width: 544px) {
  .pdr-sm-15r {
    padding-right: 15rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-15r {
    padding-right: 15rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-15r {
    padding-right: 15rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-15r {
    padding-right: 15rem;
  }
}

.mrt-xs-16r {
  margin-top: 16rem;
}

@media (min-width: 544px) {
  .mrt-sm-16r {
    margin-top: 16rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-16r {
    margin-top: 16rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-16r {
    margin-top: 16rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-16r {
    margin-top: 16rem;
  }
}

.mrb-xs-16r {
  margin-bottom: 16rem;
}

@media (min-width: 544px) {
  .mrb-sm-16r {
    margin-bottom: 16rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-16r {
    margin-bottom: 16rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-16r {
    margin-bottom: 16rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-16r {
    margin-bottom: 16rem;
  }
}

.pdl-xs-16r {
  padding-left: 16rem;
}

@media (min-width: 544px) {
  .pdl-sm-16r {
    padding-left: 16rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-16r {
    padding-left: 16rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-16r {
    padding-left: 16rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-16r {
    padding-left: 16rem;
  }
}

.pdr-xs-16r {
  padding-right: 16rem;
}

@media (min-width: 544px) {
  .pdr-sm-16r {
    padding-right: 16rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-16r {
    padding-right: 16rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-16r {
    padding-right: 16rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-16r {
    padding-right: 16rem;
  }
}

.mrt-xs-17r {
  margin-top: 17rem;
}

@media (min-width: 544px) {
  .mrt-sm-17r {
    margin-top: 17rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-17r {
    margin-top: 17rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-17r {
    margin-top: 17rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-17r {
    margin-top: 17rem;
  }
}

.mrb-xs-17r {
  margin-bottom: 17rem;
}

@media (min-width: 544px) {
  .mrb-sm-17r {
    margin-bottom: 17rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-17r {
    margin-bottom: 17rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-17r {
    margin-bottom: 17rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-17r {
    margin-bottom: 17rem;
  }
}

.pdl-xs-17r {
  padding-left: 17rem;
}

@media (min-width: 544px) {
  .pdl-sm-17r {
    padding-left: 17rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-17r {
    padding-left: 17rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-17r {
    padding-left: 17rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-17r {
    padding-left: 17rem;
  }
}

.pdr-xs-17r {
  padding-right: 17rem;
}

@media (min-width: 544px) {
  .pdr-sm-17r {
    padding-right: 17rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-17r {
    padding-right: 17rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-17r {
    padding-right: 17rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-17r {
    padding-right: 17rem;
  }
}

.mrt-xs-18r {
  margin-top: 18rem;
}

@media (min-width: 544px) {
  .mrt-sm-18r {
    margin-top: 18rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-18r {
    margin-top: 18rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-18r {
    margin-top: 18rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-18r {
    margin-top: 18rem;
  }
}

.mrb-xs-18r {
  margin-bottom: 18rem;
}

@media (min-width: 544px) {
  .mrb-sm-18r {
    margin-bottom: 18rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-18r {
    margin-bottom: 18rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-18r {
    margin-bottom: 18rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-18r {
    margin-bottom: 18rem;
  }
}

.pdl-xs-18r {
  padding-left: 18rem;
}

@media (min-width: 544px) {
  .pdl-sm-18r {
    padding-left: 18rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-18r {
    padding-left: 18rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-18r {
    padding-left: 18rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-18r {
    padding-left: 18rem;
  }
}

.pdr-xs-18r {
  padding-right: 18rem;
}

@media (min-width: 544px) {
  .pdr-sm-18r {
    padding-right: 18rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-18r {
    padding-right: 18rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-18r {
    padding-right: 18rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-18r {
    padding-right: 18rem;
  }
}

.mrt-xs-19r {
  margin-top: 19rem;
}

@media (min-width: 544px) {
  .mrt-sm-19r {
    margin-top: 19rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-19r {
    margin-top: 19rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-19r {
    margin-top: 19rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-19r {
    margin-top: 19rem;
  }
}

.mrb-xs-19r {
  margin-bottom: 19rem;
}

@media (min-width: 544px) {
  .mrb-sm-19r {
    margin-bottom: 19rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-19r {
    margin-bottom: 19rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-19r {
    margin-bottom: 19rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-19r {
    margin-bottom: 19rem;
  }
}

.pdl-xs-19r {
  padding-left: 19rem;
}

@media (min-width: 544px) {
  .pdl-sm-19r {
    padding-left: 19rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-19r {
    padding-left: 19rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-19r {
    padding-left: 19rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-19r {
    padding-left: 19rem;
  }
}

.pdr-xs-19r {
  padding-right: 19rem;
}

@media (min-width: 544px) {
  .pdr-sm-19r {
    padding-right: 19rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-19r {
    padding-right: 19rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-19r {
    padding-right: 19rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-19r {
    padding-right: 19rem;
  }
}

.mrt-xs-20r {
  margin-top: 20rem;
}

@media (min-width: 544px) {
  .mrt-sm-20r {
    margin-top: 20rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-20r {
    margin-top: 20rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-20r {
    margin-top: 20rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-20r {
    margin-top: 20rem;
  }
}

.mrb-xs-20r {
  margin-bottom: 20rem;
}

@media (min-width: 544px) {
  .mrb-sm-20r {
    margin-bottom: 20rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-20r {
    margin-bottom: 20rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-20r {
    margin-bottom: 20rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-20r {
    margin-bottom: 20rem;
  }
}

.pdl-xs-20r {
  padding-left: 20rem;
}

@media (min-width: 544px) {
  .pdl-sm-20r {
    padding-left: 20rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-20r {
    padding-left: 20rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-20r {
    padding-left: 20rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-20r {
    padding-left: 20rem;
  }
}

.pdr-xs-20r {
  padding-right: 20rem;
}

@media (min-width: 544px) {
  .pdr-sm-20r {
    padding-right: 20rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-20r {
    padding-right: 20rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-20r {
    padding-right: 20rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-20r {
    padding-right: 20rem;
  }
}

.mrt-xs-21r {
  margin-top: 21rem;
}

@media (min-width: 544px) {
  .mrt-sm-21r {
    margin-top: 21rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-21r {
    margin-top: 21rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-21r {
    margin-top: 21rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-21r {
    margin-top: 21rem;
  }
}

.mrb-xs-21r {
  margin-bottom: 21rem;
}

@media (min-width: 544px) {
  .mrb-sm-21r {
    margin-bottom: 21rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-21r {
    margin-bottom: 21rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-21r {
    margin-bottom: 21rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-21r {
    margin-bottom: 21rem;
  }
}

.pdl-xs-21r {
  padding-left: 21rem;
}

@media (min-width: 544px) {
  .pdl-sm-21r {
    padding-left: 21rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-21r {
    padding-left: 21rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-21r {
    padding-left: 21rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-21r {
    padding-left: 21rem;
  }
}

.pdr-xs-21r {
  padding-right: 21rem;
}

@media (min-width: 544px) {
  .pdr-sm-21r {
    padding-right: 21rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-21r {
    padding-right: 21rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-21r {
    padding-right: 21rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-21r {
    padding-right: 21rem;
  }
}

.mrt-xs-22r {
  margin-top: 22rem;
}

@media (min-width: 544px) {
  .mrt-sm-22r {
    margin-top: 22rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-22r {
    margin-top: 22rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-22r {
    margin-top: 22rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-22r {
    margin-top: 22rem;
  }
}

.mrb-xs-22r {
  margin-bottom: 22rem;
}

@media (min-width: 544px) {
  .mrb-sm-22r {
    margin-bottom: 22rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-22r {
    margin-bottom: 22rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-22r {
    margin-bottom: 22rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-22r {
    margin-bottom: 22rem;
  }
}

.pdl-xs-22r {
  padding-left: 22rem;
}

@media (min-width: 544px) {
  .pdl-sm-22r {
    padding-left: 22rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-22r {
    padding-left: 22rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-22r {
    padding-left: 22rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-22r {
    padding-left: 22rem;
  }
}

.pdr-xs-22r {
  padding-right: 22rem;
}

@media (min-width: 544px) {
  .pdr-sm-22r {
    padding-right: 22rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-22r {
    padding-right: 22rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-22r {
    padding-right: 22rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-22r {
    padding-right: 22rem;
  }
}

.mrt-xs-23r {
  margin-top: 23rem;
}

@media (min-width: 544px) {
  .mrt-sm-23r {
    margin-top: 23rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-23r {
    margin-top: 23rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-23r {
    margin-top: 23rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-23r {
    margin-top: 23rem;
  }
}

.mrb-xs-23r {
  margin-bottom: 23rem;
}

@media (min-width: 544px) {
  .mrb-sm-23r {
    margin-bottom: 23rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-23r {
    margin-bottom: 23rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-23r {
    margin-bottom: 23rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-23r {
    margin-bottom: 23rem;
  }
}

.pdl-xs-23r {
  padding-left: 23rem;
}

@media (min-width: 544px) {
  .pdl-sm-23r {
    padding-left: 23rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-23r {
    padding-left: 23rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-23r {
    padding-left: 23rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-23r {
    padding-left: 23rem;
  }
}

.pdr-xs-23r {
  padding-right: 23rem;
}

@media (min-width: 544px) {
  .pdr-sm-23r {
    padding-right: 23rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-23r {
    padding-right: 23rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-23r {
    padding-right: 23rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-23r {
    padding-right: 23rem;
  }
}

.mrt-xs-24r {
  margin-top: 24rem;
}

@media (min-width: 544px) {
  .mrt-sm-24r {
    margin-top: 24rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-24r {
    margin-top: 24rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-24r {
    margin-top: 24rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-24r {
    margin-top: 24rem;
  }
}

.mrb-xs-24r {
  margin-bottom: 24rem;
}

@media (min-width: 544px) {
  .mrb-sm-24r {
    margin-bottom: 24rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-24r {
    margin-bottom: 24rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-24r {
    margin-bottom: 24rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-24r {
    margin-bottom: 24rem;
  }
}

.pdl-xs-24r {
  padding-left: 24rem;
}

@media (min-width: 544px) {
  .pdl-sm-24r {
    padding-left: 24rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-24r {
    padding-left: 24rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-24r {
    padding-left: 24rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-24r {
    padding-left: 24rem;
  }
}

.pdr-xs-24r {
  padding-right: 24rem;
}

@media (min-width: 544px) {
  .pdr-sm-24r {
    padding-right: 24rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-24r {
    padding-right: 24rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-24r {
    padding-right: 24rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-24r {
    padding-right: 24rem;
  }
}

.mrt-xs-25r {
  margin-top: 25rem;
}

@media (min-width: 544px) {
  .mrt-sm-25r {
    margin-top: 25rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-25r {
    margin-top: 25rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-25r {
    margin-top: 25rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-25r {
    margin-top: 25rem;
  }
}

.mrb-xs-25r {
  margin-bottom: 25rem;
}

@media (min-width: 544px) {
  .mrb-sm-25r {
    margin-bottom: 25rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-25r {
    margin-bottom: 25rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-25r {
    margin-bottom: 25rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-25r {
    margin-bottom: 25rem;
  }
}

.pdl-xs-25r {
  padding-left: 25rem;
}

@media (min-width: 544px) {
  .pdl-sm-25r {
    padding-left: 25rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-25r {
    padding-left: 25rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-25r {
    padding-left: 25rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-25r {
    padding-left: 25rem;
  }
}

.pdr-xs-25r {
  padding-right: 25rem;
}

@media (min-width: 544px) {
  .pdr-sm-25r {
    padding-right: 25rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-25r {
    padding-right: 25rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-25r {
    padding-right: 25rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-25r {
    padding-right: 25rem;
  }
}

.mrt-xs-26r {
  margin-top: 26rem;
}

@media (min-width: 544px) {
  .mrt-sm-26r {
    margin-top: 26rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-26r {
    margin-top: 26rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-26r {
    margin-top: 26rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-26r {
    margin-top: 26rem;
  }
}

.mrb-xs-26r {
  margin-bottom: 26rem;
}

@media (min-width: 544px) {
  .mrb-sm-26r {
    margin-bottom: 26rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-26r {
    margin-bottom: 26rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-26r {
    margin-bottom: 26rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-26r {
    margin-bottom: 26rem;
  }
}

.pdl-xs-26r {
  padding-left: 26rem;
}

@media (min-width: 544px) {
  .pdl-sm-26r {
    padding-left: 26rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-26r {
    padding-left: 26rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-26r {
    padding-left: 26rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-26r {
    padding-left: 26rem;
  }
}

.pdr-xs-26r {
  padding-right: 26rem;
}

@media (min-width: 544px) {
  .pdr-sm-26r {
    padding-right: 26rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-26r {
    padding-right: 26rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-26r {
    padding-right: 26rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-26r {
    padding-right: 26rem;
  }
}

.mrt-xs-27r {
  margin-top: 27rem;
}

@media (min-width: 544px) {
  .mrt-sm-27r {
    margin-top: 27rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-27r {
    margin-top: 27rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-27r {
    margin-top: 27rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-27r {
    margin-top: 27rem;
  }
}

.mrb-xs-27r {
  margin-bottom: 27rem;
}

@media (min-width: 544px) {
  .mrb-sm-27r {
    margin-bottom: 27rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-27r {
    margin-bottom: 27rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-27r {
    margin-bottom: 27rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-27r {
    margin-bottom: 27rem;
  }
}

.pdl-xs-27r {
  padding-left: 27rem;
}

@media (min-width: 544px) {
  .pdl-sm-27r {
    padding-left: 27rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-27r {
    padding-left: 27rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-27r {
    padding-left: 27rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-27r {
    padding-left: 27rem;
  }
}

.pdr-xs-27r {
  padding-right: 27rem;
}

@media (min-width: 544px) {
  .pdr-sm-27r {
    padding-right: 27rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-27r {
    padding-right: 27rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-27r {
    padding-right: 27rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-27r {
    padding-right: 27rem;
  }
}

.mrt-xs-28r {
  margin-top: 28rem;
}

@media (min-width: 544px) {
  .mrt-sm-28r {
    margin-top: 28rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-28r {
    margin-top: 28rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-28r {
    margin-top: 28rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-28r {
    margin-top: 28rem;
  }
}

.mrb-xs-28r {
  margin-bottom: 28rem;
}

@media (min-width: 544px) {
  .mrb-sm-28r {
    margin-bottom: 28rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-28r {
    margin-bottom: 28rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-28r {
    margin-bottom: 28rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-28r {
    margin-bottom: 28rem;
  }
}

.pdl-xs-28r {
  padding-left: 28rem;
}

@media (min-width: 544px) {
  .pdl-sm-28r {
    padding-left: 28rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-28r {
    padding-left: 28rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-28r {
    padding-left: 28rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-28r {
    padding-left: 28rem;
  }
}

.pdr-xs-28r {
  padding-right: 28rem;
}

@media (min-width: 544px) {
  .pdr-sm-28r {
    padding-right: 28rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-28r {
    padding-right: 28rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-28r {
    padding-right: 28rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-28r {
    padding-right: 28rem;
  }
}

.mrt-xs-29r {
  margin-top: 29rem;
}

@media (min-width: 544px) {
  .mrt-sm-29r {
    margin-top: 29rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-29r {
    margin-top: 29rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-29r {
    margin-top: 29rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-29r {
    margin-top: 29rem;
  }
}

.mrb-xs-29r {
  margin-bottom: 29rem;
}

@media (min-width: 544px) {
  .mrb-sm-29r {
    margin-bottom: 29rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-29r {
    margin-bottom: 29rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-29r {
    margin-bottom: 29rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-29r {
    margin-bottom: 29rem;
  }
}

.pdl-xs-29r {
  padding-left: 29rem;
}

@media (min-width: 544px) {
  .pdl-sm-29r {
    padding-left: 29rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-29r {
    padding-left: 29rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-29r {
    padding-left: 29rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-29r {
    padding-left: 29rem;
  }
}

.pdr-xs-29r {
  padding-right: 29rem;
}

@media (min-width: 544px) {
  .pdr-sm-29r {
    padding-right: 29rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-29r {
    padding-right: 29rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-29r {
    padding-right: 29rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-29r {
    padding-right: 29rem;
  }
}

.mrt-xs-30r {
  margin-top: 30rem;
}

@media (min-width: 544px) {
  .mrt-sm-30r {
    margin-top: 30rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-30r {
    margin-top: 30rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-30r {
    margin-top: 30rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-30r {
    margin-top: 30rem;
  }
}

.mrb-xs-30r {
  margin-bottom: 30rem;
}

@media (min-width: 544px) {
  .mrb-sm-30r {
    margin-bottom: 30rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-30r {
    margin-bottom: 30rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-30r {
    margin-bottom: 30rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-30r {
    margin-bottom: 30rem;
  }
}

.pdl-xs-30r {
  padding-left: 30rem;
}

@media (min-width: 544px) {
  .pdl-sm-30r {
    padding-left: 30rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-30r {
    padding-left: 30rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-30r {
    padding-left: 30rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-30r {
    padding-left: 30rem;
  }
}

.pdr-xs-30r {
  padding-right: 30rem;
}

@media (min-width: 544px) {
  .pdr-sm-30r {
    padding-right: 30rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-30r {
    padding-right: 30rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-30r {
    padding-right: 30rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-30r {
    padding-right: 30rem;
  }
}

.mrt-xs-31r {
  margin-top: 31rem;
}

@media (min-width: 544px) {
  .mrt-sm-31r {
    margin-top: 31rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-31r {
    margin-top: 31rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-31r {
    margin-top: 31rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-31r {
    margin-top: 31rem;
  }
}

.mrb-xs-31r {
  margin-bottom: 31rem;
}

@media (min-width: 544px) {
  .mrb-sm-31r {
    margin-bottom: 31rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-31r {
    margin-bottom: 31rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-31r {
    margin-bottom: 31rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-31r {
    margin-bottom: 31rem;
  }
}

.pdl-xs-31r {
  padding-left: 31rem;
}

@media (min-width: 544px) {
  .pdl-sm-31r {
    padding-left: 31rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-31r {
    padding-left: 31rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-31r {
    padding-left: 31rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-31r {
    padding-left: 31rem;
  }
}

.pdr-xs-31r {
  padding-right: 31rem;
}

@media (min-width: 544px) {
  .pdr-sm-31r {
    padding-right: 31rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-31r {
    padding-right: 31rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-31r {
    padding-right: 31rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-31r {
    padding-right: 31rem;
  }
}

.mrt-xs-32r {
  margin-top: 32rem;
}

@media (min-width: 544px) {
  .mrt-sm-32r {
    margin-top: 32rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-32r {
    margin-top: 32rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-32r {
    margin-top: 32rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-32r {
    margin-top: 32rem;
  }
}

.mrb-xs-32r {
  margin-bottom: 32rem;
}

@media (min-width: 544px) {
  .mrb-sm-32r {
    margin-bottom: 32rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-32r {
    margin-bottom: 32rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-32r {
    margin-bottom: 32rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-32r {
    margin-bottom: 32rem;
  }
}

.pdl-xs-32r {
  padding-left: 32rem;
}

@media (min-width: 544px) {
  .pdl-sm-32r {
    padding-left: 32rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-32r {
    padding-left: 32rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-32r {
    padding-left: 32rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-32r {
    padding-left: 32rem;
  }
}

.pdr-xs-32r {
  padding-right: 32rem;
}

@media (min-width: 544px) {
  .pdr-sm-32r {
    padding-right: 32rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-32r {
    padding-right: 32rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-32r {
    padding-right: 32rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-32r {
    padding-right: 32rem;
  }
}

.mrt-xs-33r {
  margin-top: 33rem;
}

@media (min-width: 544px) {
  .mrt-sm-33r {
    margin-top: 33rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-33r {
    margin-top: 33rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-33r {
    margin-top: 33rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-33r {
    margin-top: 33rem;
  }
}

.mrb-xs-33r {
  margin-bottom: 33rem;
}

@media (min-width: 544px) {
  .mrb-sm-33r {
    margin-bottom: 33rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-33r {
    margin-bottom: 33rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-33r {
    margin-bottom: 33rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-33r {
    margin-bottom: 33rem;
  }
}

.pdl-xs-33r {
  padding-left: 33rem;
}

@media (min-width: 544px) {
  .pdl-sm-33r {
    padding-left: 33rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-33r {
    padding-left: 33rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-33r {
    padding-left: 33rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-33r {
    padding-left: 33rem;
  }
}

.pdr-xs-33r {
  padding-right: 33rem;
}

@media (min-width: 544px) {
  .pdr-sm-33r {
    padding-right: 33rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-33r {
    padding-right: 33rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-33r {
    padding-right: 33rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-33r {
    padding-right: 33rem;
  }
}

.mrt-xs-34r {
  margin-top: 34rem;
}

@media (min-width: 544px) {
  .mrt-sm-34r {
    margin-top: 34rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-34r {
    margin-top: 34rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-34r {
    margin-top: 34rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-34r {
    margin-top: 34rem;
  }
}

.mrb-xs-34r {
  margin-bottom: 34rem;
}

@media (min-width: 544px) {
  .mrb-sm-34r {
    margin-bottom: 34rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-34r {
    margin-bottom: 34rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-34r {
    margin-bottom: 34rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-34r {
    margin-bottom: 34rem;
  }
}

.pdl-xs-34r {
  padding-left: 34rem;
}

@media (min-width: 544px) {
  .pdl-sm-34r {
    padding-left: 34rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-34r {
    padding-left: 34rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-34r {
    padding-left: 34rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-34r {
    padding-left: 34rem;
  }
}

.pdr-xs-34r {
  padding-right: 34rem;
}

@media (min-width: 544px) {
  .pdr-sm-34r {
    padding-right: 34rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-34r {
    padding-right: 34rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-34r {
    padding-right: 34rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-34r {
    padding-right: 34rem;
  }
}

.mrt-xs-35r {
  margin-top: 35rem;
}

@media (min-width: 544px) {
  .mrt-sm-35r {
    margin-top: 35rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-35r {
    margin-top: 35rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-35r {
    margin-top: 35rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-35r {
    margin-top: 35rem;
  }
}

.mrb-xs-35r {
  margin-bottom: 35rem;
}

@media (min-width: 544px) {
  .mrb-sm-35r {
    margin-bottom: 35rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-35r {
    margin-bottom: 35rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-35r {
    margin-bottom: 35rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-35r {
    margin-bottom: 35rem;
  }
}

.pdl-xs-35r {
  padding-left: 35rem;
}

@media (min-width: 544px) {
  .pdl-sm-35r {
    padding-left: 35rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-35r {
    padding-left: 35rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-35r {
    padding-left: 35rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-35r {
    padding-left: 35rem;
  }
}

.pdr-xs-35r {
  padding-right: 35rem;
}

@media (min-width: 544px) {
  .pdr-sm-35r {
    padding-right: 35rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-35r {
    padding-right: 35rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-35r {
    padding-right: 35rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-35r {
    padding-right: 35rem;
  }
}

.mrt-xs-36r {
  margin-top: 36rem;
}

@media (min-width: 544px) {
  .mrt-sm-36r {
    margin-top: 36rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-36r {
    margin-top: 36rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-36r {
    margin-top: 36rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-36r {
    margin-top: 36rem;
  }
}

.mrb-xs-36r {
  margin-bottom: 36rem;
}

@media (min-width: 544px) {
  .mrb-sm-36r {
    margin-bottom: 36rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-36r {
    margin-bottom: 36rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-36r {
    margin-bottom: 36rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-36r {
    margin-bottom: 36rem;
  }
}

.pdl-xs-36r {
  padding-left: 36rem;
}

@media (min-width: 544px) {
  .pdl-sm-36r {
    padding-left: 36rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-36r {
    padding-left: 36rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-36r {
    padding-left: 36rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-36r {
    padding-left: 36rem;
  }
}

.pdr-xs-36r {
  padding-right: 36rem;
}

@media (min-width: 544px) {
  .pdr-sm-36r {
    padding-right: 36rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-36r {
    padding-right: 36rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-36r {
    padding-right: 36rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-36r {
    padding-right: 36rem;
  }
}

.mrt-xs-37r {
  margin-top: 37rem;
}

@media (min-width: 544px) {
  .mrt-sm-37r {
    margin-top: 37rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-37r {
    margin-top: 37rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-37r {
    margin-top: 37rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-37r {
    margin-top: 37rem;
  }
}

.mrb-xs-37r {
  margin-bottom: 37rem;
}

@media (min-width: 544px) {
  .mrb-sm-37r {
    margin-bottom: 37rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-37r {
    margin-bottom: 37rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-37r {
    margin-bottom: 37rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-37r {
    margin-bottom: 37rem;
  }
}

.pdl-xs-37r {
  padding-left: 37rem;
}

@media (min-width: 544px) {
  .pdl-sm-37r {
    padding-left: 37rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-37r {
    padding-left: 37rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-37r {
    padding-left: 37rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-37r {
    padding-left: 37rem;
  }
}

.pdr-xs-37r {
  padding-right: 37rem;
}

@media (min-width: 544px) {
  .pdr-sm-37r {
    padding-right: 37rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-37r {
    padding-right: 37rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-37r {
    padding-right: 37rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-37r {
    padding-right: 37rem;
  }
}

.mrt-xs-38r {
  margin-top: 38rem;
}

@media (min-width: 544px) {
  .mrt-sm-38r {
    margin-top: 38rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-38r {
    margin-top: 38rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-38r {
    margin-top: 38rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-38r {
    margin-top: 38rem;
  }
}

.mrb-xs-38r {
  margin-bottom: 38rem;
}

@media (min-width: 544px) {
  .mrb-sm-38r {
    margin-bottom: 38rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-38r {
    margin-bottom: 38rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-38r {
    margin-bottom: 38rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-38r {
    margin-bottom: 38rem;
  }
}

.pdl-xs-38r {
  padding-left: 38rem;
}

@media (min-width: 544px) {
  .pdl-sm-38r {
    padding-left: 38rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-38r {
    padding-left: 38rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-38r {
    padding-left: 38rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-38r {
    padding-left: 38rem;
  }
}

.pdr-xs-38r {
  padding-right: 38rem;
}

@media (min-width: 544px) {
  .pdr-sm-38r {
    padding-right: 38rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-38r {
    padding-right: 38rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-38r {
    padding-right: 38rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-38r {
    padding-right: 38rem;
  }
}

.mrt-xs-39r {
  margin-top: 39rem;
}

@media (min-width: 544px) {
  .mrt-sm-39r {
    margin-top: 39rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-39r {
    margin-top: 39rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-39r {
    margin-top: 39rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-39r {
    margin-top: 39rem;
  }
}

.mrb-xs-39r {
  margin-bottom: 39rem;
}

@media (min-width: 544px) {
  .mrb-sm-39r {
    margin-bottom: 39rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-39r {
    margin-bottom: 39rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-39r {
    margin-bottom: 39rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-39r {
    margin-bottom: 39rem;
  }
}

.pdl-xs-39r {
  padding-left: 39rem;
}

@media (min-width: 544px) {
  .pdl-sm-39r {
    padding-left: 39rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-39r {
    padding-left: 39rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-39r {
    padding-left: 39rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-39r {
    padding-left: 39rem;
  }
}

.pdr-xs-39r {
  padding-right: 39rem;
}

@media (min-width: 544px) {
  .pdr-sm-39r {
    padding-right: 39rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-39r {
    padding-right: 39rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-39r {
    padding-right: 39rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-39r {
    padding-right: 39rem;
  }
}

.mrt-xs-40r {
  margin-top: 40rem;
}

@media (min-width: 544px) {
  .mrt-sm-40r {
    margin-top: 40rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-40r {
    margin-top: 40rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-40r {
    margin-top: 40rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-40r {
    margin-top: 40rem;
  }
}

.mrb-xs-40r {
  margin-bottom: 40rem;
}

@media (min-width: 544px) {
  .mrb-sm-40r {
    margin-bottom: 40rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-40r {
    margin-bottom: 40rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-40r {
    margin-bottom: 40rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-40r {
    margin-bottom: 40rem;
  }
}

.pdl-xs-40r {
  padding-left: 40rem;
}

@media (min-width: 544px) {
  .pdl-sm-40r {
    padding-left: 40rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-40r {
    padding-left: 40rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-40r {
    padding-left: 40rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-40r {
    padding-left: 40rem;
  }
}

.pdr-xs-40r {
  padding-right: 40rem;
}

@media (min-width: 544px) {
  .pdr-sm-40r {
    padding-right: 40rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-40r {
    padding-right: 40rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-40r {
    padding-right: 40rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-40r {
    padding-right: 40rem;
  }
}

.mrt-xs-41r {
  margin-top: 41rem;
}

@media (min-width: 544px) {
  .mrt-sm-41r {
    margin-top: 41rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-41r {
    margin-top: 41rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-41r {
    margin-top: 41rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-41r {
    margin-top: 41rem;
  }
}

.mrb-xs-41r {
  margin-bottom: 41rem;
}

@media (min-width: 544px) {
  .mrb-sm-41r {
    margin-bottom: 41rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-41r {
    margin-bottom: 41rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-41r {
    margin-bottom: 41rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-41r {
    margin-bottom: 41rem;
  }
}

.pdl-xs-41r {
  padding-left: 41rem;
}

@media (min-width: 544px) {
  .pdl-sm-41r {
    padding-left: 41rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-41r {
    padding-left: 41rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-41r {
    padding-left: 41rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-41r {
    padding-left: 41rem;
  }
}

.pdr-xs-41r {
  padding-right: 41rem;
}

@media (min-width: 544px) {
  .pdr-sm-41r {
    padding-right: 41rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-41r {
    padding-right: 41rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-41r {
    padding-right: 41rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-41r {
    padding-right: 41rem;
  }
}

.mrt-xs-42r {
  margin-top: 42rem;
}

@media (min-width: 544px) {
  .mrt-sm-42r {
    margin-top: 42rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-42r {
    margin-top: 42rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-42r {
    margin-top: 42rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-42r {
    margin-top: 42rem;
  }
}

.mrb-xs-42r {
  margin-bottom: 42rem;
}

@media (min-width: 544px) {
  .mrb-sm-42r {
    margin-bottom: 42rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-42r {
    margin-bottom: 42rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-42r {
    margin-bottom: 42rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-42r {
    margin-bottom: 42rem;
  }
}

.pdl-xs-42r {
  padding-left: 42rem;
}

@media (min-width: 544px) {
  .pdl-sm-42r {
    padding-left: 42rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-42r {
    padding-left: 42rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-42r {
    padding-left: 42rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-42r {
    padding-left: 42rem;
  }
}

.pdr-xs-42r {
  padding-right: 42rem;
}

@media (min-width: 544px) {
  .pdr-sm-42r {
    padding-right: 42rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-42r {
    padding-right: 42rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-42r {
    padding-right: 42rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-42r {
    padding-right: 42rem;
  }
}

.mrt-xs-43r {
  margin-top: 43rem;
}

@media (min-width: 544px) {
  .mrt-sm-43r {
    margin-top: 43rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-43r {
    margin-top: 43rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-43r {
    margin-top: 43rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-43r {
    margin-top: 43rem;
  }
}

.mrb-xs-43r {
  margin-bottom: 43rem;
}

@media (min-width: 544px) {
  .mrb-sm-43r {
    margin-bottom: 43rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-43r {
    margin-bottom: 43rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-43r {
    margin-bottom: 43rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-43r {
    margin-bottom: 43rem;
  }
}

.pdl-xs-43r {
  padding-left: 43rem;
}

@media (min-width: 544px) {
  .pdl-sm-43r {
    padding-left: 43rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-43r {
    padding-left: 43rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-43r {
    padding-left: 43rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-43r {
    padding-left: 43rem;
  }
}

.pdr-xs-43r {
  padding-right: 43rem;
}

@media (min-width: 544px) {
  .pdr-sm-43r {
    padding-right: 43rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-43r {
    padding-right: 43rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-43r {
    padding-right: 43rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-43r {
    padding-right: 43rem;
  }
}

.mrt-xs-44r {
  margin-top: 44rem;
}

@media (min-width: 544px) {
  .mrt-sm-44r {
    margin-top: 44rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-44r {
    margin-top: 44rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-44r {
    margin-top: 44rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-44r {
    margin-top: 44rem;
  }
}

.mrb-xs-44r {
  margin-bottom: 44rem;
}

@media (min-width: 544px) {
  .mrb-sm-44r {
    margin-bottom: 44rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-44r {
    margin-bottom: 44rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-44r {
    margin-bottom: 44rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-44r {
    margin-bottom: 44rem;
  }
}

.pdl-xs-44r {
  padding-left: 44rem;
}

@media (min-width: 544px) {
  .pdl-sm-44r {
    padding-left: 44rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-44r {
    padding-left: 44rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-44r {
    padding-left: 44rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-44r {
    padding-left: 44rem;
  }
}

.pdr-xs-44r {
  padding-right: 44rem;
}

@media (min-width: 544px) {
  .pdr-sm-44r {
    padding-right: 44rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-44r {
    padding-right: 44rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-44r {
    padding-right: 44rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-44r {
    padding-right: 44rem;
  }
}

.mrt-xs-45r {
  margin-top: 45rem;
}

@media (min-width: 544px) {
  .mrt-sm-45r {
    margin-top: 45rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-45r {
    margin-top: 45rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-45r {
    margin-top: 45rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-45r {
    margin-top: 45rem;
  }
}

.mrb-xs-45r {
  margin-bottom: 45rem;
}

@media (min-width: 544px) {
  .mrb-sm-45r {
    margin-bottom: 45rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-45r {
    margin-bottom: 45rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-45r {
    margin-bottom: 45rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-45r {
    margin-bottom: 45rem;
  }
}

.pdl-xs-45r {
  padding-left: 45rem;
}

@media (min-width: 544px) {
  .pdl-sm-45r {
    padding-left: 45rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-45r {
    padding-left: 45rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-45r {
    padding-left: 45rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-45r {
    padding-left: 45rem;
  }
}

.pdr-xs-45r {
  padding-right: 45rem;
}

@media (min-width: 544px) {
  .pdr-sm-45r {
    padding-right: 45rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-45r {
    padding-right: 45rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-45r {
    padding-right: 45rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-45r {
    padding-right: 45rem;
  }
}

.mrt-xs-46r {
  margin-top: 46rem;
}

@media (min-width: 544px) {
  .mrt-sm-46r {
    margin-top: 46rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-46r {
    margin-top: 46rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-46r {
    margin-top: 46rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-46r {
    margin-top: 46rem;
  }
}

.mrb-xs-46r {
  margin-bottom: 46rem;
}

@media (min-width: 544px) {
  .mrb-sm-46r {
    margin-bottom: 46rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-46r {
    margin-bottom: 46rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-46r {
    margin-bottom: 46rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-46r {
    margin-bottom: 46rem;
  }
}

.pdl-xs-46r {
  padding-left: 46rem;
}

@media (min-width: 544px) {
  .pdl-sm-46r {
    padding-left: 46rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-46r {
    padding-left: 46rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-46r {
    padding-left: 46rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-46r {
    padding-left: 46rem;
  }
}

.pdr-xs-46r {
  padding-right: 46rem;
}

@media (min-width: 544px) {
  .pdr-sm-46r {
    padding-right: 46rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-46r {
    padding-right: 46rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-46r {
    padding-right: 46rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-46r {
    padding-right: 46rem;
  }
}

.mrt-xs-47r {
  margin-top: 47rem;
}

@media (min-width: 544px) {
  .mrt-sm-47r {
    margin-top: 47rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-47r {
    margin-top: 47rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-47r {
    margin-top: 47rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-47r {
    margin-top: 47rem;
  }
}

.mrb-xs-47r {
  margin-bottom: 47rem;
}

@media (min-width: 544px) {
  .mrb-sm-47r {
    margin-bottom: 47rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-47r {
    margin-bottom: 47rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-47r {
    margin-bottom: 47rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-47r {
    margin-bottom: 47rem;
  }
}

.pdl-xs-47r {
  padding-left: 47rem;
}

@media (min-width: 544px) {
  .pdl-sm-47r {
    padding-left: 47rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-47r {
    padding-left: 47rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-47r {
    padding-left: 47rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-47r {
    padding-left: 47rem;
  }
}

.pdr-xs-47r {
  padding-right: 47rem;
}

@media (min-width: 544px) {
  .pdr-sm-47r {
    padding-right: 47rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-47r {
    padding-right: 47rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-47r {
    padding-right: 47rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-47r {
    padding-right: 47rem;
  }
}

.mrt-xs-48r {
  margin-top: 48rem;
}

@media (min-width: 544px) {
  .mrt-sm-48r {
    margin-top: 48rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-48r {
    margin-top: 48rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-48r {
    margin-top: 48rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-48r {
    margin-top: 48rem;
  }
}

.mrb-xs-48r {
  margin-bottom: 48rem;
}

@media (min-width: 544px) {
  .mrb-sm-48r {
    margin-bottom: 48rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-48r {
    margin-bottom: 48rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-48r {
    margin-bottom: 48rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-48r {
    margin-bottom: 48rem;
  }
}

.pdl-xs-48r {
  padding-left: 48rem;
}

@media (min-width: 544px) {
  .pdl-sm-48r {
    padding-left: 48rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-48r {
    padding-left: 48rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-48r {
    padding-left: 48rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-48r {
    padding-left: 48rem;
  }
}

.pdr-xs-48r {
  padding-right: 48rem;
}

@media (min-width: 544px) {
  .pdr-sm-48r {
    padding-right: 48rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-48r {
    padding-right: 48rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-48r {
    padding-right: 48rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-48r {
    padding-right: 48rem;
  }
}

.mrt-xs-49r {
  margin-top: 49rem;
}

@media (min-width: 544px) {
  .mrt-sm-49r {
    margin-top: 49rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-49r {
    margin-top: 49rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-49r {
    margin-top: 49rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-49r {
    margin-top: 49rem;
  }
}

.mrb-xs-49r {
  margin-bottom: 49rem;
}

@media (min-width: 544px) {
  .mrb-sm-49r {
    margin-bottom: 49rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-49r {
    margin-bottom: 49rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-49r {
    margin-bottom: 49rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-49r {
    margin-bottom: 49rem;
  }
}

.pdl-xs-49r {
  padding-left: 49rem;
}

@media (min-width: 544px) {
  .pdl-sm-49r {
    padding-left: 49rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-49r {
    padding-left: 49rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-49r {
    padding-left: 49rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-49r {
    padding-left: 49rem;
  }
}

.pdr-xs-49r {
  padding-right: 49rem;
}

@media (min-width: 544px) {
  .pdr-sm-49r {
    padding-right: 49rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-49r {
    padding-right: 49rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-49r {
    padding-right: 49rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-49r {
    padding-right: 49rem;
  }
}

.mrt-xs-50r {
  margin-top: 50rem;
}

@media (min-width: 544px) {
  .mrt-sm-50r {
    margin-top: 50rem;
  }
}

@media (min-width: 768px) {
  .mrt-md-50r {
    margin-top: 50rem;
  }
}

@media (min-width: 992px) {
  .mrt-lg-50r {
    margin-top: 50rem;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-50r {
    margin-top: 50rem;
  }
}

.mrb-xs-50r {
  margin-bottom: 50rem;
}

@media (min-width: 544px) {
  .mrb-sm-50r {
    margin-bottom: 50rem;
  }
}

@media (min-width: 768px) {
  .mrb-md-50r {
    margin-bottom: 50rem;
  }
}

@media (min-width: 992px) {
  .mrb-lg-50r {
    margin-bottom: 50rem;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-50r {
    margin-bottom: 50rem;
  }
}

.pdl-xs-50r {
  padding-left: 50rem;
}

@media (min-width: 544px) {
  .pdl-sm-50r {
    padding-left: 50rem;
  }
}

@media (min-width: 768px) {
  .pdl-md-50r {
    padding-left: 50rem;
  }
}

@media (min-width: 992px) {
  .pdl-lg-50r {
    padding-left: 50rem;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-50r {
    padding-left: 50rem;
  }
}

.pdr-xs-50r {
  padding-right: 50rem;
}

@media (min-width: 544px) {
  .pdr-sm-50r {
    padding-right: 50rem;
  }
}

@media (min-width: 768px) {
  .pdr-md-50r {
    padding-right: 50rem;
  }
}

@media (min-width: 992px) {
  .pdr-lg-50r {
    padding-right: 50rem;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-50r {
    padding-right: 50rem;
  }
}

.mrt-xs-0 {
  margin-top: 0px;
}

@media (min-width: 544px) {
  .mrt-sm-0 {
    margin-top: 0px;
  }
}

@media (min-width: 768px) {
  .mrt-md-0 {
    margin-top: 0px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-0 {
    margin-top: 0px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-0 {
    margin-top: 0px;
  }
}

.mrb-xs-0 {
  margin-bottom: 0px;
}

@media (min-width: 544px) {
  .mrb-sm-0 {
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .mrb-md-0 {
    margin-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-0 {
    margin-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-0 {
    margin-bottom: 0px;
  }
}

.pdl-xs-0 {
  padding-left: 0px;
}

@media (min-width: 544px) {
  .pdl-sm-0 {
    padding-left: 0px;
  }
}

@media (min-width: 768px) {
  .pdl-md-0 {
    padding-left: 0px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-0 {
    padding-left: 0px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-0 {
    padding-left: 0px;
  }
}

.pdr-xs-0 {
  padding-right: 0px;
}

@media (min-width: 544px) {
  .pdr-sm-0 {
    padding-right: 0px;
  }
}

@media (min-width: 768px) {
  .pdr-md-0 {
    padding-right: 0px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-0 {
    padding-right: 0px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-0 {
    padding-right: 0px;
  }
}

.mrt-xs-5 {
  margin-top: 5px;
}

@media (min-width: 544px) {
  .mrt-sm-5 {
    margin-top: 5px;
  }
}

@media (min-width: 768px) {
  .mrt-md-5 {
    margin-top: 5px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-5 {
    margin-top: 5px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-5 {
    margin-top: 5px;
  }
}

.mrb-xs-5 {
  margin-bottom: 5px;
}

@media (min-width: 544px) {
  .mrb-sm-5 {
    margin-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .mrb-md-5 {
    margin-bottom: 5px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-5 {
    margin-bottom: 5px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-5 {
    margin-bottom: 5px;
  }
}

.pdl-xs-5 {
  padding-left: 5px;
}

@media (min-width: 544px) {
  .pdl-sm-5 {
    padding-left: 5px;
  }
}

@media (min-width: 768px) {
  .pdl-md-5 {
    padding-left: 5px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-5 {
    padding-left: 5px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-5 {
    padding-left: 5px;
  }
}

.pdr-xs-5 {
  padding-right: 5px;
}

@media (min-width: 544px) {
  .pdr-sm-5 {
    padding-right: 5px;
  }
}

@media (min-width: 768px) {
  .pdr-md-5 {
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-5 {
    padding-right: 5px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-5 {
    padding-right: 5px;
  }
}

.mrt-xs-10 {
  margin-top: 10px;
}

@media (min-width: 544px) {
  .mrt-sm-10 {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .mrt-md-10 {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-10 {
    margin-top: 10px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-10 {
    margin-top: 10px;
  }
}

.mrb-xs-10 {
  margin-bottom: 10px;
}

@media (min-width: 544px) {
  .mrb-sm-10 {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .mrb-md-10 {
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-10 {
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-10 {
    margin-bottom: 10px;
  }
}

.pdl-xs-10 {
  padding-left: 10px;
}

@media (min-width: 544px) {
  .pdl-sm-10 {
    padding-left: 10px;
  }
}

@media (min-width: 768px) {
  .pdl-md-10 {
    padding-left: 10px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-10 {
    padding-left: 10px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-10 {
    padding-left: 10px;
  }
}

.pdr-xs-10 {
  padding-right: 10px;
}

@media (min-width: 544px) {
  .pdr-sm-10 {
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .pdr-md-10 {
    padding-right: 10px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-10 {
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-10 {
    padding-right: 10px;
  }
}

.mrt-xs-15 {
  margin-top: 15px;
}

@media (min-width: 544px) {
  .mrt-sm-15 {
    margin-top: 15px;
  }
}

@media (min-width: 768px) {
  .mrt-md-15 {
    margin-top: 15px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-15 {
    margin-top: 15px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-15 {
    margin-top: 15px;
  }
}

.mrb-xs-15 {
  margin-bottom: 15px;
}

@media (min-width: 544px) {
  .mrb-sm-15 {
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .mrb-md-15 {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-15 {
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-15 {
    margin-bottom: 15px;
  }
}

.pdl-xs-15 {
  padding-left: 15px;
}

@media (min-width: 544px) {
  .pdl-sm-15 {
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .pdl-md-15 {
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-15 {
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-15 {
    padding-left: 15px;
  }
}

.pdr-xs-15 {
  padding-right: 15px;
}

@media (min-width: 544px) {
  .pdr-sm-15 {
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  .pdr-md-15 {
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-15 {
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-15 {
    padding-right: 15px;
  }
}

.mrt-xs-20 {
  margin-top: 20px;
}

@media (min-width: 544px) {
  .mrt-sm-20 {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .mrt-md-20 {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-20 {
    margin-top: 20px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-20 {
    margin-top: 20px;
  }
}

.mrb-xs-20 {
  margin-bottom: 20px;
}

@media (min-width: 544px) {
  .mrb-sm-20 {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .mrb-md-20 {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-20 {
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-20 {
    margin-bottom: 20px;
  }
}

.pdl-xs-20 {
  padding-left: 20px;
}

@media (min-width: 544px) {
  .pdl-sm-20 {
    padding-left: 20px;
  }
}

@media (min-width: 768px) {
  .pdl-md-20 {
    padding-left: 20px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-20 {
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-20 {
    padding-left: 20px;
  }
}

.pdr-xs-20 {
  padding-right: 20px;
}

@media (min-width: 544px) {
  .pdr-sm-20 {
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .pdr-md-20 {
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-20 {
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-20 {
    padding-right: 20px;
  }
}

.mrt-xs-25 {
  margin-top: 25px;
}

@media (min-width: 544px) {
  .mrt-sm-25 {
    margin-top: 25px;
  }
}

@media (min-width: 768px) {
  .mrt-md-25 {
    margin-top: 25px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-25 {
    margin-top: 25px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-25 {
    margin-top: 25px;
  }
}

.mrb-xs-25 {
  margin-bottom: 25px;
}

@media (min-width: 544px) {
  .mrb-sm-25 {
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) {
  .mrb-md-25 {
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-25 {
    margin-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-25 {
    margin-bottom: 25px;
  }
}

.pdl-xs-25 {
  padding-left: 25px;
}

@media (min-width: 544px) {
  .pdl-sm-25 {
    padding-left: 25px;
  }
}

@media (min-width: 768px) {
  .pdl-md-25 {
    padding-left: 25px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-25 {
    padding-left: 25px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-25 {
    padding-left: 25px;
  }
}

.pdr-xs-25 {
  padding-right: 25px;
}

@media (min-width: 544px) {
  .pdr-sm-25 {
    padding-right: 25px;
  }
}

@media (min-width: 768px) {
  .pdr-md-25 {
    padding-right: 25px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-25 {
    padding-right: 25px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-25 {
    padding-right: 25px;
  }
}

.mrt-xs-30 {
  margin-top: 30px;
}

@media (min-width: 544px) {
  .mrt-sm-30 {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .mrt-md-30 {
    margin-top: 30px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-30 {
    margin-top: 30px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-30 {
    margin-top: 30px;
  }
}

.mrb-xs-30 {
  margin-bottom: 30px;
}

@media (min-width: 544px) {
  .mrb-sm-30 {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .mrb-md-30 {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-30 {
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-30 {
    margin-bottom: 30px;
  }
}

.pdl-xs-30 {
  padding-left: 30px;
}

@media (min-width: 544px) {
  .pdl-sm-30 {
    padding-left: 30px;
  }
}

@media (min-width: 768px) {
  .pdl-md-30 {
    padding-left: 30px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-30 {
    padding-left: 30px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-30 {
    padding-left: 30px;
  }
}

.pdr-xs-30 {
  padding-right: 30px;
}

@media (min-width: 544px) {
  .pdr-sm-30 {
    padding-right: 30px;
  }
}

@media (min-width: 768px) {
  .pdr-md-30 {
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-30 {
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-30 {
    padding-right: 30px;
  }
}

.mrt-xs-35 {
  margin-top: 35px;
}

@media (min-width: 544px) {
  .mrt-sm-35 {
    margin-top: 35px;
  }
}

@media (min-width: 768px) {
  .mrt-md-35 {
    margin-top: 35px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-35 {
    margin-top: 35px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-35 {
    margin-top: 35px;
  }
}

.mrb-xs-35 {
  margin-bottom: 35px;
}

@media (min-width: 544px) {
  .mrb-sm-35 {
    margin-bottom: 35px;
  }
}

@media (min-width: 768px) {
  .mrb-md-35 {
    margin-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-35 {
    margin-bottom: 35px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-35 {
    margin-bottom: 35px;
  }
}

.pdl-xs-35 {
  padding-left: 35px;
}

@media (min-width: 544px) {
  .pdl-sm-35 {
    padding-left: 35px;
  }
}

@media (min-width: 768px) {
  .pdl-md-35 {
    padding-left: 35px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-35 {
    padding-left: 35px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-35 {
    padding-left: 35px;
  }
}

.pdr-xs-35 {
  padding-right: 35px;
}

@media (min-width: 544px) {
  .pdr-sm-35 {
    padding-right: 35px;
  }
}

@media (min-width: 768px) {
  .pdr-md-35 {
    padding-right: 35px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-35 {
    padding-right: 35px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-35 {
    padding-right: 35px;
  }
}

.mrt-xs-40 {
  margin-top: 40px;
}

@media (min-width: 544px) {
  .mrt-sm-40 {
    margin-top: 40px;
  }
}

@media (min-width: 768px) {
  .mrt-md-40 {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-40 {
    margin-top: 40px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-40 {
    margin-top: 40px;
  }
}

.mrb-xs-40 {
  margin-bottom: 40px;
}

@media (min-width: 544px) {
  .mrb-sm-40 {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .mrb-md-40 {
    margin-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-40 {
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-40 {
    margin-bottom: 40px;
  }
}

.pdl-xs-40 {
  padding-left: 40px;
}

@media (min-width: 544px) {
  .pdl-sm-40 {
    padding-left: 40px;
  }
}

@media (min-width: 768px) {
  .pdl-md-40 {
    padding-left: 40px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-40 {
    padding-left: 40px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-40 {
    padding-left: 40px;
  }
}

.pdr-xs-40 {
  padding-right: 40px;
}

@media (min-width: 544px) {
  .pdr-sm-40 {
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  .pdr-md-40 {
    padding-right: 40px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-40 {
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-40 {
    padding-right: 40px;
  }
}

.mrt-xs-45 {
  margin-top: 45px;
}

@media (min-width: 544px) {
  .mrt-sm-45 {
    margin-top: 45px;
  }
}

@media (min-width: 768px) {
  .mrt-md-45 {
    margin-top: 45px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-45 {
    margin-top: 45px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-45 {
    margin-top: 45px;
  }
}

.mrb-xs-45 {
  margin-bottom: 45px;
}

@media (min-width: 544px) {
  .mrb-sm-45 {
    margin-bottom: 45px;
  }
}

@media (min-width: 768px) {
  .mrb-md-45 {
    margin-bottom: 45px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-45 {
    margin-bottom: 45px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-45 {
    margin-bottom: 45px;
  }
}

.pdl-xs-45 {
  padding-left: 45px;
}

@media (min-width: 544px) {
  .pdl-sm-45 {
    padding-left: 45px;
  }
}

@media (min-width: 768px) {
  .pdl-md-45 {
    padding-left: 45px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-45 {
    padding-left: 45px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-45 {
    padding-left: 45px;
  }
}

.pdr-xs-45 {
  padding-right: 45px;
}

@media (min-width: 544px) {
  .pdr-sm-45 {
    padding-right: 45px;
  }
}

@media (min-width: 768px) {
  .pdr-md-45 {
    padding-right: 45px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-45 {
    padding-right: 45px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-45 {
    padding-right: 45px;
  }
}

.mrt-xs-50 {
  margin-top: 50px;
}

@media (min-width: 544px) {
  .mrt-sm-50 {
    margin-top: 50px;
  }
}

@media (min-width: 768px) {
  .mrt-md-50 {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-50 {
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-50 {
    margin-top: 50px;
  }
}

.mrb-xs-50 {
  margin-bottom: 50px;
}

@media (min-width: 544px) {
  .mrb-sm-50 {
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .mrb-md-50 {
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-50 {
    margin-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-50 {
    margin-bottom: 50px;
  }
}

.pdl-xs-50 {
  padding-left: 50px;
}

@media (min-width: 544px) {
  .pdl-sm-50 {
    padding-left: 50px;
  }
}

@media (min-width: 768px) {
  .pdl-md-50 {
    padding-left: 50px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-50 {
    padding-left: 50px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-50 {
    padding-left: 50px;
  }
}

.pdr-xs-50 {
  padding-right: 50px;
}

@media (min-width: 544px) {
  .pdr-sm-50 {
    padding-right: 50px;
  }
}

@media (min-width: 768px) {
  .pdr-md-50 {
    padding-right: 50px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-50 {
    padding-right: 50px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-50 {
    padding-right: 50px;
  }
}

.mrt-xs-55 {
  margin-top: 55px;
}

@media (min-width: 544px) {
  .mrt-sm-55 {
    margin-top: 55px;
  }
}

@media (min-width: 768px) {
  .mrt-md-55 {
    margin-top: 55px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-55 {
    margin-top: 55px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-55 {
    margin-top: 55px;
  }
}

.mrb-xs-55 {
  margin-bottom: 55px;
}

@media (min-width: 544px) {
  .mrb-sm-55 {
    margin-bottom: 55px;
  }
}

@media (min-width: 768px) {
  .mrb-md-55 {
    margin-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-55 {
    margin-bottom: 55px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-55 {
    margin-bottom: 55px;
  }
}

.pdl-xs-55 {
  padding-left: 55px;
}

@media (min-width: 544px) {
  .pdl-sm-55 {
    padding-left: 55px;
  }
}

@media (min-width: 768px) {
  .pdl-md-55 {
    padding-left: 55px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-55 {
    padding-left: 55px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-55 {
    padding-left: 55px;
  }
}

.pdr-xs-55 {
  padding-right: 55px;
}

@media (min-width: 544px) {
  .pdr-sm-55 {
    padding-right: 55px;
  }
}

@media (min-width: 768px) {
  .pdr-md-55 {
    padding-right: 55px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-55 {
    padding-right: 55px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-55 {
    padding-right: 55px;
  }
}

.mrt-xs-60 {
  margin-top: 60px;
}

@media (min-width: 544px) {
  .mrt-sm-60 {
    margin-top: 60px;
  }
}

@media (min-width: 768px) {
  .mrt-md-60 {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-60 {
    margin-top: 60px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-60 {
    margin-top: 60px;
  }
}

.mrb-xs-60 {
  margin-bottom: 60px;
}

@media (min-width: 544px) {
  .mrb-sm-60 {
    margin-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .mrb-md-60 {
    margin-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-60 {
    margin-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-60 {
    margin-bottom: 60px;
  }
}

.pdl-xs-60 {
  padding-left: 60px;
}

@media (min-width: 544px) {
  .pdl-sm-60 {
    padding-left: 60px;
  }
}

@media (min-width: 768px) {
  .pdl-md-60 {
    padding-left: 60px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-60 {
    padding-left: 60px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-60 {
    padding-left: 60px;
  }
}

.pdr-xs-60 {
  padding-right: 60px;
}

@media (min-width: 544px) {
  .pdr-sm-60 {
    padding-right: 60px;
  }
}

@media (min-width: 768px) {
  .pdr-md-60 {
    padding-right: 60px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-60 {
    padding-right: 60px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-60 {
    padding-right: 60px;
  }
}

.mrt-xs-65 {
  margin-top: 65px;
}

@media (min-width: 544px) {
  .mrt-sm-65 {
    margin-top: 65px;
  }
}

@media (min-width: 768px) {
  .mrt-md-65 {
    margin-top: 65px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-65 {
    margin-top: 65px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-65 {
    margin-top: 65px;
  }
}

.mrb-xs-65 {
  margin-bottom: 65px;
}

@media (min-width: 544px) {
  .mrb-sm-65 {
    margin-bottom: 65px;
  }
}

@media (min-width: 768px) {
  .mrb-md-65 {
    margin-bottom: 65px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-65 {
    margin-bottom: 65px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-65 {
    margin-bottom: 65px;
  }
}

.pdl-xs-65 {
  padding-left: 65px;
}

@media (min-width: 544px) {
  .pdl-sm-65 {
    padding-left: 65px;
  }
}

@media (min-width: 768px) {
  .pdl-md-65 {
    padding-left: 65px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-65 {
    padding-left: 65px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-65 {
    padding-left: 65px;
  }
}

.pdr-xs-65 {
  padding-right: 65px;
}

@media (min-width: 544px) {
  .pdr-sm-65 {
    padding-right: 65px;
  }
}

@media (min-width: 768px) {
  .pdr-md-65 {
    padding-right: 65px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-65 {
    padding-right: 65px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-65 {
    padding-right: 65px;
  }
}

.mrt-xs-70 {
  margin-top: 70px;
}

@media (min-width: 544px) {
  .mrt-sm-70 {
    margin-top: 70px;
  }
}

@media (min-width: 768px) {
  .mrt-md-70 {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-70 {
    margin-top: 70px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-70 {
    margin-top: 70px;
  }
}

.mrb-xs-70 {
  margin-bottom: 70px;
}

@media (min-width: 544px) {
  .mrb-sm-70 {
    margin-bottom: 70px;
  }
}

@media (min-width: 768px) {
  .mrb-md-70 {
    margin-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-70 {
    margin-bottom: 70px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-70 {
    margin-bottom: 70px;
  }
}

.pdl-xs-70 {
  padding-left: 70px;
}

@media (min-width: 544px) {
  .pdl-sm-70 {
    padding-left: 70px;
  }
}

@media (min-width: 768px) {
  .pdl-md-70 {
    padding-left: 70px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-70 {
    padding-left: 70px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-70 {
    padding-left: 70px;
  }
}

.pdr-xs-70 {
  padding-right: 70px;
}

@media (min-width: 544px) {
  .pdr-sm-70 {
    padding-right: 70px;
  }
}

@media (min-width: 768px) {
  .pdr-md-70 {
    padding-right: 70px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-70 {
    padding-right: 70px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-70 {
    padding-right: 70px;
  }
}

.mrt-xs-75 {
  margin-top: 75px;
}

@media (min-width: 544px) {
  .mrt-sm-75 {
    margin-top: 75px;
  }
}

@media (min-width: 768px) {
  .mrt-md-75 {
    margin-top: 75px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-75 {
    margin-top: 75px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-75 {
    margin-top: 75px;
  }
}

.mrb-xs-75 {
  margin-bottom: 75px;
}

@media (min-width: 544px) {
  .mrb-sm-75 {
    margin-bottom: 75px;
  }
}

@media (min-width: 768px) {
  .mrb-md-75 {
    margin-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-75 {
    margin-bottom: 75px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-75 {
    margin-bottom: 75px;
  }
}

.pdl-xs-75 {
  padding-left: 75px;
}

@media (min-width: 544px) {
  .pdl-sm-75 {
    padding-left: 75px;
  }
}

@media (min-width: 768px) {
  .pdl-md-75 {
    padding-left: 75px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-75 {
    padding-left: 75px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-75 {
    padding-left: 75px;
  }
}

.pdr-xs-75 {
  padding-right: 75px;
}

@media (min-width: 544px) {
  .pdr-sm-75 {
    padding-right: 75px;
  }
}

@media (min-width: 768px) {
  .pdr-md-75 {
    padding-right: 75px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-75 {
    padding-right: 75px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-75 {
    padding-right: 75px;
  }
}

.mrt-xs-80 {
  margin-top: 80px;
}

@media (min-width: 544px) {
  .mrt-sm-80 {
    margin-top: 80px;
  }
}

@media (min-width: 768px) {
  .mrt-md-80 {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-80 {
    margin-top: 80px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-80 {
    margin-top: 80px;
  }
}

.mrb-xs-80 {
  margin-bottom: 80px;
}

@media (min-width: 544px) {
  .mrb-sm-80 {
    margin-bottom: 80px;
  }
}

@media (min-width: 768px) {
  .mrb-md-80 {
    margin-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-80 {
    margin-bottom: 80px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-80 {
    margin-bottom: 80px;
  }
}

.pdl-xs-80 {
  padding-left: 80px;
}

@media (min-width: 544px) {
  .pdl-sm-80 {
    padding-left: 80px;
  }
}

@media (min-width: 768px) {
  .pdl-md-80 {
    padding-left: 80px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-80 {
    padding-left: 80px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-80 {
    padding-left: 80px;
  }
}

.pdr-xs-80 {
  padding-right: 80px;
}

@media (min-width: 544px) {
  .pdr-sm-80 {
    padding-right: 80px;
  }
}

@media (min-width: 768px) {
  .pdr-md-80 {
    padding-right: 80px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-80 {
    padding-right: 80px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-80 {
    padding-right: 80px;
  }
}

.mrt-xs-85 {
  margin-top: 85px;
}

@media (min-width: 544px) {
  .mrt-sm-85 {
    margin-top: 85px;
  }
}

@media (min-width: 768px) {
  .mrt-md-85 {
    margin-top: 85px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-85 {
    margin-top: 85px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-85 {
    margin-top: 85px;
  }
}

.mrb-xs-85 {
  margin-bottom: 85px;
}

@media (min-width: 544px) {
  .mrb-sm-85 {
    margin-bottom: 85px;
  }
}

@media (min-width: 768px) {
  .mrb-md-85 {
    margin-bottom: 85px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-85 {
    margin-bottom: 85px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-85 {
    margin-bottom: 85px;
  }
}

.pdl-xs-85 {
  padding-left: 85px;
}

@media (min-width: 544px) {
  .pdl-sm-85 {
    padding-left: 85px;
  }
}

@media (min-width: 768px) {
  .pdl-md-85 {
    padding-left: 85px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-85 {
    padding-left: 85px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-85 {
    padding-left: 85px;
  }
}

.pdr-xs-85 {
  padding-right: 85px;
}

@media (min-width: 544px) {
  .pdr-sm-85 {
    padding-right: 85px;
  }
}

@media (min-width: 768px) {
  .pdr-md-85 {
    padding-right: 85px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-85 {
    padding-right: 85px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-85 {
    padding-right: 85px;
  }
}

.mrt-xs-90 {
  margin-top: 90px;
}

@media (min-width: 544px) {
  .mrt-sm-90 {
    margin-top: 90px;
  }
}

@media (min-width: 768px) {
  .mrt-md-90 {
    margin-top: 90px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-90 {
    margin-top: 90px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-90 {
    margin-top: 90px;
  }
}

.mrb-xs-90 {
  margin-bottom: 90px;
}

@media (min-width: 544px) {
  .mrb-sm-90 {
    margin-bottom: 90px;
  }
}

@media (min-width: 768px) {
  .mrb-md-90 {
    margin-bottom: 90px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-90 {
    margin-bottom: 90px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-90 {
    margin-bottom: 90px;
  }
}

.pdl-xs-90 {
  padding-left: 90px;
}

@media (min-width: 544px) {
  .pdl-sm-90 {
    padding-left: 90px;
  }
}

@media (min-width: 768px) {
  .pdl-md-90 {
    padding-left: 90px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-90 {
    padding-left: 90px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-90 {
    padding-left: 90px;
  }
}

.pdr-xs-90 {
  padding-right: 90px;
}

@media (min-width: 544px) {
  .pdr-sm-90 {
    padding-right: 90px;
  }
}

@media (min-width: 768px) {
  .pdr-md-90 {
    padding-right: 90px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-90 {
    padding-right: 90px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-90 {
    padding-right: 90px;
  }
}

.mrt-xs-95 {
  margin-top: 95px;
}

@media (min-width: 544px) {
  .mrt-sm-95 {
    margin-top: 95px;
  }
}

@media (min-width: 768px) {
  .mrt-md-95 {
    margin-top: 95px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-95 {
    margin-top: 95px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-95 {
    margin-top: 95px;
  }
}

.mrb-xs-95 {
  margin-bottom: 95px;
}

@media (min-width: 544px) {
  .mrb-sm-95 {
    margin-bottom: 95px;
  }
}

@media (min-width: 768px) {
  .mrb-md-95 {
    margin-bottom: 95px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-95 {
    margin-bottom: 95px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-95 {
    margin-bottom: 95px;
  }
}

.pdl-xs-95 {
  padding-left: 95px;
}

@media (min-width: 544px) {
  .pdl-sm-95 {
    padding-left: 95px;
  }
}

@media (min-width: 768px) {
  .pdl-md-95 {
    padding-left: 95px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-95 {
    padding-left: 95px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-95 {
    padding-left: 95px;
  }
}

.pdr-xs-95 {
  padding-right: 95px;
}

@media (min-width: 544px) {
  .pdr-sm-95 {
    padding-right: 95px;
  }
}

@media (min-width: 768px) {
  .pdr-md-95 {
    padding-right: 95px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-95 {
    padding-right: 95px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-95 {
    padding-right: 95px;
  }
}

.mrt-xs-100 {
  margin-top: 100px;
}

@media (min-width: 544px) {
  .mrt-sm-100 {
    margin-top: 100px;
  }
}

@media (min-width: 768px) {
  .mrt-md-100 {
    margin-top: 100px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-100 {
    margin-top: 100px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-100 {
    margin-top: 100px;
  }
}

.mrb-xs-100 {
  margin-bottom: 100px;
}

@media (min-width: 544px) {
  .mrb-sm-100 {
    margin-bottom: 100px;
  }
}

@media (min-width: 768px) {
  .mrb-md-100 {
    margin-bottom: 100px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-100 {
    margin-bottom: 100px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-100 {
    margin-bottom: 100px;
  }
}

.pdl-xs-100 {
  padding-left: 100px;
}

@media (min-width: 544px) {
  .pdl-sm-100 {
    padding-left: 100px;
  }
}

@media (min-width: 768px) {
  .pdl-md-100 {
    padding-left: 100px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-100 {
    padding-left: 100px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-100 {
    padding-left: 100px;
  }
}

.pdr-xs-100 {
  padding-right: 100px;
}

@media (min-width: 544px) {
  .pdr-sm-100 {
    padding-right: 100px;
  }
}

@media (min-width: 768px) {
  .pdr-md-100 {
    padding-right: 100px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-100 {
    padding-right: 100px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-100 {
    padding-right: 100px;
  }
}

.mrt-xs-105 {
  margin-top: 105px;
}

@media (min-width: 544px) {
  .mrt-sm-105 {
    margin-top: 105px;
  }
}

@media (min-width: 768px) {
  .mrt-md-105 {
    margin-top: 105px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-105 {
    margin-top: 105px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-105 {
    margin-top: 105px;
  }
}

.mrb-xs-105 {
  margin-bottom: 105px;
}

@media (min-width: 544px) {
  .mrb-sm-105 {
    margin-bottom: 105px;
  }
}

@media (min-width: 768px) {
  .mrb-md-105 {
    margin-bottom: 105px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-105 {
    margin-bottom: 105px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-105 {
    margin-bottom: 105px;
  }
}

.pdl-xs-105 {
  padding-left: 105px;
}

@media (min-width: 544px) {
  .pdl-sm-105 {
    padding-left: 105px;
  }
}

@media (min-width: 768px) {
  .pdl-md-105 {
    padding-left: 105px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-105 {
    padding-left: 105px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-105 {
    padding-left: 105px;
  }
}

.pdr-xs-105 {
  padding-right: 105px;
}

@media (min-width: 544px) {
  .pdr-sm-105 {
    padding-right: 105px;
  }
}

@media (min-width: 768px) {
  .pdr-md-105 {
    padding-right: 105px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-105 {
    padding-right: 105px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-105 {
    padding-right: 105px;
  }
}

.mrt-xs-110 {
  margin-top: 110px;
}

@media (min-width: 544px) {
  .mrt-sm-110 {
    margin-top: 110px;
  }
}

@media (min-width: 768px) {
  .mrt-md-110 {
    margin-top: 110px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-110 {
    margin-top: 110px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-110 {
    margin-top: 110px;
  }
}

.mrb-xs-110 {
  margin-bottom: 110px;
}

@media (min-width: 544px) {
  .mrb-sm-110 {
    margin-bottom: 110px;
  }
}

@media (min-width: 768px) {
  .mrb-md-110 {
    margin-bottom: 110px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-110 {
    margin-bottom: 110px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-110 {
    margin-bottom: 110px;
  }
}

.pdl-xs-110 {
  padding-left: 110px;
}

@media (min-width: 544px) {
  .pdl-sm-110 {
    padding-left: 110px;
  }
}

@media (min-width: 768px) {
  .pdl-md-110 {
    padding-left: 110px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-110 {
    padding-left: 110px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-110 {
    padding-left: 110px;
  }
}

.pdr-xs-110 {
  padding-right: 110px;
}

@media (min-width: 544px) {
  .pdr-sm-110 {
    padding-right: 110px;
  }
}

@media (min-width: 768px) {
  .pdr-md-110 {
    padding-right: 110px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-110 {
    padding-right: 110px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-110 {
    padding-right: 110px;
  }
}

.mrt-xs-115 {
  margin-top: 115px;
}

@media (min-width: 544px) {
  .mrt-sm-115 {
    margin-top: 115px;
  }
}

@media (min-width: 768px) {
  .mrt-md-115 {
    margin-top: 115px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-115 {
    margin-top: 115px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-115 {
    margin-top: 115px;
  }
}

.mrb-xs-115 {
  margin-bottom: 115px;
}

@media (min-width: 544px) {
  .mrb-sm-115 {
    margin-bottom: 115px;
  }
}

@media (min-width: 768px) {
  .mrb-md-115 {
    margin-bottom: 115px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-115 {
    margin-bottom: 115px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-115 {
    margin-bottom: 115px;
  }
}

.pdl-xs-115 {
  padding-left: 115px;
}

@media (min-width: 544px) {
  .pdl-sm-115 {
    padding-left: 115px;
  }
}

@media (min-width: 768px) {
  .pdl-md-115 {
    padding-left: 115px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-115 {
    padding-left: 115px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-115 {
    padding-left: 115px;
  }
}

.pdr-xs-115 {
  padding-right: 115px;
}

@media (min-width: 544px) {
  .pdr-sm-115 {
    padding-right: 115px;
  }
}

@media (min-width: 768px) {
  .pdr-md-115 {
    padding-right: 115px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-115 {
    padding-right: 115px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-115 {
    padding-right: 115px;
  }
}

.mrt-xs-120 {
  margin-top: 120px;
}

@media (min-width: 544px) {
  .mrt-sm-120 {
    margin-top: 120px;
  }
}

@media (min-width: 768px) {
  .mrt-md-120 {
    margin-top: 120px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-120 {
    margin-top: 120px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-120 {
    margin-top: 120px;
  }
}

.mrb-xs-120 {
  margin-bottom: 120px;
}

@media (min-width: 544px) {
  .mrb-sm-120 {
    margin-bottom: 120px;
  }
}

@media (min-width: 768px) {
  .mrb-md-120 {
    margin-bottom: 120px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-120 {
    margin-bottom: 120px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-120 {
    margin-bottom: 120px;
  }
}

.pdl-xs-120 {
  padding-left: 120px;
}

@media (min-width: 544px) {
  .pdl-sm-120 {
    padding-left: 120px;
  }
}

@media (min-width: 768px) {
  .pdl-md-120 {
    padding-left: 120px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-120 {
    padding-left: 120px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-120 {
    padding-left: 120px;
  }
}

.pdr-xs-120 {
  padding-right: 120px;
}

@media (min-width: 544px) {
  .pdr-sm-120 {
    padding-right: 120px;
  }
}

@media (min-width: 768px) {
  .pdr-md-120 {
    padding-right: 120px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-120 {
    padding-right: 120px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-120 {
    padding-right: 120px;
  }
}

.mrt-xs-125 {
  margin-top: 125px;
}

@media (min-width: 544px) {
  .mrt-sm-125 {
    margin-top: 125px;
  }
}

@media (min-width: 768px) {
  .mrt-md-125 {
    margin-top: 125px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-125 {
    margin-top: 125px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-125 {
    margin-top: 125px;
  }
}

.mrb-xs-125 {
  margin-bottom: 125px;
}

@media (min-width: 544px) {
  .mrb-sm-125 {
    margin-bottom: 125px;
  }
}

@media (min-width: 768px) {
  .mrb-md-125 {
    margin-bottom: 125px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-125 {
    margin-bottom: 125px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-125 {
    margin-bottom: 125px;
  }
}

.pdl-xs-125 {
  padding-left: 125px;
}

@media (min-width: 544px) {
  .pdl-sm-125 {
    padding-left: 125px;
  }
}

@media (min-width: 768px) {
  .pdl-md-125 {
    padding-left: 125px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-125 {
    padding-left: 125px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-125 {
    padding-left: 125px;
  }
}

.pdr-xs-125 {
  padding-right: 125px;
}

@media (min-width: 544px) {
  .pdr-sm-125 {
    padding-right: 125px;
  }
}

@media (min-width: 768px) {
  .pdr-md-125 {
    padding-right: 125px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-125 {
    padding-right: 125px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-125 {
    padding-right: 125px;
  }
}

.mrt-xs-130 {
  margin-top: 130px;
}

@media (min-width: 544px) {
  .mrt-sm-130 {
    margin-top: 130px;
  }
}

@media (min-width: 768px) {
  .mrt-md-130 {
    margin-top: 130px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-130 {
    margin-top: 130px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-130 {
    margin-top: 130px;
  }
}

.mrb-xs-130 {
  margin-bottom: 130px;
}

@media (min-width: 544px) {
  .mrb-sm-130 {
    margin-bottom: 130px;
  }
}

@media (min-width: 768px) {
  .mrb-md-130 {
    margin-bottom: 130px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-130 {
    margin-bottom: 130px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-130 {
    margin-bottom: 130px;
  }
}

.pdl-xs-130 {
  padding-left: 130px;
}

@media (min-width: 544px) {
  .pdl-sm-130 {
    padding-left: 130px;
  }
}

@media (min-width: 768px) {
  .pdl-md-130 {
    padding-left: 130px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-130 {
    padding-left: 130px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-130 {
    padding-left: 130px;
  }
}

.pdr-xs-130 {
  padding-right: 130px;
}

@media (min-width: 544px) {
  .pdr-sm-130 {
    padding-right: 130px;
  }
}

@media (min-width: 768px) {
  .pdr-md-130 {
    padding-right: 130px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-130 {
    padding-right: 130px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-130 {
    padding-right: 130px;
  }
}

.mrt-xs-135 {
  margin-top: 135px;
}

@media (min-width: 544px) {
  .mrt-sm-135 {
    margin-top: 135px;
  }
}

@media (min-width: 768px) {
  .mrt-md-135 {
    margin-top: 135px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-135 {
    margin-top: 135px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-135 {
    margin-top: 135px;
  }
}

.mrb-xs-135 {
  margin-bottom: 135px;
}

@media (min-width: 544px) {
  .mrb-sm-135 {
    margin-bottom: 135px;
  }
}

@media (min-width: 768px) {
  .mrb-md-135 {
    margin-bottom: 135px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-135 {
    margin-bottom: 135px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-135 {
    margin-bottom: 135px;
  }
}

.pdl-xs-135 {
  padding-left: 135px;
}

@media (min-width: 544px) {
  .pdl-sm-135 {
    padding-left: 135px;
  }
}

@media (min-width: 768px) {
  .pdl-md-135 {
    padding-left: 135px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-135 {
    padding-left: 135px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-135 {
    padding-left: 135px;
  }
}

.pdr-xs-135 {
  padding-right: 135px;
}

@media (min-width: 544px) {
  .pdr-sm-135 {
    padding-right: 135px;
  }
}

@media (min-width: 768px) {
  .pdr-md-135 {
    padding-right: 135px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-135 {
    padding-right: 135px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-135 {
    padding-right: 135px;
  }
}

.mrt-xs-140 {
  margin-top: 140px;
}

@media (min-width: 544px) {
  .mrt-sm-140 {
    margin-top: 140px;
  }
}

@media (min-width: 768px) {
  .mrt-md-140 {
    margin-top: 140px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-140 {
    margin-top: 140px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-140 {
    margin-top: 140px;
  }
}

.mrb-xs-140 {
  margin-bottom: 140px;
}

@media (min-width: 544px) {
  .mrb-sm-140 {
    margin-bottom: 140px;
  }
}

@media (min-width: 768px) {
  .mrb-md-140 {
    margin-bottom: 140px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-140 {
    margin-bottom: 140px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-140 {
    margin-bottom: 140px;
  }
}

.pdl-xs-140 {
  padding-left: 140px;
}

@media (min-width: 544px) {
  .pdl-sm-140 {
    padding-left: 140px;
  }
}

@media (min-width: 768px) {
  .pdl-md-140 {
    padding-left: 140px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-140 {
    padding-left: 140px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-140 {
    padding-left: 140px;
  }
}

.pdr-xs-140 {
  padding-right: 140px;
}

@media (min-width: 544px) {
  .pdr-sm-140 {
    padding-right: 140px;
  }
}

@media (min-width: 768px) {
  .pdr-md-140 {
    padding-right: 140px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-140 {
    padding-right: 140px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-140 {
    padding-right: 140px;
  }
}

.mrt-xs-145 {
  margin-top: 145px;
}

@media (min-width: 544px) {
  .mrt-sm-145 {
    margin-top: 145px;
  }
}

@media (min-width: 768px) {
  .mrt-md-145 {
    margin-top: 145px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-145 {
    margin-top: 145px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-145 {
    margin-top: 145px;
  }
}

.mrb-xs-145 {
  margin-bottom: 145px;
}

@media (min-width: 544px) {
  .mrb-sm-145 {
    margin-bottom: 145px;
  }
}

@media (min-width: 768px) {
  .mrb-md-145 {
    margin-bottom: 145px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-145 {
    margin-bottom: 145px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-145 {
    margin-bottom: 145px;
  }
}

.pdl-xs-145 {
  padding-left: 145px;
}

@media (min-width: 544px) {
  .pdl-sm-145 {
    padding-left: 145px;
  }
}

@media (min-width: 768px) {
  .pdl-md-145 {
    padding-left: 145px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-145 {
    padding-left: 145px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-145 {
    padding-left: 145px;
  }
}

.pdr-xs-145 {
  padding-right: 145px;
}

@media (min-width: 544px) {
  .pdr-sm-145 {
    padding-right: 145px;
  }
}

@media (min-width: 768px) {
  .pdr-md-145 {
    padding-right: 145px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-145 {
    padding-right: 145px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-145 {
    padding-right: 145px;
  }
}

.mrt-xs-150 {
  margin-top: 150px;
}

@media (min-width: 544px) {
  .mrt-sm-150 {
    margin-top: 150px;
  }
}

@media (min-width: 768px) {
  .mrt-md-150 {
    margin-top: 150px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-150 {
    margin-top: 150px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-150 {
    margin-top: 150px;
  }
}

.mrb-xs-150 {
  margin-bottom: 150px;
}

@media (min-width: 544px) {
  .mrb-sm-150 {
    margin-bottom: 150px;
  }
}

@media (min-width: 768px) {
  .mrb-md-150 {
    margin-bottom: 150px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-150 {
    margin-bottom: 150px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-150 {
    margin-bottom: 150px;
  }
}

.pdl-xs-150 {
  padding-left: 150px;
}

@media (min-width: 544px) {
  .pdl-sm-150 {
    padding-left: 150px;
  }
}

@media (min-width: 768px) {
  .pdl-md-150 {
    padding-left: 150px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-150 {
    padding-left: 150px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-150 {
    padding-left: 150px;
  }
}

.pdr-xs-150 {
  padding-right: 150px;
}

@media (min-width: 544px) {
  .pdr-sm-150 {
    padding-right: 150px;
  }
}

@media (min-width: 768px) {
  .pdr-md-150 {
    padding-right: 150px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-150 {
    padding-right: 150px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-150 {
    padding-right: 150px;
  }
}

.mrt-xs-155 {
  margin-top: 155px;
}

@media (min-width: 544px) {
  .mrt-sm-155 {
    margin-top: 155px;
  }
}

@media (min-width: 768px) {
  .mrt-md-155 {
    margin-top: 155px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-155 {
    margin-top: 155px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-155 {
    margin-top: 155px;
  }
}

.mrb-xs-155 {
  margin-bottom: 155px;
}

@media (min-width: 544px) {
  .mrb-sm-155 {
    margin-bottom: 155px;
  }
}

@media (min-width: 768px) {
  .mrb-md-155 {
    margin-bottom: 155px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-155 {
    margin-bottom: 155px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-155 {
    margin-bottom: 155px;
  }
}

.pdl-xs-155 {
  padding-left: 155px;
}

@media (min-width: 544px) {
  .pdl-sm-155 {
    padding-left: 155px;
  }
}

@media (min-width: 768px) {
  .pdl-md-155 {
    padding-left: 155px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-155 {
    padding-left: 155px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-155 {
    padding-left: 155px;
  }
}

.pdr-xs-155 {
  padding-right: 155px;
}

@media (min-width: 544px) {
  .pdr-sm-155 {
    padding-right: 155px;
  }
}

@media (min-width: 768px) {
  .pdr-md-155 {
    padding-right: 155px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-155 {
    padding-right: 155px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-155 {
    padding-right: 155px;
  }
}

.mrt-xs-160 {
  margin-top: 160px;
}

@media (min-width: 544px) {
  .mrt-sm-160 {
    margin-top: 160px;
  }
}

@media (min-width: 768px) {
  .mrt-md-160 {
    margin-top: 160px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-160 {
    margin-top: 160px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-160 {
    margin-top: 160px;
  }
}

.mrb-xs-160 {
  margin-bottom: 160px;
}

@media (min-width: 544px) {
  .mrb-sm-160 {
    margin-bottom: 160px;
  }
}

@media (min-width: 768px) {
  .mrb-md-160 {
    margin-bottom: 160px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-160 {
    margin-bottom: 160px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-160 {
    margin-bottom: 160px;
  }
}

.pdl-xs-160 {
  padding-left: 160px;
}

@media (min-width: 544px) {
  .pdl-sm-160 {
    padding-left: 160px;
  }
}

@media (min-width: 768px) {
  .pdl-md-160 {
    padding-left: 160px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-160 {
    padding-left: 160px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-160 {
    padding-left: 160px;
  }
}

.pdr-xs-160 {
  padding-right: 160px;
}

@media (min-width: 544px) {
  .pdr-sm-160 {
    padding-right: 160px;
  }
}

@media (min-width: 768px) {
  .pdr-md-160 {
    padding-right: 160px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-160 {
    padding-right: 160px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-160 {
    padding-right: 160px;
  }
}

.mrt-xs-165 {
  margin-top: 165px;
}

@media (min-width: 544px) {
  .mrt-sm-165 {
    margin-top: 165px;
  }
}

@media (min-width: 768px) {
  .mrt-md-165 {
    margin-top: 165px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-165 {
    margin-top: 165px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-165 {
    margin-top: 165px;
  }
}

.mrb-xs-165 {
  margin-bottom: 165px;
}

@media (min-width: 544px) {
  .mrb-sm-165 {
    margin-bottom: 165px;
  }
}

@media (min-width: 768px) {
  .mrb-md-165 {
    margin-bottom: 165px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-165 {
    margin-bottom: 165px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-165 {
    margin-bottom: 165px;
  }
}

.pdl-xs-165 {
  padding-left: 165px;
}

@media (min-width: 544px) {
  .pdl-sm-165 {
    padding-left: 165px;
  }
}

@media (min-width: 768px) {
  .pdl-md-165 {
    padding-left: 165px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-165 {
    padding-left: 165px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-165 {
    padding-left: 165px;
  }
}

.pdr-xs-165 {
  padding-right: 165px;
}

@media (min-width: 544px) {
  .pdr-sm-165 {
    padding-right: 165px;
  }
}

@media (min-width: 768px) {
  .pdr-md-165 {
    padding-right: 165px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-165 {
    padding-right: 165px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-165 {
    padding-right: 165px;
  }
}

.mrt-xs-170 {
  margin-top: 170px;
}

@media (min-width: 544px) {
  .mrt-sm-170 {
    margin-top: 170px;
  }
}

@media (min-width: 768px) {
  .mrt-md-170 {
    margin-top: 170px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-170 {
    margin-top: 170px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-170 {
    margin-top: 170px;
  }
}

.mrb-xs-170 {
  margin-bottom: 170px;
}

@media (min-width: 544px) {
  .mrb-sm-170 {
    margin-bottom: 170px;
  }
}

@media (min-width: 768px) {
  .mrb-md-170 {
    margin-bottom: 170px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-170 {
    margin-bottom: 170px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-170 {
    margin-bottom: 170px;
  }
}

.pdl-xs-170 {
  padding-left: 170px;
}

@media (min-width: 544px) {
  .pdl-sm-170 {
    padding-left: 170px;
  }
}

@media (min-width: 768px) {
  .pdl-md-170 {
    padding-left: 170px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-170 {
    padding-left: 170px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-170 {
    padding-left: 170px;
  }
}

.pdr-xs-170 {
  padding-right: 170px;
}

@media (min-width: 544px) {
  .pdr-sm-170 {
    padding-right: 170px;
  }
}

@media (min-width: 768px) {
  .pdr-md-170 {
    padding-right: 170px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-170 {
    padding-right: 170px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-170 {
    padding-right: 170px;
  }
}

.mrt-xs-175 {
  margin-top: 175px;
}

@media (min-width: 544px) {
  .mrt-sm-175 {
    margin-top: 175px;
  }
}

@media (min-width: 768px) {
  .mrt-md-175 {
    margin-top: 175px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-175 {
    margin-top: 175px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-175 {
    margin-top: 175px;
  }
}

.mrb-xs-175 {
  margin-bottom: 175px;
}

@media (min-width: 544px) {
  .mrb-sm-175 {
    margin-bottom: 175px;
  }
}

@media (min-width: 768px) {
  .mrb-md-175 {
    margin-bottom: 175px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-175 {
    margin-bottom: 175px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-175 {
    margin-bottom: 175px;
  }
}

.pdl-xs-175 {
  padding-left: 175px;
}

@media (min-width: 544px) {
  .pdl-sm-175 {
    padding-left: 175px;
  }
}

@media (min-width: 768px) {
  .pdl-md-175 {
    padding-left: 175px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-175 {
    padding-left: 175px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-175 {
    padding-left: 175px;
  }
}

.pdr-xs-175 {
  padding-right: 175px;
}

@media (min-width: 544px) {
  .pdr-sm-175 {
    padding-right: 175px;
  }
}

@media (min-width: 768px) {
  .pdr-md-175 {
    padding-right: 175px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-175 {
    padding-right: 175px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-175 {
    padding-right: 175px;
  }
}

.mrt-xs-180 {
  margin-top: 180px;
}

@media (min-width: 544px) {
  .mrt-sm-180 {
    margin-top: 180px;
  }
}

@media (min-width: 768px) {
  .mrt-md-180 {
    margin-top: 180px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-180 {
    margin-top: 180px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-180 {
    margin-top: 180px;
  }
}

.mrb-xs-180 {
  margin-bottom: 180px;
}

@media (min-width: 544px) {
  .mrb-sm-180 {
    margin-bottom: 180px;
  }
}

@media (min-width: 768px) {
  .mrb-md-180 {
    margin-bottom: 180px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-180 {
    margin-bottom: 180px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-180 {
    margin-bottom: 180px;
  }
}

.pdl-xs-180 {
  padding-left: 180px;
}

@media (min-width: 544px) {
  .pdl-sm-180 {
    padding-left: 180px;
  }
}

@media (min-width: 768px) {
  .pdl-md-180 {
    padding-left: 180px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-180 {
    padding-left: 180px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-180 {
    padding-left: 180px;
  }
}

.pdr-xs-180 {
  padding-right: 180px;
}

@media (min-width: 544px) {
  .pdr-sm-180 {
    padding-right: 180px;
  }
}

@media (min-width: 768px) {
  .pdr-md-180 {
    padding-right: 180px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-180 {
    padding-right: 180px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-180 {
    padding-right: 180px;
  }
}

.mrt-xs-185 {
  margin-top: 185px;
}

@media (min-width: 544px) {
  .mrt-sm-185 {
    margin-top: 185px;
  }
}

@media (min-width: 768px) {
  .mrt-md-185 {
    margin-top: 185px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-185 {
    margin-top: 185px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-185 {
    margin-top: 185px;
  }
}

.mrb-xs-185 {
  margin-bottom: 185px;
}

@media (min-width: 544px) {
  .mrb-sm-185 {
    margin-bottom: 185px;
  }
}

@media (min-width: 768px) {
  .mrb-md-185 {
    margin-bottom: 185px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-185 {
    margin-bottom: 185px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-185 {
    margin-bottom: 185px;
  }
}

.pdl-xs-185 {
  padding-left: 185px;
}

@media (min-width: 544px) {
  .pdl-sm-185 {
    padding-left: 185px;
  }
}

@media (min-width: 768px) {
  .pdl-md-185 {
    padding-left: 185px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-185 {
    padding-left: 185px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-185 {
    padding-left: 185px;
  }
}

.pdr-xs-185 {
  padding-right: 185px;
}

@media (min-width: 544px) {
  .pdr-sm-185 {
    padding-right: 185px;
  }
}

@media (min-width: 768px) {
  .pdr-md-185 {
    padding-right: 185px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-185 {
    padding-right: 185px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-185 {
    padding-right: 185px;
  }
}

.mrt-xs-190 {
  margin-top: 190px;
}

@media (min-width: 544px) {
  .mrt-sm-190 {
    margin-top: 190px;
  }
}

@media (min-width: 768px) {
  .mrt-md-190 {
    margin-top: 190px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-190 {
    margin-top: 190px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-190 {
    margin-top: 190px;
  }
}

.mrb-xs-190 {
  margin-bottom: 190px;
}

@media (min-width: 544px) {
  .mrb-sm-190 {
    margin-bottom: 190px;
  }
}

@media (min-width: 768px) {
  .mrb-md-190 {
    margin-bottom: 190px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-190 {
    margin-bottom: 190px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-190 {
    margin-bottom: 190px;
  }
}

.pdl-xs-190 {
  padding-left: 190px;
}

@media (min-width: 544px) {
  .pdl-sm-190 {
    padding-left: 190px;
  }
}

@media (min-width: 768px) {
  .pdl-md-190 {
    padding-left: 190px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-190 {
    padding-left: 190px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-190 {
    padding-left: 190px;
  }
}

.pdr-xs-190 {
  padding-right: 190px;
}

@media (min-width: 544px) {
  .pdr-sm-190 {
    padding-right: 190px;
  }
}

@media (min-width: 768px) {
  .pdr-md-190 {
    padding-right: 190px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-190 {
    padding-right: 190px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-190 {
    padding-right: 190px;
  }
}

.mrt-xs-195 {
  margin-top: 195px;
}

@media (min-width: 544px) {
  .mrt-sm-195 {
    margin-top: 195px;
  }
}

@media (min-width: 768px) {
  .mrt-md-195 {
    margin-top: 195px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-195 {
    margin-top: 195px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-195 {
    margin-top: 195px;
  }
}

.mrb-xs-195 {
  margin-bottom: 195px;
}

@media (min-width: 544px) {
  .mrb-sm-195 {
    margin-bottom: 195px;
  }
}

@media (min-width: 768px) {
  .mrb-md-195 {
    margin-bottom: 195px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-195 {
    margin-bottom: 195px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-195 {
    margin-bottom: 195px;
  }
}

.pdl-xs-195 {
  padding-left: 195px;
}

@media (min-width: 544px) {
  .pdl-sm-195 {
    padding-left: 195px;
  }
}

@media (min-width: 768px) {
  .pdl-md-195 {
    padding-left: 195px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-195 {
    padding-left: 195px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-195 {
    padding-left: 195px;
  }
}

.pdr-xs-195 {
  padding-right: 195px;
}

@media (min-width: 544px) {
  .pdr-sm-195 {
    padding-right: 195px;
  }
}

@media (min-width: 768px) {
  .pdr-md-195 {
    padding-right: 195px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-195 {
    padding-right: 195px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-195 {
    padding-right: 195px;
  }
}

.mrt-xs-200 {
  margin-top: 200px;
}

@media (min-width: 544px) {
  .mrt-sm-200 {
    margin-top: 200px;
  }
}

@media (min-width: 768px) {
  .mrt-md-200 {
    margin-top: 200px;
  }
}

@media (min-width: 992px) {
  .mrt-lg-200 {
    margin-top: 200px;
  }
}

@media (min-width: 1200px) {
  .mrt-xl-200 {
    margin-top: 200px;
  }
}

.mrb-xs-200 {
  margin-bottom: 200px;
}

@media (min-width: 544px) {
  .mrb-sm-200 {
    margin-bottom: 200px;
  }
}

@media (min-width: 768px) {
  .mrb-md-200 {
    margin-bottom: 200px;
  }
}

@media (min-width: 992px) {
  .mrb-lg-200 {
    margin-bottom: 200px;
  }
}

@media (min-width: 1200px) {
  .mrb-xl-200 {
    margin-bottom: 200px;
  }
}

.pdl-xs-200 {
  padding-left: 200px;
}

@media (min-width: 544px) {
  .pdl-sm-200 {
    padding-left: 200px;
  }
}

@media (min-width: 768px) {
  .pdl-md-200 {
    padding-left: 200px;
  }
}

@media (min-width: 992px) {
  .pdl-lg-200 {
    padding-left: 200px;
  }
}

@media (min-width: 1200px) {
  .pdl-xl-200 {
    padding-left: 200px;
  }
}

.pdr-xs-200 {
  padding-right: 200px;
}

@media (min-width: 544px) {
  .pdr-sm-200 {
    padding-right: 200px;
  }
}

@media (min-width: 768px) {
  .pdr-md-200 {
    padding-right: 200px;
  }
}

@media (min-width: 992px) {
  .pdr-lg-200 {
    padding-right: 200px;
  }
}

@media (min-width: 1200px) {
  .pdr-xl-200 {
    padding-right: 200px;
  }
}

.text-xs-center {
  text-align: center;
}

@media (min-width: 544px) {
  .text-sm-center {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .text-md-center {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .text-lg-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .text-xl-center {
    text-align: center;
  }
}

.text-xs-left {
  text-align: left;
}

@media (min-width: 544px) {
  .text-sm-left {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left;
  }
}

.text-xs-right {
  text-align: right;
}

@media (min-width: 544px) {
  .text-sm-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .text-md-right {
    text-align: right;
  }
}

@media (min-width: 992px) {
  .text-lg-right {
    text-align: right;
  }
}

@media (min-width: 1200px) {
  .text-xl-right {
    text-align: right;
  }
}

.w-xs-0 {
  width: 0%;
}

@media (min-width: 544px) {
  .w-sm-0 {
    width: 0%;
  }
}

@media (min-width: 768px) {
  .w-md-0 {
    width: 0%;
  }
}

@media (min-width: 992px) {
  .w-lg-0 {
    width: 0%;
  }
}

@media (min-width: 1200px) {
  .w-xl-0 {
    width: 0%;
  }
}

.w-xs-5 {
  width: 5%;
}

@media (min-width: 544px) {
  .w-sm-5 {
    width: 5%;
  }
}

@media (min-width: 768px) {
  .w-md-5 {
    width: 5%;
  }
}

@media (min-width: 992px) {
  .w-lg-5 {
    width: 5%;
  }
}

@media (min-width: 1200px) {
  .w-xl-5 {
    width: 5%;
  }
}

.w-xs-10 {
  width: 10%;
}

@media (min-width: 544px) {
  .w-sm-10 {
    width: 10%;
  }
}

@media (min-width: 768px) {
  .w-md-10 {
    width: 10%;
  }
}

@media (min-width: 992px) {
  .w-lg-10 {
    width: 10%;
  }
}

@media (min-width: 1200px) {
  .w-xl-10 {
    width: 10%;
  }
}

.w-xs-15 {
  width: 15%;
}

@media (min-width: 544px) {
  .w-sm-15 {
    width: 15%;
  }
}

@media (min-width: 768px) {
  .w-md-15 {
    width: 15%;
  }
}

@media (min-width: 992px) {
  .w-lg-15 {
    width: 15%;
  }
}

@media (min-width: 1200px) {
  .w-xl-15 {
    width: 15%;
  }
}

.w-xs-20 {
  width: 20%;
}

@media (min-width: 544px) {
  .w-sm-20 {
    width: 20%;
  }
}

@media (min-width: 768px) {
  .w-md-20 {
    width: 20%;
  }
}

@media (min-width: 992px) {
  .w-lg-20 {
    width: 20%;
  }
}

@media (min-width: 1200px) {
  .w-xl-20 {
    width: 20%;
  }
}

.w-xs-25 {
  width: 25%;
}

@media (min-width: 544px) {
  .w-sm-25 {
    width: 25%;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25%;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25%;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25%;
  }
}

.w-xs-30 {
  width: 30%;
}

@media (min-width: 544px) {
  .w-sm-30 {
    width: 30%;
  }
}

@media (min-width: 768px) {
  .w-md-30 {
    width: 30%;
  }
}

@media (min-width: 992px) {
  .w-lg-30 {
    width: 30%;
  }
}

@media (min-width: 1200px) {
  .w-xl-30 {
    width: 30%;
  }
}

.w-xs-35 {
  width: 35%;
}

@media (min-width: 544px) {
  .w-sm-35 {
    width: 35%;
  }
}

@media (min-width: 768px) {
  .w-md-35 {
    width: 35%;
  }
}

@media (min-width: 992px) {
  .w-lg-35 {
    width: 35%;
  }
}

@media (min-width: 1200px) {
  .w-xl-35 {
    width: 35%;
  }
}

.w-xs-40 {
  width: 40%;
}

@media (min-width: 544px) {
  .w-sm-40 {
    width: 40%;
  }
}

@media (min-width: 768px) {
  .w-md-40 {
    width: 40%;
  }
}

@media (min-width: 992px) {
  .w-lg-40 {
    width: 40%;
  }
}

@media (min-width: 1200px) {
  .w-xl-40 {
    width: 40%;
  }
}

.w-xs-45 {
  width: 45%;
}

@media (min-width: 544px) {
  .w-sm-45 {
    width: 45%;
  }
}

@media (min-width: 768px) {
  .w-md-45 {
    width: 45%;
  }
}

@media (min-width: 992px) {
  .w-lg-45 {
    width: 45%;
  }
}

@media (min-width: 1200px) {
  .w-xl-45 {
    width: 45%;
  }
}

.w-xs-50 {
  width: 50%;
}

@media (min-width: 544px) {
  .w-sm-50 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .w-lg-50 {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .w-xl-50 {
    width: 50%;
  }
}

.w-xs-55 {
  width: 55%;
}

@media (min-width: 544px) {
  .w-sm-55 {
    width: 55%;
  }
}

@media (min-width: 768px) {
  .w-md-55 {
    width: 55%;
  }
}

@media (min-width: 992px) {
  .w-lg-55 {
    width: 55%;
  }
}

@media (min-width: 1200px) {
  .w-xl-55 {
    width: 55%;
  }
}

.w-xs-60 {
  width: 60%;
}

@media (min-width: 544px) {
  .w-sm-60 {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .w-md-60 {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .w-lg-60 {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .w-xl-60 {
    width: 60%;
  }
}

.w-xs-65 {
  width: 65%;
}

@media (min-width: 544px) {
  .w-sm-65 {
    width: 65%;
  }
}

@media (min-width: 768px) {
  .w-md-65 {
    width: 65%;
  }
}

@media (min-width: 992px) {
  .w-lg-65 {
    width: 65%;
  }
}

@media (min-width: 1200px) {
  .w-xl-65 {
    width: 65%;
  }
}

.w-xs-70 {
  width: 70%;
}

@media (min-width: 544px) {
  .w-sm-70 {
    width: 70%;
  }
}

@media (min-width: 768px) {
  .w-md-70 {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .w-lg-70 {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .w-xl-70 {
    width: 70%;
  }
}

.w-xs-75 {
  width: 75%;
}

@media (min-width: 544px) {
  .w-sm-75 {
    width: 75%;
  }
}

@media (min-width: 768px) {
  .w-md-75 {
    width: 75%;
  }
}

@media (min-width: 992px) {
  .w-lg-75 {
    width: 75%;
  }
}

@media (min-width: 1200px) {
  .w-xl-75 {
    width: 75%;
  }
}

.w-xs-80 {
  width: 80%;
}

@media (min-width: 544px) {
  .w-sm-80 {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .w-md-80 {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .w-lg-80 {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .w-xl-80 {
    width: 80%;
  }
}

.w-xs-85 {
  width: 85%;
}

@media (min-width: 544px) {
  .w-sm-85 {
    width: 85%;
  }
}

@media (min-width: 768px) {
  .w-md-85 {
    width: 85%;
  }
}

@media (min-width: 992px) {
  .w-lg-85 {
    width: 85%;
  }
}

@media (min-width: 1200px) {
  .w-xl-85 {
    width: 85%;
  }
}

.w-xs-90 {
  width: 90%;
}

@media (min-width: 544px) {
  .w-sm-90 {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .w-md-90 {
    width: 90%;
  }
}

@media (min-width: 992px) {
  .w-lg-90 {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .w-xl-90 {
    width: 90%;
  }
}

.w-xs-95 {
  width: 95%;
}

@media (min-width: 544px) {
  .w-sm-95 {
    width: 95%;
  }
}

@media (min-width: 768px) {
  .w-md-95 {
    width: 95%;
  }
}

@media (min-width: 992px) {
  .w-lg-95 {
    width: 95%;
  }
}

@media (min-width: 1200px) {
  .w-xl-95 {
    width: 95%;
  }
}

.w-xs-100 {
  width: 100%;
}

@media (min-width: 544px) {
  .w-sm-100 {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100%;
  }
}

body {
  margin: 0;
  padding: 0;
  font-size: 0.9375rem;
  font-family: 'Muli';
  color: #333;
  line-height: 1.5;
}

@media (max-width: 767px) {
  body {
    font-size: 0.875rem;
  }
}

html {
  overflow: auto;
  max-width: 100%;
  width: 100%;
}

.fullpage, #wrapper {
  overflow: hidden;
  max-width: 1600px;
  width: 100%;
  background: #fff;
  margin: 0 auto;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.fullpage.open, #wrapper.open {
  overflow: visible;
  -webkit-transform: translateX(280px);
  -khtml-transform: translateX(280px);
  -moz-transform: translateX(280px);
  -ms-transform: translateX(280px);
  -o-transform: translateX(280px);
  transform: translateX(280px);
}

a {
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

a:focus, a:active, a:hover, a:hover:focus, a:hover:active, a:hover:hover {
  outline: none;
  text-decoration: none;
}

input, button, select, textarea {
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

input:hover, input:active, input:focus, button:hover, button:active, button:focus, select:hover, select:active, select:focus, textarea:hover, textarea:active, textarea:focus {
  outline: none;
}

button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

img, iframe {
  vertical-align: middle;
}

main {
  clear: both;
}

main:before, main:after {
  content: '';
  display: table;
  width: 100%;
  clear: both;
}

strong span {
  font-weight: bold;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
    width: 100%;
  }
}

.content {
  line-height: 1.5;
  color: #333;
  font-size: 0.9375rem;
}

@media (max-width: 991px) {
  .content {
    font-size: 0.875rem;
  }
}

@media (max-width: 767px) {
  .content {
    font-size: 0.8125rem;
  }
}

.content ul, .content ol, .content p, .content h1, .content h2, .content h3, .content h4, .content h5, .content h6, .content img, .content figure {
  margin-bottom: 15px;
  line-height: 1.5;
}

@media (max-width: 543px) {
  .content ul, .content ol, .content p, .content h1, .content h2, .content h3, .content h4, .content h5, .content h6, .content img, .content figure {
    margin-bottom: 10px;
  }
}

.content figure {
  margin: 0 auto 15px;
  text-align: center;
}

@media (max-width: 543px) {
  .content figure {
    margin-bottom: 10px;
  }
}

.content figure img {
  margin-bottom: 5px;
}

.content figure figcaption {
  padding: 5px 0;
  text-align: center;
  font-size: 0.8125rem;
  font-style: italic;
  color: #666666;
}

.content ul {
  padding-left: 15px;
}

.content ul li {
  position: relative;
}

.content ul li:before {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background: #3b9e18;
  position: absolute;
  top: 8px;
  left: -10px;
}

.content h4 {
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 700;
  color: #3b9e18;
}

.content h5 {
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  font-weight: 600;
}

.col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.nopadding {
  padding: 0;
}

body {
  padding-top: 100px;
}

@media (max-width: 1199px) {
  body {
    padding-top: 60px;
  }
}

.homepage {
  padding-top: 0;
}

@media (max-width: 1199px) {
  .homepage {
    padding-top: 60px;
  }
}

header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  padding: 10px 0 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
  background: #fff;
}

@media (max-width: 1199px) {
  header {
    padding-top: 0;
  }
}

@media (max-width: 1199px) {
  header .headertop {
    float: right;
    padding: 15px 0;
  }
  header .headertop .container {
    max-width: unset;
    width: auto;
  }
}

header .social {
  float: left;
}

@media (max-width: 1199px) {
  header .social {
    display: none;
  }
}

header .social a {
  display: inline-block;
  font-size: 1.25rem;
  color: #000;
  line-height: 30px;
}

header .social a:hover {
  color: #3b9e18;
}

header .social a + a {
  margin-left: 10px;
}

header .colright {
  float: right;
}

header .colright .search {
  float: left;
}

header .colright .search .searchwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  width: 220px;
  border: 1px solid #000;
}

@media (max-width: 543px) {
  header .colright .search .searchwrap {
    width: 100px;
  }
}

header .colright .search .searchwrap .searchbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

header .colright .search .searchwrap input {
  background: none;
  line-height: 26px;
  border: none;
  color: #000;
  width: calc(100% - 30px);
  padding: 0px 10px;
}

header .colright .search .searchwrap .searchbutton {
  width: 30px;
  line-height: 26px;
  text-align: center;
  color: #000;
}

header .colright .language {
  float: left;
  margin-left: 20px;
}

header .colright .language a {
  display: inline-block;
  font-size: 0.9375rem;
  font-weight: 700;
  color: #333;
}

header .colright .language a + a {
  margin-left: 15px;
  position: relative;
  line-height: 30px;
}

header .colright .language a + a:before {
  content: '';
  display: block;
  width: 1px;
  height: 10px;
  background: #000;
  position: absolute;
  top: 10px;
  left: -6px;
}

header .colright .language a.active {
  color: #3b9e18;
}

header .headermain {
  position: relative;
}

@media (max-width: 1199px) {
  header .headermain {
    float: left;
  }
  header .headermain .container {
    max-width: unset;
    width: auto;
  }
}

header .btn-showmenu {
  float: left;
  font-size: 1.5rem;
  margin: 10px 15px 10px 0;
}

header .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.7;
  display: none;
}

header .sitelogo {
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -khtml-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (max-width: 1199px) {
  header .sitelogo {
    position: static;
    top: unset;
    -webkit-transform: translateX(0);
    -khtml-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    float: left;
    padding: 10px 0;
  }
}

@media (max-width: 543px) {
  header .sitelogo {
    padding: 17px 0;
  }
}

header .sitelogo a {
  display: block;
}

header .sitelogo a img {
  height: 50px;
}

@media (max-width: 1199px) {
  header .sitelogo a img {
    height: 40px;
  }
}

@media (max-width: 543px) {
  header .sitelogo a img {
    height: 20px;
  }
}

@media (max-width: 1199px) {
  header .menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 280px;
    background: #fff;
    -webkit-transition: all, 0.5s;
    -khtml-transition: all, 0.5s;
    -moz-transition: all, 0.5s;
    -ms-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    -webkit-transform: translateX(-280px);
    -khtml-transform: translateX(-280px);
    -moz-transform: translateX(-280px);
    -ms-transform: translateX(-280px);
    -o-transform: translateX(-280px);
    transform: translateX(-280px);
  }
  header .menu.open {
    -webkit-transform: translateX(0);
    -khtml-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@media (max-width: 1199px) {
  header .menu .sitelogo {
    float: none;
    padding: 20px 0;
    border-bottom: 2px solid #3b9e18;
    text-align: center;
  }
  header .menu .sitelogo img {
    height: 50px;
  }
}

header .menu .menuleft {
  float: left;
}

@media (max-width: 1199px) {
  header .menu .menuleft {
    float: none;
  }
}

header .menu .menuright {
  float: right;
}

@media (max-width: 1199px) {
  header .menu .menuright {
    float: none;
  }
}

header .menu ul.menulink > li {
  float: left;
}

@media (max-width: 1199px) {
  header .menu ul.menulink > li {
    float: none;
  }
}

header .menu ul.menulink > li + li {
  margin-left: 40px;
}

@media (max-width: 1199px) {
  header .menu ul.menulink > li + li {
    margin-left: 0;
  }
}

header .menu ul.menulink > li > a {
  display: block;
  color: #000;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 20px;
  padding: 20px 0;
}

@media (max-width: 1199px) {
  header .menu ul.menulink > li > a {
    padding: 15px 25px;
    border-bottom: 1px solid #e6e6e6;
  }
}

header .menu ul.menulink > li.hassub {
  position: relative;
}

header .menu ul.menulink > li.hassub .btn-showsub {
  display: none;
  font-size: 1.125rem;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: 7px;
  right: 10px;
}

@media (max-width: 1199px) {
  header .menu ul.menulink > li.hassub .btn-showsub {
    display: block;
  }
}

header .menu ul.menulink > li.hassub .sub {
  background: rgba(59, 158, 24, 0.9);
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -khtml-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  min-width: 250px;
  margin-top: 20px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

@media (max-width: 1199px) {
  header .menu ul.menulink > li.hassub .sub {
    position: static;
    top: unset;
    display: none;
    margin-top: 0;
    visibility: visible;
    opacity: 1;
    background: #f2f2f2;
    -webkit-transform: translateX(0);
    -khtml-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: all, 0s;
    -khtml-transition: all, 0s;
    -moz-transition: all, 0s;
    -ms-transition: all, 0s;
    -o-transition: all, 0s;
    transition: all, 0s;
  }
}

header .menu ul.menulink > li.hassub .sub > ul > li {
  position: relative;
}

header .menu ul.menulink > li.hassub .sub > ul > li > a {
  display: block;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 1199px) {
  header .menu ul.menulink > li.hassub .sub > ul > li > a {
    padding: 10px 25px;
    color: #333;
  }
}

header .menu ul.menulink > li.hassub .sub > ul > li:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
}

header .menu ul.menulink > li.hassub .sub > ul > li .nano {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 250px;
  background: rgba(42, 114, 17, 0.9);
}

header .menu ul.menulink > li.hassub .sub > ul > li .nano ul li a {
  display: block;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 1199px) {
  header .menu ul.menulink > li.hassub .sub > ul > li .nano ul li a {
    padding: 10px 25px;
    color: #333;
  }
}

header .menu ul.menulink > li.hassub .sub > ul > li .nano ul li a:hover {
  background: rgba(255, 255, 255, 0.1);
}

header .menu ul.menulink > li.hassub .sub > ul > li:hover .nano {
  display: block;
}

@media (min-width: 1200px) {
  header .menu ul.menulink > li:hover > a {
    color: #3b9e18;
  }
  header .menu ul.menulink > li:hover .sub {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }
}

header .menu ul.menulink > li.active > a {
  color: #3b9e18;
}

header.minimal {
  background: #fff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1);
}

.homepage header {
  background: transparent;
}

@media (max-width: 1199px) {
  .homepage header {
    background: #fff;
  }
}

.homepage header.minimal {
  background: #fff;
}

.searchpanel {
  position: absolute;
  top: 100%;
  right: -200px;
  min-width: 800px;
  z-index: 200;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
  border: 1px solid #e6e6e6;
  background: #fff;
  display: none;
}

@media (max-width: 1199px) {
  .searchpanel {
    position: static;
    top: unset;
    box-shadow: none;
    border: none;
    margin: 10px;
    min-width: unset;
    display: block;
  }
}

.searchpanel .nav-tabs {
  transform-origin: 0% 0%;
  -webkit-transform: rotateZ(90deg);
  -khtml-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
  position: absolute;
  top: -1px;
  right: -379px;
  background: #fff;
  width: 338px;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  border-bottom: 3px solid #0067ac;
}

@media (max-width: 1199px) {
  .searchpanel .nav-tabs {
    position: static;
    top: unset;
    width: 100%;
    -webkit-transform: rotateZ(0);
    -khtml-transform: rotateZ(0);
    -moz-transform: rotateZ(0);
    -ms-transform: rotateZ(0);
    -o-transform: rotateZ(0);
    transform: rotateZ(0);
    border-bottom: 2px solid #0067ac;
  }
}

.searchpanel .nav-tabs .nav-item {
  width: 100%;
  float: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.searchpanel .nav-tabs .nav-item + .nav-item {
  margin-left: 0;
}

.searchpanel .nav-tabs .nav-item .nav-link {
  border-radius: 0;
  font-family: 'open sans condensed';
  font-weight: 700;
  color: #dd0031;
  border: none;
}

@media (max-width: 1199px) {
  .searchpanel .nav-tabs .nav-item .nav-link {
    font-size: 0.8125rem;
    line-height: 30px;
    padding: 0;
  }
}

.searchpanel .nav-tabs .nav-item .nav-link.active {
  background: #0067ac;
  color: #fff;
  border: none;
}

.searchpanel .tab-content {
  min-height: 336px;
}

@media (max-width: 1199px) {
  .searchpanel .tab-content {
    height: calc(100vh - 129px);
    overflow: auto;
  }
}

.searchpanel .title {
  background: #ffdde5;
  font-family: 'open sans condensed';
  font-weight: 700;
  color: #dd0031;
  text-transform: uppercase;
  font-size: 0.8125rem;
  line-height: 30px;
  padding: 0px 10px;
}

@media (max-width: 1199px) {
  .searchpanel .title {
    text-transform: none;
  }
}

.searchpanel .nopadding {
  width: 25%;
}

@media (max-width: 1199px) {
  .searchpanel .nopadding {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .searchpanel .nopadding + .nopadding {
    border-left: 1px solid #e6e6e6;
  }
}

.searchpanel .itemlist {
  display: flex;
  flex-wrap: wrap;
}

.searchpanel .item {
  padding: 5px;
  border-bottom: 1px dashed #e6e6e6;
}

@media (max-width: 1199px) {
  .searchpanel .item {
    width: 100%;
  }
}

.searchpanel .item figure {
  display: flex;
  flex-wrap: wrap;
}

.searchpanel .item figure .itemimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.searchpanel .item figure .itemimg img {
  max-width: 90%;
  max-height: 90%;
}

.searchpanel .item figure figcaption {
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.searchpanel .item figure figcaption .itemname {
  font-size: 0.75rem;
}

.searchpanel .item figure figcaption .itemname a {
  color: #333;
}

.searchpanel .item figure figcaption .itemname a:hover {
  color: #0067ac;
}

.searchpanel .itemlist.news .item {
  width: 25%;
}

@media (max-width: 1199px) {
  .searchpanel .itemlist.news .item {
    width: 100%;
  }
}

.search-page {
  margin-bottom: 40px;
}

.search-page .resulttitle {
  font-size: 1.75rem;
  font-family: 'open sans condensed';
  text-transform: uppercase;
  font-weight: 700;
  color: #333;
  margin-bottom: 30px;
}

.search-page .resulttitle strong {
  color: #0067ac;
}

.search-page .resultlist {
  margin-bottom: 20px;
}

.search-page .resultlist .resultitem {
  font-size: 0.8125rem;
  color: #333;
  padding: 5px 10px;
  background: #f2f2f2;
}

.search-page .resultlist .resultitem strong {
  color: #ed1c24;
}

.search-page .resultlist .title {
  background: #dff2ff;
  font-family: 'open sans condensed';
  font-weight: 700;
  color: #0067ac;
  text-transform: uppercase;
  font-size: 0.8125rem;
  line-height: 30px;
  padding: 0px 10px;
}

.search-page .resultlist .nopadding {
  width: 25%;
}

@media (max-width: 991px) {
  .search-page .resultlist .nopadding {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .search-page .resultlist .nopadding + .nopadding {
    border-left: 1px solid #e6e6e6;
  }
}

.search-page .resultlist .itemlist {
  display: flex;
  flex-wrap: wrap;
}

.search-page .resultlist .item {
  padding: 5px;
  border-bottom: 1px dashed #e6e6e6;
}

@media (max-width: 991px) {
  .search-page .resultlist .item {
    width: 33.33%;
  }
}

@media (max-width: 767px) {
  .search-page .resultlist .item {
    width: 50%;
  }
}

@media (max-width: 543px) {
  .search-page .resultlist .item {
    width: 100%;
  }
}

.search-page .resultlist .item figure {
  display: flex;
  flex-wrap: wrap;
}

.search-page .resultlist .item figure .itemimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.search-page .resultlist .item figure .itemimg img {
  max-width: 90%;
  max-height: 90%;
}

.search-page .resultlist .item figure figcaption {
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.search-page .resultlist .item figure figcaption .itemname {
  font-size: 0.75rem;
}

.search-page .resultlist .item figure figcaption .itemname a {
  color: #333;
}

.search-page .resultlist .item figure figcaption .itemname a:hover {
  color: #0067ac;
}

.search-page .resultlist.news .item {
  width: 25%;
}

@media (max-width: 991px) {
  .search-page .resultlist.news .item {
    width: 33.33%;
  }
}

@media (max-width: 767px) {
  .search-page .resultlist.news .item {
    width: 50%;
  }
}

@media (max-width: 543px) {
  .search-page .resultlist.news .item {
    width: 100%;
  }
}

.search-page .hashtag {
  padding: 10px 0;
}

.search-page .hashtag a {
  display: inline-block;
  line-height: 30px;
  padding: 0px 10px;
  color: #333;
  font-weight: 600;
  border: 1px solid #e6e6e6;
  font-size: 0.875rem;
  margin: 0 5px 5px 0;
}

.search-page .hashtag a:hover {
  color: #0067ac;
  border-color: #0067ac;
  background: #dff2ff;
}

.search-page .hashtag a:before {
  content: '#';
}

.headtitle {
  font-size: 2.75rem;
  font-weight: 600;
  color: #0067ac;
  text-align: center;
  margin-bottom: 40px;
}

.pagetitle {
  font-size: 1.75rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #333;
  margin-bottom: 30px;
}

.pagetitle:after {
  content: '';
  display: block;
  width: 30px;
  height: 3px;
  background: #3b9e18;
  margin-top: 10px;
}

.hometitle {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 1199px) {
  .hometitle {
    font-size: 2.25rem;
  }
}

@media (max-width: 991px) {
  .hometitle {
    font-size: 2rem;
  }
}

.hometitle:after {
  content: '';
  display: block;
  width: 80px;
  height: 3px;
  background: #3b9e18;
  margin: 15px auto 0;
}

.pagedes {
  font-size: 0.875rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 80%;
}

@media (max-width: 1199px) {
  .pagedes {
    max-width: 90%;
  }
}

@media (max-width: 991px) {
  .pagedes {
    max-width: 100%;
  }
}

.home-welcome {
  padding: 40px 0;
}

.home-welcome .featurelist .featurecol {
  background: #3b9e18;
  height: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
}

@media (max-width: 1199px) {
  .home-welcome .featurelist .featurecol {
    margin-bottom: 30px;
    height: auto;
  }
}

.home-welcome .featurelist .featurecol figure {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}

.home-welcome .featurelist .featurecol .featureimg {
  width: 45px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-welcome .featurelist .featurecol .featureimg img {
  max-width: 100%;
  max-height: 100%;
}

.home-welcome .featurelist .featurecol figcaption {
  width: calc(100% - 45px);
  padding-left: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.home-welcome .featurelist .featurecol figcaption .number {
  width: 100%;
  font-size: 2.25rem;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  line-height: 1;
}

.home-welcome .featurelist .featurecol figcaption .des {
  width: 100%;
  font-weight: 600;
  color: #1e4310;
}

.home-service {
  padding: 40px 0;
  color: #fff;
}

.home-service .servicelist .servicecol figure .serviceimg {
  height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.home-service .servicelist .servicecol figure figcaption {
  text-align: center;
}

.home-tuvan {
  padding: 40px 0;
}

.home-tuvan .tuvanlist .row {
  margin: 0px -5px;
}

.home-tuvan .tuvanlist .col-xs-12 {
  padding: 0px 5px;
}

.home-tuvan .tuvanlist .tuvancol {
  margin-bottom: 10px;
}

.home-tuvan .tuvanlist .tuvancol figure {
  position: relative;
  overflow: hidden;
}

.home-tuvan .tuvanlist .tuvancol figure .tuvanimg {
  display: block;
  height: 180px;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

@media (max-width: 991px) {
  .home-tuvan .tuvanlist .tuvancol figure .tuvanimg {
    height: 100px;
  }
}

@media (max-width: 767px) {
  .home-tuvan .tuvanlist .tuvancol figure .tuvanimg {
    height: 200px;
  }
}

.home-tuvan .tuvanlist .tuvancol figure figcaption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

@media (max-width: 991px) {
  .home-tuvan .tuvanlist .tuvancol figure figcaption {
    padding: 5px;
  }
}

.home-tuvan .tuvanlist .tuvancol figure figcaption .tuvancaption {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px) {
  .home-tuvan .tuvanlist .tuvancol figure figcaption .tuvancaption {
    font-size: 0.75rem;
  }
}

.home-tuvan .tuvanlist .tuvancol.big figure .tuvanimg {
  height: 370px;
}

@media (max-width: 991px) {
  .home-tuvan .tuvanlist .tuvancol.big figure .tuvanimg {
    height: 210px;
  }
}

.home-tuvan .tuvanlist .tuvancol.text figure figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px;
  color: #fff;
}

@media (max-width: 991px) {
  .home-tuvan .tuvanlist .tuvancol.text figure figcaption {
    padding: 5px;
    font-size: 0.75rem;
  }
}

.home-tuvan .tuvanlist .tuvancol:hover figure .tuvanimg {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  -khtml-transform: scale3d(1.2, 1.2, 1);
  -moz-transform: scale3d(1.2, 1.2, 1);
  -ms-transform: scale3d(1.2, 1.2, 1);
  -o-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
  filter: brightness(120%);
}

.home-news {
  padding: 40px 0;
  color: #fff;
}

.home-news .homenews-list .row {
  margin: 0px -10px;
}

.home-news .homenews-list .col-xs-12 {
  padding: 0px 10px;
}

.home-news .homenews-list .newscol {
  margin-bottom: 20px;
}

.home-news .homenews-list .newscol figure {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  background: #3b9e18;
}

.home-news .homenews-list .newscol figure .newsimg {
  display: block;
  width: 50%;
  height: 200px;
}

@media (max-width: 543px) {
  .home-news .homenews-list .newscol figure .newsimg {
    width: 100%;
  }
}

.home-news .homenews-list .newscol figure figcaption {
  width: 50%;
  padding: 15px;
}

@media (max-width: 1199px) {
  .home-news .homenews-list .newscol figure figcaption {
    padding: 10px;
  }
}

@media (max-width: 543px) {
  .home-news .homenews-list .newscol figure figcaption {
    width: 100%;
  }
}

.home-news .homenews-list .newscol figure figcaption .newsname {
  font-size: 0.9375rem;
  font-weight: 700;
  margin-bottom: 15px;
}

@media (max-width: 1199px) {
  .home-news .homenews-list .newscol figure figcaption .newsname {
    font-size: 0.9375rem;
  }
}

.home-news .homenews-list .newscol figure figcaption .newsname:after {
  content: '';
  display: block;
  width: 80px;
  height: 1px;
  margin-top: 10px;
  background: #fff;
}

.home-news .homenews-list .newscol figure figcaption .newsname a {
  color: #fff;
}

.home-news .homenews-list .newscol figure figcaption .newsdes {
  color: #fff;
  font-size: 0.875rem;
  margin-bottom: 15px;
}

@media (max-width: 1199px) {
  .home-news .homenews-list .newscol figure figcaption .newsdes {
    font-size: 0.875rem;
  }
}

.home-news .homenews-list .newscol figure figcaption .link {
  color: #1e6006;
}

@media (max-width: 1199px) {
  .home-news .homenews-list .newscol figure figcaption .link {
    font-size: 0.8125rem;
  }
}

.home-news .homenews-list .newscol figure figcaption .link:hover {
  color: #fff;
  letter-spacing: 1px;
}

.home-news .homenews-list .newscol:nth-child(even) figure .newsimg {
  order: 2;
}

@media (max-width: 543px) {
  .home-news .homenews-list .newscol:nth-child(even) figure .newsimg {
    order: 1;
  }
}

.home-news .homenews-list .newscol:nth-child(even) figure figcaption {
  order: 1;
}

@media (max-width: 543px) {
  .home-news .homenews-list .newscol:nth-child(even) figure figcaption {
    order: 2;
  }
}

.home-news .homenews-list .newscol.big figure {
  position: relative;
}

.home-news .homenews-list .newscol.big figure .newsimg {
  height: 420px;
  width: 100%;
}

.home-news .homenews-list .newscol.big figure figcaption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
  width: 100%;
}

.home-news .homenews-list .newscol.big figure figcaption time {
  color: #3b9e18;
  display: block;
}

.home-news .homenews-list .newscol.big figure figcaption time:before {
  content: '\f073';
  font-family: 'fontawesome';
  margin-right: 5px;
}

.home-news .homenews-list .newscol.big figure figcaption .newsname {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0;
}

.home-news .homenews-list .newscol.big figure figcaption .newsname:after {
  display: none;
}

.home-news .homenews-list .newscol.big figure figcaption .newsname a {
  color: #fff;
}

.home-feedback {
  padding: 40px 0;
}

.home-feedback .hometitle:after {
  margin-left: 0;
}

.home-feedback .feedback-slide .slick-prev {
  top: -35px;
  right: 40px;
  left: unset;
  -webkit-transform: translateY(-100%);
  -khtml-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.home-feedback .feedback-slide .slick-next {
  top: -35px;
  right: 0;
  -webkit-transform: translateY(-100%);
  -khtml-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.home-feedback .feedback-slide .item {
  padding: 0;
}

.home-partner {
  padding: 40px 0;
}

.home-partner .hometitle:after {
  margin-left: 0;
}

.home-partner .partnerslide .slick-prev, .home-partner .partnerslide .slick-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -khtml-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
  font-size: 0;
  opacity: 0.5;
  color: #333;
}

.home-partner .partnerslide .slick-prev:before, .home-partner .partnerslide .slick-next:before {
  content: '';
  font-family: 'fontawesome';
  font-size: 2rem;
}

.home-partner .partnerslide .slick-prev:hover, .home-partner .partnerslide .slick-next:hover {
  opacity: 1;
  color: #0d1936;
}

.home-partner .partnerslide .slick-prev {
  left: -20px;
}

.home-partner .partnerslide .slick-prev:before {
  content: '\f104';
}

.home-partner .partnerslide .slick-next {
  right: -20px;
}

.home-partner .partnerslide .slick-next:before {
  content: '\f105';
}

.home-partner .partnerslide .slick-prev {
  top: -35px;
  right: 40px;
  left: unset;
  -webkit-transform: translateY(-100%);
  -khtml-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.home-partner .partnerslide .slick-next {
  top: -35px;
  right: 0;
  -webkit-transform: translateY(-100%);
  -khtml-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.home-partner .partnerlist .partnercol {
  border: 1px solid #e6e6e6;
  height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.home-partner .partnerlist .partnercol img {
  max-width: 60%;
  max-height: 60%;
}

.home-form {
  padding: 40px 0;
  color: #fff;
}

.home-form .form-wrap {
  max-width: 70%;
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .home-form .form-wrap {
    max-width: 80%;
  }
}

@media (max-width: 991px) {
  .home-form .form-wrap {
    max-width: 100%;
  }
}

.home-form .form-group {
  margin-bottom: 10px;
}

.home-form .form-group input, .home-form .form-group select, .home-form .form-group textarea {
  background: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 5px;
  width: 100%;
  padding: 10px 15px;
  color: #000;
}

.home-form .form-group input:hover, .home-form .form-group input:active, .home-form .form-group input:focus, .home-form .form-group select:hover, .home-form .form-group select:active, .home-form .form-group select:focus, .home-form .form-group textarea:hover, .home-form .form-group textarea:active, .home-form .form-group textarea:focus {
  background: rgba(255, 255, 255, 0.8);
}

.home-form .form-group select {
  padding: 9px 10px;
}

.home-form .select-option {
  margin-bottom: 15px;
}

.home-form .select-option label {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.home-form .select-option ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.home-form .select-option ul li {
  width: 25%;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .home-form .select-option ul li {
    width: 50%;
  }
}

@media (max-width: 543px) {
  .home-form .select-option ul li {
    width: 100%;
  }
}

.home-form .select-option .checkbox input[type="checkbox"], .home-form .select-option .cf input[type="checkbox"] {
  display: none;
}

.home-form .select-option .checkbox input[type="checkbox"] + label, .home-form .select-option .cf input[type="checkbox"] + label {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  margin-bottom: 0;
}

.home-form .select-option .checkbox input[type="checkbox"] + label:before, .home-form .select-option .cf input[type="checkbox"] + label:before {
  background: rgba(255, 255, 255, 0.5);
  border: none;
}

.home-form .select-option .checkbox input[type="checkbox"] + label:after, .home-form .select-option .cf input[type="checkbox"] + label:after {
  color: #3b9e18;
}

.home-form .select-option .checkbox input[type="checkbox"]:checked + label:before, .home-form .select-option .cf input[type="checkbox"]:checked + label:before {
  background: #fff;
}

.home-form label, .home-form input[type="reset"] {
  display: none;
}

.home-form .frm-btn {
  text-align: center;
  margin-top: 15px;
}

.home-form .frm-btn input[type="submit"] {
  display: inline-block;
  text-transform: uppercase;
  width: auto;
  color: #fff;
  padding: 10px 60px;
  border-radius: 100px;
  background: #3b9e18;
  border: none;
}

.home-form .frm-btn input[type="submit"]:hover {
  background: #4cca1f;
}

textarea {
  min-height: 100px;
}

footer {
  background: #0d1936;
}

footer .footertop {
  padding: 60px 0 40px;
}

footer .footercol {
  color: #fff;
  margin-bottom: 20px;
}

footer .title {
  font-size: 1.5rem;
  color: #47c63d;
  margin-bottom: 20px;
}

footer .title:after {
  content: '';
  display: block;
  width: 70px;
  height: 3px;
  background: #314575;
  margin-top: 5px;
}

footer .contact-detail ul li {
  color: #fff;
  font-size: 0.9375rem;
  position: relative;
  padding-left: 25px;
  line-height: 1.8;
}

footer .contact-detail ul li .fa {
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 1.125rem;
}

footer .contact-detail ul li a {
  color: #fff;
}

footer .contact-detail ul li a:hover {
  color: #3b9e18;
}

footer .footerlink ul li {
  line-height: 1.8;
}

footer .footerlink ul li a {
  color: #fff;
}

footer .footerlink ul li a:hover {
  color: #3b9e18;
}

footer .subscribewrap .module-title {
  color: #fff;
  margin-bottom: 10px;
}

footer .subscribewrap .subscribefrm {
  position: relative;
}

footer .subscribewrap .subscribefrm input {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 100px;
  line-height: 40px;
  padding: 0px 20px;
  color: #fff;
  width: 100%;
}

footer .subscribewrap .subscribefrm .subscribebutton {
  font-size: 0;
  width: 36px;
  height: 36px;
  border-radius: 100px;
  background: #47c63d;
  line-height: 36px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 2px;
  right: 2px;
}

footer .subscribewrap .subscribefrm .subscribebutton:before {
  content: '\f00c';
  font-family: 'fontawesome';
  font-size: 1.125rem;
}

footer .footerbottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

footer .footerbottom .copyright {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
  padding: 10px 0;
  float: left;
}

footer .footerbottom .footernav {
  float: right;
}

footer .footerbottom .footernav ul li {
  display: inline-block;
  padding: 10px 0;
}

footer .footerbottom .footernav ul li + li {
  margin-left: 25px;
}

footer .footerbottom .footernav ul li a {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.875rem;
}

footer .footerbottom .footernav ul li a:hover {
  color: #fff;
}

.up {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #3b9e18;
  color: #fff;
  line-height: 40px;
  text-align: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
  -webkit-transform: translateX(100px);
  -khtml-transform: translateX(100px);
  -moz-transform: translateX(100px);
  -ms-transform: translateX(100px);
  -o-transform: translateX(100px);
  transform: translateX(100px);
  cursor: pointer;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.up.active {
  -webkit-transform: translateX(0);
  -khtml-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.up:hover {
  background: #4cca1f;
}

.breadcrumbwrap {
  position: relative;
  z-index: 1;
}

.breadcrumbwrap .breadcrumbimg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(90%);
  overflow: hidden;
}

.breadcrumbwrap .breadcrumbimg img {
  width: 100%;
}

.breadcrumbwrap .breadcrumbimg .slick-list {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.breadcrumbwrap .breadcrumbimg .slick-track {
  height: 100%;
}

.breadcrumbwrap .breadcrumbimg .item .img {
  height: 100%;
}

.breadcrumbwrap .container {
  position: relative;
  z-index: 5;
}

.breadcrumbwrap .pagename {
  margin: 450px 0 10px;
  display: inline-block;
  font-size: 2.25rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.breadcrumbwrap .pagename:after {
  content: "";
  display: block;
  width: 60px;
  height: 1px;
  background: #fff;
  margin-top: 10px;
}

.breadcrumbwrap .breadcrumb {
  display: block;
  padding: 300px 0 15px;
  border-radius: 0;
  background: none;
  border: none;
  z-index: 1;
}

.breadcrumbwrap .breadcrumb li {
  float: left;
  color: #fff;
  font-size: 0.875rem;
}

.breadcrumbwrap .breadcrumb li:first-child:before {
  content: '\f015';
  font-family: 'fontawesome';
  margin-right: 5px;
  line-height: 1;
}

.breadcrumbwrap .breadcrumb li + li:before {
  content: '-';
  margin: 0px 10px;
}

.breadcrumbwrap .breadcrumb li a {
  color: #fff;
  font-weight: 600;
}

.breadcrumbwrap .breadcrumb li a:hover {
  text-decoration: underline;
}

.product-page {
  padding: 40px 0 0;
}

.product-category .catecol {
  margin-bottom: 40px;
}

.product-category .catecol figure .cateimg {
  display: block;
  margin-bottom: 15px;
  overflow: hidden;
}

.product-category .catecol figure .cateimg img {
  width: 100%;
  -webkit-transition: all, 0.3s;
  -khtml-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.product-category .catecol figure figcaption .catename {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.product-category .catecol figure figcaption .catename a {
  color: #999999;
}

.product-category .catecol figure figcaption .catename a:hover {
  color: #333;
}

.product-category .catecol figure figcaption .cateproduct li {
  display: inline-block;
  font-size: 0.8125rem;
  color: #666666;
}

.product-category .catecol figure figcaption .cateproduct li a {
  color: #666666;
}

.product-category .catecol figure figcaption .cateproduct li a:hover {
  color: #333;
}

.product-category .catecol figure figcaption .cateproduct li + li:before {
  content: ' | ';
}

.product-category .catecol figure:hover .cateimg img {
  -webkit-transform: scale3d(1.1, 1.1, 1);
  -khtml-transform: scale3d(1.1, 1.1, 1);
  -moz-transform: scale3d(1.1, 1.1, 1);
  -ms-transform: scale3d(1.1, 1.1, 1);
  -o-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}

.product-category .catecol figure:hover figcaption .catename a {
  color: #ed1c24;
}

.category-intro {
  margin-bottom: 60px;
  width: 80%;
}

@media (max-width: 1199px) {
  .category-intro {
    width: 100%;
  }
}

.category-intro .page-catename {
  font-size: 2rem;
  color: #666666;
  margin-bottom: 20px;
}

.category-intro .pagedes {
  color: #666666;
  font-size: 0.875rem;
  line-height: 1.8;
}

.product-list .row {
  margin: 0;
}

.product-list .col-xs-12 {
  padding: 0;
}

.productcol {
  padding-bottom: 30px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e6e6e6;
}

.productcol figure {
  text-align: center;
}

.productcol figure .colorimg {
  display: block;
}

.productcol figure .productimg {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.productcol figure .productimg img {
  max-width: 80%;
  max-height: 60%;
  -webkit-transition: all, 0.3s;
  -khtml-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.productcol figure .promotion {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  z-index: 5;
  color: #fff;
  font-size: 1rem;
  font-family: 'Dancing Script';
  position: absolute;
  right: 0;
  bottom: 0;
  background: #3b9e18;
}

.productcol figure figcaption .productname {
  font-size: 1.4375rem;
  margin-bottom: 5px;
}

@media (max-width: 1199px) {
  .productcol figure figcaption .productname {
    font-size: 1.25rem;
  }
}

@media (max-width: 991px) {
  .productcol figure figcaption .productname {
    font-size: 1.125rem;
  }
}

@media (max-width: 767px) {
  .productcol figure figcaption .productname {
    font-size: 1rem;
  }
}

@media (max-width: 543px) {
  .productcol figure figcaption .productname {
    font-size: 0.875rem;
  }
}

.productcol figure figcaption .productname a {
  color: #333;
}

.productcol figure figcaption .productcate {
  font-size: 0.8125rem;
  color: #666666;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .productcol figure figcaption .productcate {
    font-size: 0.75rem;
  }
}

.productcol figure figcaption .colorslide {
  width: 70%;
  margin: 0px auto 15px;
}

.productcol figure figcaption .colorslide .slick-prev, .productcol figure figcaption .colorslide .slick-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -khtml-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0rem;
  color: #666666;
}

.productcol figure figcaption .colorslide .slick-prev:before, .productcol figure figcaption .colorslide .slick-next:before {
  content: '';
  font-family: 'fontawesome';
  font-size: 0.875rem;
}

.productcol figure figcaption .colorslide .color {
  width: 24px;
  height: 24px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  margin-bottom: 5px;
}

.productcol figure figcaption .colorslide .new {
  width: 24px;
  font-size: 0.5rem;
  text-align: center;
  background: #ed1c24;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
  padding: 1px;
}

.productcol figure figcaption .productprice {
  display: flex;
  justify-content: center;
  align-items: center;
}

.productcol figure figcaption .productprice .old {
  color: #999999;
  text-decoration: line-through;
}

.productcol figure figcaption .productprice .new {
  font-size: 1.125rem;
  color: #ed1c24;
  margin-right: 10px;
}

.productcol figure:hover .productimg img {
  -webkit-transform: scale3d(1.1, 1.1, 1);
  -khtml-transform: scale3d(1.1, 1.1, 1);
  -moz-transform: scale3d(1.1, 1.1, 1);
  -ms-transform: scale3d(1.1, 1.1, 1);
  -o-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}

.productcol figure:hover figcaption .productname a {
  color: #ed1c24;
}

.product-nav {
  width: 20%;
  padding: 0px 20px;
  margin-bottom: 20px;
}

@media (max-width: 543px) {
  .product-nav {
    width: 100%;
    order: 2;
    margin-bottom: 10px;
  }
}

.product-nav .item {
  padding: 2px 0;
}

.product-nav .item .img {
  border: 1px solid transparent;
  -webkit-transition: all, 0.3s;
  -khtml-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.product-nav .item .img img {
  max-width: 100%;
  max-height: 100%;
}

.product-nav .item.slick-current .img {
  border: 1px solid #ed1c24;
}

.product-slide {
  width: 80%;
  margin-bottom: 60px;
}

@media (max-width: 543px) {
  .product-slide {
    width: 100%;
    order: 1;
  }
}

.product-slide .item a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-slide .item a img {
  max-width: 100%;
  max-height: 100%;
}

.page-productname {
  padding: 0 0 20px;
  font-size: 2rem;
  color: #ed1c24;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
}

.page-productname .productcate {
  color: #333;
}

.product-info {
  margin-bottom: 40px;
}

@media (max-width: 1199px) {
  .product-info {
    margin-bottom: 20px;
  }
}

.product-info .product-overview {
  padding: 20px 0;
  border-bottom: 1px dashed #e6e6e6;
}

.product-info .product-overview ul {
  margin-bottom: 0;
}

.product-info .product-overview ul li {
  font-size: 0.8125rem;
  position: relative;
  padding-left: 15px;
  color: gray;
  line-height: 1.8;
}

.product-info .product-overview ul li:before {
  content: '\f111';
  font-family: 'fontawesome';
  color: #ed1c24;
  position: absolute;
  top: 7px;
  left: 0;
  font-size: 0.125rem;
}

@media (max-width: 1199px) {
  .product-info .product-overview ul li {
    font-size: 0.75rem;
  }
}

.product-info .product-properties {
  padding: 20px 0;
  border-bottom: 1px dashed #e6e6e6;
}

.product-info .product-properties a + a {
  margin-left: 10px;
}

.product-info .product-color {
  padding: 20px 0;
  border-bottom: 1px dashed #e6e6e6;
}

.product-info .product-color label {
  font-size: 0.875rem;
  margin-bottom: 5px;
}

.product-info .product-color .nav-tabs {
  margin: 0;
  padding: 0;
  border: none;
}

.product-info .product-color .nav-tabs .nav-item {
  margin: 0;
  padding: 0;
  border: none;
}

.product-info .product-color .nav-tabs .nav-item + .nav-item {
  margin-left: 10px;
}

.product-info .product-color .nav-tabs .nav-item .nav-link {
  width: 26px;
  height: 26px;
  padding: 0;
  margin: 0 0 5px;
  border-radius: 50%;
  border: 1px solid #cccccc;
}

.product-info .product-color .nav-tabs .nav-item .nav-link.active {
  border-color: #ed1c24;
}

.product-info .product-color .nav-tabs .nav-item .new {
  font-size: 0.5625rem;
  text-transform: uppercase;
  color: #fff;
  background: #ed1c24;
  line-height: 1;
  text-align: center;
  padding: 1px;
}

.product-info .product-size {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 15px 0;
}

.product-info .product-size .label {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  margin-bottom: 5px;
  font-weight: 500;
}

.product-info .product-size .size, .product-info .product-size li {
  margin-right: 5px;
  margin-bottom: 5px;
}

.product-info .product-size .size a, .product-info .product-size li a {
  display: block;
  min-width: 32px;
  line-height: 30px;
  padding: 0px 10px;
  font-size: 0.8125rem;
  border: 1px solid #cccccc;
  text-align: center;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
  cursor: pointer;
  color: #999999;
  font-weight: bold;
}

.product-info .product-size .size a:hover, .product-info .product-size li a:hover {
  color: #0067ac;
}

.product-info .product-size .size a.active, .product-info .product-size li a.active {
  border-color: #0067ac;
  color: #0067ac;
}

.product-info .product-price {
  padding: 20px 0;
}

.product-info .product-price .new {
  color: #ed1c24;
  font-size: 1.75rem;
}

.product-info .share {
  margin-bottom: 20px;
}

.product-news {
  margin-bottom: 40px;
}

.product-news .news-list .newscol {
  margin-bottom: 30px;
}

.product-news .news-list .newscol figure .newsimg {
  display: block;
  margin-bottom: 20px;
}

.product-news .news-list .newscol figure .newsimg img {
  width: 100%;
}

.product-news .news-list .newscol figure figcaption .newsname {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.product-news .news-list .newscol figure figcaption .newsname a {
  color: #666666;
}

.product-news .news-list .newscol figure figcaption .newsname a:hover {
  color: #333;
}

.product-news .news-list .newscol figure figcaption .newsdes {
  font-size: 0.875rem;
  color: #666666;
}

.product-news .news-list .newscol figure figcaption .newsdes:hover {
  color: #ed1c24;
}

.product-news .news-list .newscol figure figcaption .newsdes:after {
  content: '\f105';
  font-family: 'fontawesome';
  margin-left: 5px;
  color: #ed1c24;
}

.product-description {
  margin-bottom: 40px;
}

.other-product {
  margin-bottom: 40px;
}

#map iframe {
  pointer-events: none;
}

#map iframe.active {
  pointer-events: all;
}

.contact-page {
  padding: 40px 0 0;
}

.contact-form {
  margin-bottom: 40px;
}

.contact-form .form-group {
  margin-bottom: 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  flex-wrap: wrap;
}

.contact-form .frm-captcha {
  width: 100%;
  clear: both;
  padding: 0px 15px;
}

.contact-form .frm-captcha label {
  width: 150px;
}

.contact-form .frm-captcha input {
  width: 100%;
}

.contact-form .frm-captcha-input {
  margin-right: 10px;
  width: 150px;
}

@media (max-width: 543px) {
  .contact-form .frm-captcha-input {
    width: 130px;
  }
}

.contact-form label {
  font-size: 0.875rem;
  padding: 10px 0;
  color: #666666;
  margin-bottom: -2px;
}

.contact-form input, .contact-form select, .contact-form textarea {
  width: 100%;
  border: 1px solid #e6e6e6;
  background: #f2f2f2;
  padding: 10px 15px;
  margin-bottom: -2px;
  border-radius: 3px;
}

.contact-form input[type="reset"] {
  display: none;
}

.contact-form .btnwrap, .contact-form .frm-btnwrap {
  width: 100%;
  text-align: right;
}

.contact-form .frm-btnwrap {
  display: block;
  border: none;
  padding: 0px 15px;
}

.contact-form .frm-btn {
  display: inline-block;
}

.contact-form input[type="submit"] {
  width: auto;
  color: #fff;
  background: #3b9e18;
  padding: 10px 100px;
  border: none;
}

.contact-form input[type="submit"]:hover {
  background: #2a7211;
}

.contact-info {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
  padding: 20px 0;
}

.contact-info ul {
  position: relative;
  z-index: 2;
  font-size: 1rem;
  line-height: 1.5;
  color: #666666;
}

.contact-info ul li {
  margin-bottom: 5px;
}

.contact-info .company {
  font-weight: bold;
  color: #3b9e18;
  font-size: 1.125rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.contact-info a {
  color: #3b9e18;
}

.contact-info a:hover {
  color: #3b9e18;
}

.contact-info:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}

.contact-info:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  -webkit-transform: rotateZ(-1deg);
  -khtml-transform: rotateZ(-1deg);
  -moz-transform: rotateZ(-1deg);
  -ms-transform: rotateZ(-1deg);
  -o-transform: rotateZ(-1deg);
  transform: rotateZ(-1deg);
}

.bannerwrap .hermes .tp-bullet {
  width: 12px;
  height: 12px;
  box-shadow: none;
  background: #fff;
}

.bannerwrap .hermes .tp-bullet.selected:after {
  background: #3b9e18;
}

.bannerwrap .uranus.tparrows {
  border-radius: 50%;
}

.bannerwrap .uranus.tparrows:before {
  font-size: 1.5rem;
}

.bannerwrap .uranus.tparrows:hover {
  background: rgba(0, 0, 0, 0.1);
}

.bannerwrap .uranus.tparrows:hover:before {
  color: #3b9e18;
}

.bannerwrap .tp-caption {
  text-align: center;
}

.bannerwrap .banner-caption {
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  display: inline-block;
  padding: 25px 50px;
  color: #333;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 1.5;
}

.about-page {
  margin: 40px 0;
}

.about-history {
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .about-history {
    text-align: center;
  }
}

.history-img {
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .history-img {
    margin-bottom: 20px;
  }
}

.history-img .item img {
  width: 100%;
}

.history-year {
  width: 250px;
  margin: 0px auto;
  padding: 0px 30px;
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .history-year {
    margin-bottom: 20px;
  }
}

.history-year .slick-prev, .history-year .slick-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -khtml-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0;
  color: #666666;
  opacity: 0.5;
  -webkit-transition: all, 0.3s;
  -khtml-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.history-year .slick-prev:before, .history-year .slick-next:before {
  content: '';
  font-family: 'fontawesome';
  font-size: 2.25rem;
}

.history-year .slick-prev:hover, .history-year .slick-next:hover {
  color: #ed1c24;
  opacity: 1;
}

.history-year .slick-prev {
  left: 0;
}

.history-year .slick-prev:before {
  content: '\f104';
}

.history-year .slick-next {
  right: 0;
}

.history-year .slick-next:before {
  content: '\f105';
}

.history-year .item {
  border-bottom: 2px dotted #e6e6e6;
}

.history-year .year {
  text-align: center;
  font-size: 4.5rem;
  font-weight: 900;
  color: #ed1c24;
}

.history-nav:before {
  content: '';
  display: block;
  width: 100%;
  height: 20px;
  background: url(../img/about/ruler.jpg) bottom left repeat-x;
}

.history-nav .item {
  text-align: center;
  padding: 10px 0;
  position: relative;
  cursor: pointer;
}

.history-nav .item:before {
  content: '';
  display: block;
  width: 100%;
  height: 0px;
  border-bottom: 2px dotted #e6e6e6;
  position: absolute;
  top: 45%;
}

.history-nav .item.slick-current {
  color: #ed1c24;
}

.history-nav .year {
  display: inline-block;
  font-weight: 700;
  position: relative;
  z-index: 1;
  background: #fff;
  padding: 0px 3px;
}

.fancybox-bg {
  background: rgba(0, 0, 0, 0.8);
}

.fancybox-button--close {
  background: #ed1c24;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.fancybox-button--close:hover {
  background: #c61017;
}

.radio input[type="radio"] {
  display: none;
}

.radio input[type="radio"] + label {
  padding: 5px 0 5px 20px;
  position: relative;
  color: #666666;
  -webkit-transition: all, 0.3s;
  -khtml-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.radio input[type="radio"] + label:hover {
  color: #333;
}

.radio input[type="radio"] + label:before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 0;
  border: 1px solid #999999;
}

.radio input[type="radio"] + label:after {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border: 4px solid #0d1936;
  position: absolute;
  top: 8px;
  left: 0;
  border-radius: 50px;
  opacity: 0;
  -webkit-transition: all, 0.3s;
  -khtml-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.radio input[type="radio"]:checked + label {
  color: #333;
}

.radio input[type="radio"]:checked + label:after {
  opacity: 1;
}

.checkbox input[type="checkbox"], .cf input[type="checkbox"] {
  display: none;
}

.checkbox input[type="checkbox"] + label, .cf input[type="checkbox"] + label {
  color: #333;
  padding: 0px;
  padding-left: 25px;
  position: relative;
}

.checkbox input[type="checkbox"] + label:hover, .cf input[type="checkbox"] + label:hover {
  color: #000;
}

.checkbox input[type="checkbox"] + label:before, .cf input[type="checkbox"] + label:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #cccccc;
  position: absolute;
  top: 2px;
  left: 0;
}

.checkbox input[type="checkbox"] + label:after, .cf input[type="checkbox"] + label:after {
  content: '\f00c';
  font-family: 'fontawesome';
  display: block;
  width: 16px;
  height: 16px;
  line-height: 14px;
  text-align: center;
  border-radius: 3px;
  color: #0d1936;
  border: 1px solid #cccccc;
  position: absolute;
  top: 2px;
  left: 0;
  z-index: 1;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
  opacity: 0;
}

.checkbox input[type="checkbox"]:checked + label:after, .cf input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.input-number {
  position: relative;
  width: 90px;
}

.input-number .btn-spin {
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}

.input-number .btn-spin.btn-dec {
  left: 0;
}

.input-number .btn-spin.btn-inc {
  right: 0;
}

.input-number input {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-size: 1.0625rem;
  font-weight: 700;
  color: #000;
  text-align: center;
  padding: 4px 10px;
}

.login-page .login-panel {
  margin: 60px 0;
  background: #fff;
  border-radius: 5px;
}

@media (max-width: 1199px) {
  .login-page .login-panel {
    margin: 60px 0;
  }
}

.login-page .sitelogo {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

.login-page .sitelogo img {
  height: 80px;
}

@media (max-width: 543px) {
  .login-page .sitelogo {
    margin-bottom: 30px;
  }
}

.login-page .headtitle {
  text-align: center;
  font-size: 1.125rem;
  margin-bottom: 30px;
}

.login-page .headtitle:after {
  content: '';
  margin: 10px auto;
}

.login-form {
  width: 100%;
  margin: 0 auto;
}

.login-form .title {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  border-left: 3px solid #ed1c24;
  padding-left: 10px;
  margin-bottom: 30px;
}

.login-form .module-title {
  font-size: 0.875rem;
  color: #666666;
  margin-bottom: 20px;
}

.login-form .module-title a {
  color: #ed1c24;
}

.login-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.login-form .form-group label {
  color: #999999;
  text-transform: uppercase;
  font-size: 1.125rem;
  display: block;
  width: 50px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  top: 6px;
  left: 0;
  border-right: 1px solid #e6e6e6;
}

.login-form .form-group input {
  width: 100%;
  font-size: 1.125rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 15px 30px 15px 60px;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.login-form .form-group input:active, .login-form .form-group input:hover, .login-form .form-group input:focus {
  border-color: #999999;
}

.login-form .forget {
  font-size: 0.875rem;
  color: #999999;
  margin-bottom: 30px;
}

.login-form .forget a {
  color: #ed1c24;
}

.login-form .forget a:hover {
  color: #000;
}

.login-form .btn-login {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  position: relative;
  color: #fff;
  background: #ed1c24;
  display: block;
  border-radius: 5px;
  overflow: hidden;
}

@media (max-width: 543px) {
  .login-form .btn-login {
    width: 100%;
  }
}

.login-form .btn-login:before {
  background: #c61017;
}

.login-form .btn-login:after {
  content: '\f105';
  font-family: 'fontawesome';
  position: absolute;
  top: 12px;
  right: 15px;
}

.login-form input[type='reset'] {
  display: none;
}

.login-form input[type="submit"] {
  width: 100%;
  color: #fff;
  background: none;
  padding: 15px 60px;
  border: none;
  text-transform: uppercase;
  font-size: 1rem;
}

.login-form .go-signup {
  text-align: center;
  margin-top: 15px;
}

.login-form .go-signup a {
  display: block;
  clear: both;
  color: #ed1c24;
}

.login-form .go-signup a:hover {
  color: #000;
}

.login-bg {
  width: 60%;
  position: relative;
  overflow: hidden;
  padding: 50px;
  border-radius: 5px;
}

@media (max-width: 991px) {
  .login-bg {
    width: 100%;
    order: 1;
  }
}

@media (max-width: 767px) {
  .login-bg {
    padding: 30px;
  }
}

.login-bg .login-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.login-bg .login-img .login-overlay {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.5;
  z-index: 1;
}

.login-bg .login-img img {
  height: 100%;
  width: auto;
  max-width: unset;
  filter: grayscale(100%);
}

@media (max-width: 991px) {
  .login-bg .login-img img {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .login-bg .login-img img {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    -khtml-transform: scale3d(1.1, 1.1, 1);
    -moz-transform: scale3d(1.1, 1.1, 1);
    -ms-transform: scale3d(1.1, 1.1, 1);
    -o-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}

@media (max-width: 543px) {
  .login-bg .login-img img {
    width: auto;
    height: 100%;
  }
}

.login-bg .login-des {
  position: relative;
  z-index: 1;
  color: #fff;
}

.login-bg .login-des .title {
  font-size: 2.875rem;
  font-weight: 300;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .login-bg .login-des .title {
    font-size: 2.25rem;
    margin-bottom: 10px;
  }
}

.login-bg .login-des .module-title {
  font-size: 1.625rem;
  font-weight: 200;
  opacity: 0.9;
}

@media (max-width: 767px) {
  .login-bg .login-des .module-title {
    font-size: 1.25rem;
  }
}

.login-bg .login-des .des {
  margin-top: 180px;
  font-size: 1.25rem;
}

@media (max-width: 991px) {
  .login-bg .login-des .des {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .login-bg .login-des .des {
    margin-top: 10px;
  }
}

.login-bg .login-des .des span {
  opacity: 0.8;
  font-weight: 200;
  display: inline-block;
  margin-right: 10px;
}

.login-bg .login-des .des a {
  display: inline-block;
  opacity: 1;
  font-weight: 300;
  font-size: 2rem;
  color: #fff;
}

@media (max-width: 543px) {
  .login-bg .login-des .des a {
    font-size: 1.625rem;
  }
}

.login-bg .login-des .des a:after {
  content: '\f105';
  font-family: 'fontawesome';
  margin-left: 5px;
}

.login-bg .login-des .des a:hover {
  color: #ed1c24;
}

.signup-page .signup-panel {
  margin: 40px 0;
  background: #fff;
  border-radius: 3px;
}

.signup-page .headtitle {
  text-align: center;
  font-size: 1.125rem;
  margin-bottom: 15px;
}

.signup-page .headtitle:after {
  content: '';
  margin: 5px auto;
}

.signup-form {
  width: 100%;
  position: relative;
}

.signup-form .title {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  border-left: 3px solid #ed1c24;
  padding-left: 10px;
  margin-bottom: 30px;
}

.signup-form .module-title {
  font-size: 0.875rem;
  color: #666666;
  margin-bottom: 20px;
}

.signup-form .module-title a {
  color: #ed1c24;
}

.signup-form .form-group {
  margin-bottom: 10px;
}

.signup-form .form-group label {
  color: #999999;
  text-transform: uppercase;
  font-size: 0.8125rem;
  margin-bottom: 5px;
}

.signup-form .form-group input, .signup-form .form-group select {
  width: 100%;
  font-size: 0.9375rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px 15px;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.signup-form .form-group input:active, .signup-form .form-group input:hover, .signup-form .form-group input:focus, .signup-form .form-group select:active, .signup-form .form-group select:hover, .signup-form .form-group select:focus {
  border-color: #999999;
}

.signup-form .form-group select {
  padding: 9px 15px;
}

.signup-form .gender-select ul {
  padding: 10px 0 0;
}

.signup-form .gender-select ul:after {
  display: table;
  content: '';
  width: 100%;
  clear: both;
}

.signup-form .gender-select ul li {
  width: 150px;
  float: left;
}

.signup-form .gender-select input[type="radio"] {
  display: none;
}

.signup-form .gender-select input[type="radio"] + label {
  font-weight: 500;
  color: #333;
  padding-left: 30px;
  position: relative;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.signup-form .gender-select input[type="radio"] + label:hover {
  color: #000;
}

.signup-form .gender-select input[type="radio"] + label:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #999999;
  opacity: 0.5;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.signup-form .gender-select input[type="radio"] + label:after {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border: 3px solid #fff;
  background: #ed1c24;
  opacity: 0;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 1px;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.signup-form .gender-select input[type="radio"]:checked + label:before, .signup-form .gender-select input[type="radio"]:checked + label:after {
  opacity: 1;
}

.signup-form .birthday ul {
  margin: 0 -10px;
}

.signup-form .birthday ul li {
  width: 33.33%;
  float: left;
  padding: 0 10px;
}

.signup-form .confirm {
  padding-top: 10px;
}

.signup-form .confirm .check-group input[type='checkbox'] {
  display: none;
}

.signup-form .confirm .check-group input[type='checkbox'] + label {
  color: #666666;
  font-weight: 300;
  padding-left: 30px;
  font-size: 0.875rem;
  position: relative;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.signup-form .confirm .check-group input[type='checkbox'] + label:hover {
  color: #000;
}

.signup-form .confirm .check-group input[type='checkbox'] + label:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #999999;
  opacity: 0.5;
  position: absolute;
  top: 1px;
  left: 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.signup-form .confirm .check-group input[type='checkbox'] + label:after {
  content: '\f00c';
  font-family: 'fontawesome';
  display: block;
  line-height: 14px;
  font-size: 0.875rem;
  color: #ed1c24;
  opacity: 0;
  position: absolute;
  top: 2px;
  left: 1px;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.signup-form .confirm .check-group input[type='checkbox']:checked + label:before, .signup-form .confirm .check-group input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.signup-form .confirm .confirm-des {
  margin: 15px 0 20px;
  color: #666666;
  font-size: 0.875rem;
}

.signup-form .confirm .confirm-des a {
  color: #ed1c24;
}

.signup-form .confirm .confirm-des a:hover {
  color: #000;
}

.signup-form .btn-signup {
  margin: 0 auto;
  width: 160px;
  text-align: center;
  position: relative;
  color: #fff;
  background: #ed1c24;
  display: block;
  border-radius: 5px;
}

@media (max-width: 543px) {
  .signup-form .btn-signup {
    width: 100%;
  }
}

.signup-form .btn-signup:before {
  background: #c61017;
}

.signup-form .btn-signup:after {
  content: '\f105';
  font-family: 'fontawesome';
  position: absolute;
  top: 8px;
  right: 15px;
}

.signup-form input[type='reset'] {
  display: none;
}

.signup-form input[type="submit"] {
  width: 100%;
  color: #fff;
  background: none;
  padding: 12px 40px;
  border: none;
  text-transform: uppercase;
  font-size: 1rem;
}

.signup-bg {
  width: 40%;
  position: relative;
  overflow: hidden;
  padding: 50px;
}

@media (max-width: 991px) {
  .signup-bg {
    width: 100%;
    order: 1;
  }
}

@media (max-width: 767px) {
  .signup-bg {
    padding: 30px;
  }
}

.signup-bg .signup-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.signup-bg .signup-img .signup-overlay {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.5;
  z-index: 1;
}

.signup-bg .signup-img img {
  height: 100%;
  width: auto;
  max-width: unset;
  filter: grayscale(100%);
}

@media (max-width: 991px) {
  .signup-bg .signup-img img {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .signup-bg .signup-img img {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    -khtml-transform: scale3d(1.1, 1.1, 1);
    -moz-transform: scale3d(1.1, 1.1, 1);
    -ms-transform: scale3d(1.1, 1.1, 1);
    -o-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}

@media (max-width: 543px) {
  .signup-bg .signup-img img {
    width: auto;
    height: 100%;
  }
}

.signup-bg .signup-des {
  position: relative;
  z-index: 1;
  color: #fff;
}

.signup-bg .signup-des .title {
  font-size: 2.875rem;
  font-weight: 300;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .signup-bg .signup-des .title {
    font-size: 2.25rem;
    margin-bottom: 10px;
  }
}

.signup-bg .signup-des .module-title {
  font-size: 1.625rem;
  font-weight: 200;
  opacity: 0.9;
}

@media (max-width: 767px) {
  .signup-bg .signup-des .module-title {
    font-size: 1.25rem;
  }
}

.signup-bg .signup-des .des {
  font-size: 1.25rem;
}

.signup-bg .signup-des .des span {
  opacity: 0.8;
  font-weight: 200;
  display: inline-block;
  margin-right: 10px;
}

.signup-bg .signup-des .des a {
  display: inline-block;
  opacity: 1;
  font-weight: 300;
  font-size: 1.5rem;
  color: #fff;
}

@media (max-width: 543px) {
  .signup-bg .signup-des .des a {
    font-size: 1.625rem;
  }
}

.signup-bg .signup-des .des a:after {
  content: '\f105';
  font-family: 'fontawesome';
  margin-left: 5px;
}

.signup-bg .signup-des .des a:hover {
  color: #ed1c24;
}

.user-sidebar {
  margin: 60px 0 20px;
  position: relative;
}

@media (max-width: 991px) {
  .user-sidebar:after {
    content: '\f107';
    font-family: 'fontawesome';
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    -khtml-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    font-size: 1.5rem;
  }
}

.user-sidebar .userinfo {
  background: #222;
}

.user-sidebar .userinfo figure {
  padding: 10px;
}

.user-sidebar .userinfo figure:after {
  content: '';
  display: table;
  width: 100%;
  clear: both;
}

.user-sidebar .userinfo figure .user-img {
  display: block;
  width: 80px;
  float: left;
}

@media (max-width: 1199px) {
  .user-sidebar .userinfo figure .user-img {
    width: 60px;
  }
}

.user-sidebar .userinfo figure figcaption {
  display: table-cell;
  padding: 15px;
}

@media (max-width: 1199px) {
  .user-sidebar .userinfo figure figcaption {
    padding: 7px 15px;
  }
}

.user-sidebar .userinfo figure figcaption .title {
  color: #fff;
  opacity: 0.8;
  font-weight: 300;
}

@media (max-width: 1199px) {
  .user-sidebar .userinfo figure figcaption .title {
    font-size: 0.8125rem;
  }
}

.user-sidebar .userinfo figure figcaption .user-name {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.125rem;
  color: #fff;
}

@media (max-width: 1199px) {
  .user-sidebar .userinfo figure figcaption .user-name {
    font-size: 0.9375rem;
  }
}

.user-sidelink {
  background: #f2f2f2;
  margin-bottom: 60px;
}

@media (max-width: 991px) {
  .user-sidelink {
    margin-bottom: 30px;
    display: none;
  }
}

.user-sidelink ul li {
  width: 100%;
}

.user-sidelink ul li:before {
  background: #ededed;
}

.user-sidelink ul li a {
  display: block;
  color: #333;
  padding: 9px 30px;
}

.user-sidelink ul li a:hover {
  color: #ed1c24;
}

.user-sidelink ul li.active {
  background: #e6e6e6;
  border-left: 3px solid #ed1c24;
}

.user-sidelink ul li.active a {
  font-weight: bold;
}

.user-page {
  margin-top: 60px;
  position: relative;
}

@media (max-width: 991px) {
  .user-page {
    margin-top: 0;
  }
}

.user-page .postname {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.user-page .postname:after {
  margin-top: 5px;
}

.user-page .posttitle {
  font-size: 1.125rem;
  line-height: 1.5;
  margin: 0 0 20px;
  min-height: 27px;
}

.user-page .link {
  font-size: 0.8125rem;
  color: #333;
  position: absolute;
  top: 3px;
  right: 0;
}

.user-page .link:after {
  content: '\f105';
  font-family: 'fontawesome';
  margin-left: 10px;
}

.user-page .link:hover {
  color: #ed1c24;
}

.user-page .btn-addaddr {
  font-size: 0.875rem;
  color: #333;
  position: absolute;
  top: 7px;
  right: 0;
}

.user-page .btn-addaddr:before {
  content: '\f044';
  font-family: 'fontawesome';
  margin-right: 5px;
}

.user-page .btn-addaddr:hover {
  color: #ed1c24;
}

.user-page .table {
  border-bottom: 1px solid #e6e6e6;
  font-size: 0.875rem;
  margin-bottom: 0;
}

.user-page .table a {
  color: #ed1c24;
}

.user-page .table a:hover {
  color: #000;
}

.user-page .table th {
  border: none;
  border-top: 2px solid #ed1c24;
  background: #222;
  color: #fff;
  white-space: nowrap;
}

.user-page .table td {
  border: none;
}

.user-page .table th, .user-page .table td {
  white-space: nowrap;
}

.user-page .table th:nth-child(3), .user-page .table td:nth-child(3) {
  white-space: normal;
}

@media (max-width: 767px) {
  .user-page .table th:nth-child(3), .user-page .table td:nth-child(3) {
    white-space: nowrap;
  }
}

.user-page .table tr:nth-child(even) {
  background: #f7f7f7;
}

.account-info, .coin-info {
  position: relative;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .account-info, .coin-info {
    margin-bottom: 20px;
  }
}

.account-info .info-detail, .coin-info .info-detail {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 15px 20px;
}

.account-info .info-detail .group, .coin-info .info-detail .group {
  margin-bottom: 10px;
}

.account-info .info-detail .group:last-child, .coin-info .info-detail .group:last-child {
  margin-bottom: 0;
}

.account-info .info-detail .group .label, .coin-info .info-detail .group .label {
  float: left;
  width: 100px;
}

@media (max-width: 543px) {
  .account-info .info-detail .group .label, .coin-info .info-detail .group .label {
    float: none;
    display: block;
    color: #999999;
  }
}

.account-info .info-detail .group .detail, .coin-info .info-detail .group .detail {
  display: table-cell;
  font-weight: 500;
}

@media (max-width: 543px) {
  .account-info .info-detail .group .detail, .coin-info .info-detail .group .detail {
    float: none;
    display: block;
    font-weight: bold;
  }
}

.account-form {
  width: 50%;
}

@media (max-width: 1199px) {
  .account-form {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .account-form {
    width: 70%;
  }
}

@media (max-width: 543px) {
  .account-form {
    width: 100%;
  }
}

.account-form .form-group {
  margin-bottom: 15px;
}

.account-form .form-group label {
  text-transform: uppercase;
  font-size: 0.8125rem;
  color: #666666;
  margin-bottom: 5px;
}

.account-form .form-group input, .account-form .form-group select {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 10px 15px;
  font-size: 0.875rem;
  -webkit-transition: all, 0.3s;
  transition: all, 0.3s;
}

.account-form .form-group input:hover, .account-form .form-group input:active, .account-form .form-group input:focus, .account-form .form-group select:hover, .account-form .form-group select:active, .account-form .form-group select:focus {
  border-color: #999999;
}

.account-form .gender-select ul {
  padding: 10px 0 0;
}

.account-form .gender-select ul:after {
  display: table;
  content: '';
  width: 100%;
  clear: both;
}

.account-form .gender-select ul li {
  width: 50%;
  float: left;
}

.account-form .gender-select input[type="radio"] {
  display: none;
}

.account-form .gender-select input[type="radio"] + label {
  font-weight: 500;
  color: #333;
  padding-left: 30px;
  text-transform: none;
  font-size: 0.9375rem;
  position: relative;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.account-form .gender-select input[type="radio"] + label:hover {
  color: #000;
}

.account-form .gender-select input[type="radio"] + label:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #999999;
  opacity: 0.5;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.account-form .gender-select input[type="radio"] + label:after {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border: 3px solid #fff;
  background: #ed1c24;
  opacity: 0;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 1px;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.account-form .gender-select input[type="radio"]:checked + label:before, .account-form .gender-select input[type="radio"]:checked + label:after {
  opacity: 1;
}

.account-form .birthday ul {
  margin: 0 -10px;
}

.account-form .birthday ul li {
  width: 33.33%;
  float: left;
  padding: 0 10px;
}

@media (max-width: 543px) {
  .account-form .birthday ul li select {
    font-size: 0.875rem;
    padding: 10px 10px;
  }
}

.account-form .check-group input[type='checkbox'] {
  display: none;
}

.account-form .check-group input[type='checkbox'] + label {
  color: #666666;
  padding-left: 30px;
  font-size: 0.875rem;
  position: relative;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.account-form .check-group input[type='checkbox'] + label:hover {
  color: #000;
}

.account-form .check-group input[type='checkbox'] + label:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #999999;
  opacity: 0.5;
  position: absolute;
  top: 1px;
  left: 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.account-form .check-group input[type='checkbox'] + label:after {
  content: '\f00c';
  font-family: 'fontawesome';
  display: block;
  line-height: 14px;
  font-size: 1rem;
  color: #ed1c24;
  opacity: 0;
  position: absolute;
  top: 2px;
  left: 1px;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.account-form .check-group input[type='checkbox']:checked + label:before, .account-form .check-group input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.account-form .check-group {
  margin-bottom: 15px;
}

.account-form .changepw-form {
  display: none;
}

.account-form input[type='submit'] {
  color: #fff;
  background: #ed1c24;
  padding: 12px 30px;
  border: none;
  text-transform: uppercase;
  margin: 5px 0 40px;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.account-form input[type='submit']:hover {
  background: #c61017;
}

.coin-info {
  height: 100%;
  margin-bottom: 30px;
  position: relative;
  padding-top: 47px;
}

@media (max-width: 991px) {
  .coin-info {
    padding-top: 0;
  }
}

.coin-info .info-detail {
  min-height: 86px;
}

@media (min-width: 768px) {
  .coin-info .info-detail .detail {
    line-height: 54px;
  }
}

.coin-info .info-detail .detail strong {
  color: #ed1c24;
}

.coin-page .notification {
  color: #ed1c24;
  margin-bottom: 10px;
}

.coin-page .coin-amount {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.coin-page .coin-amount strong {
  color: #ed1c24;
}

.coin-page .coin-history {
  padding-top: 20px;
  margin-bottom: 20px;
}

.cart-history {
  margin-bottom: 30px;
  position: relative;
}

.cart-history .pages {
  margin: 20px 0;
}

.cart-history .pages .pagination li {
  width: 36px;
  height: 36px;
  line-height: 36px;
}

.cart-history .module-title {
  margin-bottom: 20px;
}

.cart-history .content {
  margin-bottom: 20px;
}

.cart-history .content p {
  margin-bottom: 5px;
}

.cart-history .table-responsive {
  margin-bottom: 20px;
}

.cart-history .total-amount {
  float: right;
  width: 360px;
}

.cart-history .total-amount .label {
  width: 180px;
  float: left;
  text-align: right;
  padding-right: 10px;
}

.cart-history .total-amount .value {
  width: 180px;
  float: left;
  text-align: left;
  font-weight: 500;
  padding-left: 10px;
}

.cart-history .btn-back {
  clear: both;
  margin-bottom: 30px;
}

.cart-history .btn-back:hover {
  background: #222;
  color: #fff;
}

.address-info {
  margin-bottom: 60px;
  position: relative;
}

.address-info .address-list {
  margin: 0 -10px;
}

.address-info .address-col {
  width: 50%;
  padding: 0 10px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .address-info .address-col {
    width: 100%;
  }
}

.address-info .address-col .address {
  border: 1px solid #d9d9d9;
  padding: 15px 20px;
  border-radius: 3px;
  position: relative;
  height: 100%;
}

.address-info .address-col .address.default {
  border: 1px dashed #000;
}

.address-info .address-col .address .type {
  position: absolute;
  top: 5px;
  right: 10px;
  text-transform: uppercase;
  font-size: 0.8125rem;
  color: #ed1c24;
  font-weight: 500;
}

.address-info .address-col .address .name {
  font-size: 0.9375rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.address-info .address-col .address .addr-detail {
  font-size: 0.875rem;
  color: #666666;
  margin-bottom: 10px;
}

.address-info .address-col .address .btn-editaddr {
  display: inline-block;
  background: #f2f2f2;
  color: #333;
  padding: 7px 20px;
  font-size: 0.8125rem;
}

.address-info .address-col .address .btn-editaddr:after {
  content: '\f040';
  font-family: 'fontawesome';
  margin-left: 5px;
}

.address-info .address-col .address .btn-editaddr:hover {
  color: #ed1c24;
  background: #e6e6e6;
}

.address-info .address-form {
  width: 50%;
}

@media (max-width: 1199px) {
  .address-info .address-form {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .address-info .address-form {
    width: 70%;
  }
}

@media (max-width: 543px) {
  .address-info .address-form {
    width: 100%;
  }
}

.address-info .address-form .form-group {
  margin-bottom: 15px;
}

.address-info .address-form .form-group label {
  text-transform: uppercase;
  font-size: 0.8125rem;
  color: #666666;
  margin-bottom: 5px;
}

.address-info .address-form .form-group input, .address-info .address-form .form-group select, .address-info .address-form .form-group textarea {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 10px 15px;
  font-size: 0.875rem;
  -webkit-transition: all, 0.3s;
  transition: all, 0.3s;
}

.address-info .address-form .form-group input:hover, .address-info .address-form .form-group input:active, .address-info .address-form .form-group input:focus, .address-info .address-form .form-group select:hover, .address-info .address-form .form-group select:active, .address-info .address-form .form-group select:focus, .address-info .address-form .form-group textarea:hover, .address-info .address-form .form-group textarea:active, .address-info .address-form .form-group textarea:focus {
  border-color: #999999;
}

.address-info .address-form input[type="submit"] {
  color: #fff;
  background: #ed1c24;
  padding: 12px 30px;
  border: none;
  text-transform: uppercase;
  margin: 5px 0 40px;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.address-info .address-form input[type="submit"]:hover {
  background: #c61017;
}

.address-info .address-form .check-group input[type='checkbox'] {
  display: none;
}

.address-info .address-form .check-group input[type='checkbox'] + label {
  color: #666666;
  font-weight: 300;
  padding-left: 30px;
  font-size: 0.875rem;
  position: relative;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
  text-transform: none;
}

.address-info .address-form .check-group input[type='checkbox'] + label:hover {
  color: #000;
}

.address-info .address-form .check-group input[type='checkbox'] + label:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #999999;
  opacity: 0.5;
  position: absolute;
  top: 1px;
  left: 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.address-info .address-form .check-group input[type='checkbox'] + label:after {
  content: '\f00c';
  font-family: 'fontawesome';
  display: block;
  line-height: 14px;
  font-size: 1rem;
  color: #ed1c24;
  opacity: 0;
  position: absolute;
  top: 2px;
  left: 1px;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.address-info .address-form .check-group input[type='checkbox']:checked + label:before, .address-info .address-form .check-group input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.wishlist .wishlist-product {
  margin-bottom: 20px;
}

.wishlist .wishlist-product .product {
  padding: 20px 0;
  border-bottom: 1px solid #e6e6e6;
}

.wishlist .wishlist-product .product figure .product-img {
  display: block;
  width: 130px;
  height: 130px;
  padding: 10px;
  text-align: center;
  border: 1px solid #cccccc;
  float: left;
}

@media (max-width: 543px) {
  .wishlist .wishlist-product .product figure .product-img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
}

.wishlist .wishlist-product .product figure figcaption {
  display: block;
  width: calc(100% - 130px);
  float: left;
  padding: 15px 0px 15px 20px;
}

@media (max-width: 767px) {
  .wishlist .wishlist-product .product figure figcaption {
    padding: 0 0 0 20px;
  }
}

@media (max-width: 543px) {
  .wishlist .wishlist-product .product figure figcaption {
    padding-left: 0;
    width: 100%;
  }
}

.wishlist .wishlist-product .product figure figcaption .product-info {
  float: left;
}

@media (max-width: 767px) {
  .wishlist .wishlist-product .product figure figcaption .product-info {
    width: 100%;
    float: none;
  }
}

.wishlist .wishlist-product .product figure figcaption .product-name {
  display: inline-block;
  font-size: 0.8125rem;
  text-transform: uppercase;
  border-left: 3px solid #ed1c24;
  padding-left: 10px;
  margin-bottom: 5px;
}

@media (max-width: 543px) {
  .wishlist .wishlist-product .product figure figcaption .product-name {
    display: block;
  }
}

.wishlist .wishlist-product .product figure figcaption .product-name a {
  color: #333;
  font-size: 1.125rem;
  font-weight: bold;
}

.wishlist .wishlist-product .product figure figcaption .size {
  color: #666666;
}

@media (max-width: 543px) {
  .wishlist .wishlist-product .product figure figcaption .size {
    display: block;
  }
}

.wishlist .wishlist-product .product figure figcaption .size:before {
  content: '|';
  margin: 0 10px;
}

@media (max-width: 543px) {
  .wishlist .wishlist-product .product figure figcaption .size:before {
    display: none;
  }
}

.wishlist .wishlist-product .product figure figcaption .product-category {
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 17px;
}

@media (max-width: 767px) {
  .wishlist .wishlist-product .product figure figcaption .product-category {
    margin-bottom: 5px;
  }
}

.wishlist .wishlist-product .product figure figcaption .product-price {
  margin-bottom: 5px;
}

@media (max-width: 543px) {
  .wishlist .wishlist-product .product figure figcaption .product-price {
    margin-bottom: 15px;
  }
}

.wishlist .wishlist-product .product figure figcaption .product-price .old {
  display: inline-block;
  font-size: 1rem;
  color: #999999;
  text-decoration: line-through;
  font-style: italic;
}

.wishlist .wishlist-product .product figure figcaption .product-price .new {
  display: inline-block;
  font-size: 1.75rem;
  font-weight: 700;
  margin-left: 5px;
}

.wishlist .wishlist-product .product figure figcaption .button {
  float: right;
}

@media (max-width: 767px) {
  .wishlist .wishlist-product .product figure figcaption .button {
    width: 100%;
    float: none;
  }
}

.wishlist .wishlist-product .product figure figcaption .btn-addtocart {
  display: inline-block;
  padding: 7px 20px;
  color: #fff;
  font-size: 0.8125rem;
  background: #ed1c24;
  text-transform: uppercase;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

@media (max-width: 543px) {
  .wishlist .wishlist-product .product figure figcaption .btn-addtocart {
    width: calc(100% - 42px);
  }
}

.wishlist .wishlist-product .product figure figcaption .btn-addtocart:before {
  content: '\f07a';
  font-family: 'fontawesome';
  margin-right: 5px;
}

.wishlist .wishlist-product .product figure figcaption .btn-addtocart:hover {
  background: #c61017;
}

.wishlist .wishlist-product .product figure figcaption .btn-removecart {
  display: inline-block;
  margin-left: 5px;
  width: 33px;
  height: 33px;
  background: #e6e6e6;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.wishlist .wishlist-product .product figure figcaption .btn-removecart:hover {
  background: #cccccc;
  color: #ed1c24;
}

.RadCaptcha #ctl00_altContent2_ctl01_captcha_ctl01 {
  float: left;
}

.RadCaptcha #ctl00_altContent2_ctl01_captcha_ctl01 + p {
  float: left;
  margin-top: 0;
}

.RadCaptcha span {
  font-size: 0.8125rem;
  color: #ed1c24;
  margin-bottom: 5px;
  display: block;
  margin-bottom: 5px;
  margin-top: 15px;
}

.RadCaptcha p {
  margin-top: 0.875rem;
}

.RadCaptcha img {
  display: inline-block !important;
  margin-right: 0.9375rem;
  vertical-align: middle;
  height: 40px !important;
}

@media (max-width: 543px) {
  .RadCaptcha img {
    margin-right: 5px;
  }
}

.RadCaptcha input {
  width: 250px;
}

.RadCaptcha label {
  display: none;
}

.RadCaptcha .rcRefreshImage {
  display: inline-block !important;
  vertical-align: middle;
  position: relative;
  color: transparent;
  width: 30px;
  height: 30px;
}

@media (max-width: 543px) {
  .RadCaptcha .rcRefreshImage {
    position: absolute;
    right: 0;
    bottom: 5px;
  }
}

.RadCaptcha .rcRefreshImage:after {
  content: '\f021';
  font-family: "FontAwesome";
  font-size: 18px;
  color: #000;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -khtml-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.RadCaptcha #ctl00_mainContent_ctl03_captcha_ctl01 {
  float: left;
}

.RadCaptcha #ctl00_mainContent_ctl03_captcha_ctl01 + p {
  display: table-cell;
  padding: 7px 0;
}

.RadCaptcha #ctl00_mainContent_ctl03_captcha_ctl01 + p input {
  width: 100%;
}

.ActionMenu {
  z-index: 99999 !important;
}

.ActionMenu .ActionMenuTag {
  line-height: 1.5;
}

.ActionMenu .ActionMenuTag .fa {
  color: #1B4D79 !important;
  width: auto !important;
  height: auto !important;
  border: none !important;
  font-size: 11px !important;
  display: inline-block !important;
  float: left !important;
  border-radius: 0 !important;
  line-height: 1.1rem !important;
  margin-right: 4px !important;
}

.ActionMenu .ActionMenuBody {
  z-index: 99999 !important;
}

.ActionMenu .ActionMenuBody > dl dd > a {
  color: #1B4D79 !important;
  width: auto !important;
  height: auto !important;
  background: transparent;
}

.ActionMenu .ActionMenuBody > dl dd > a .fa {
  color: #1B4D79 !important;
  width: auto !important;
  height: auto !important;
  border: none !important;
  font-size: 11px !important;
  display: inline-block !important;
  float: left !important;
  border-radius: 0 !important;
  line-height: 1.1rem !important;
  margin-right: 4px !important;
}

.ActionMenu .ActionMenuBody > h4 {
  color: #333 !important;
}

.cmsadminpanel {
  z-index: 16000009;
}

/*Register Form*/
.subscribefrm {
  position: relative;
}

.subscribefrm .fa.fa-exclamation-triangle {
  color: red;
  font-size: 0.9375rem;
  display: block;
  position: absolute;
  top: 13px;
  right: 50px;
  text-indent: -9999px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
}

.subscribefrm .fa.fa-exclamation-triangle:after {
  content: '\f071';
  font-size: 12px;
  color: red;
  text-indent: 0;
  float: left;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.subscribefrm .fa-exclamation-triangle:before, .subscribefrm .fa-warning:before {
  margin-right: 5px;
}

.form-group {
  position: relative;
}

.form-group .fa.fa-exclamation-triangle {
  position: absolute;
  top: 12px;
  right: 15px;
  color: transparent;
  width: 15px;
  height: 15px;
}

.form-group .fa.fa-exclamation-triangle:before {
  content: '\f071';
  font-size: 12px;
  font-weight: 500;
  color: red;
  position: absolute;
}

.form-group .fa-exclamation-triangle:before, .form-group .fa-warning:before {
  margin-right: 5px;
}

.contact-form .form-group {
  position: relative;
}

.contact-form .form-group .fa.fa-exclamation-triangle {
  top: unset;
  bottom: 13px;
  right: 15px;
  font-size: 1.125rem;
}

.frm-thanks {
  padding: 15px;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 700;
  color: #ed1c24;
}

.hidden {
  display: none;
}

.share {
  text-align: left;
  clear: both;
}

.share.sc-right {
  text-align: right;
}

.share > div {
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 5px;
  vertical-align: top;
}

.share .google, .share .tweet {
  width: 60px;
}

.share .fb-share-button.fb_iframe_widget iframe, .share iframe.twitter-share-button {
  display: block;
}

.share .google {
  margin-top: -5px;
}

.searchresults .pagination {
  display: none;
}

.searchresults .altcontent1 {
  display: none;
}

.searchresults .pagination {
  text-align: left;
}

.searchresults .banner-home {
  display: none;
}

.searchresults .home-news {
  display: none;
}

.search-page {
  margin: 40px 0;
}

.search-page .searchresultsummary {
  display: none;
}

.search-page .page-header {
  border: none;
  padding: 0;
  margin: 0;
}

.search-page h1 {
  font-size: 21px;
  font-weight: 700;
  margin: 0 0 15px;
  text-transform: none;
  line-height: 1.5em;
  color: #000;
  display: inline-block;
}

.search-page .btnSearchWrap {
  position: relative;
}

.search-page .btnSearchWrap .btnSearch {
  filter: alpha(opacity=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  -ms-opacity: 1;
  -o-opacity: 1;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  bottom: 0;
  width: 34px;
}

.search-page .btnSearchWrap .btnSearch .fa {
  position: absolute;
  right: 0;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #d9d9d9;
  vertical-align: middle;
}

.search-page .btnSearchWrap .seachpage-btn {
  position: relative;
  height: 34px;
  width: 34px;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  -ms-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
}

.search-page .btn {
  background-color: #0067ac;
  color: #fff;
  border: none;
  -webkit-transition: all, 0.3s;
  -khtml-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.search-page .btn:hover, .search-page .btn:focus {
  background-color: #005893;
}

.search-page .searchsummary {
  margin: 10px 0 25px;
  display: none;
}

.search-page .searchsummary strong {
  color: #000;
}

.search-page .searchcontrols {
  margin: 15px 0;
}

.search-page .searchcontrols input[type="text"] {
  height: 35px;
  line-height: 35px;
  padding: 0 15px;
  border: 1px solid #d9d9d9;
  font-size: 0.9375rem;
  color: #000;
  border-radius: 0;
}

.search-page .searchcontrols input[type="submit"] {
  border: none;
  height: 35px;
  line-height: 35px;
  text-align: center;
  padding: 0 20px;
  color: #fff;
  background-color: #ed1c24;
}

.search-page .searchresultlist {
  margin-top: 20px;
}

.search-page .searchresult {
  border-bottom: 1px solid #d9d9d9;
  margin: 0 0 15px;
  padding: 0 0 15px;
}

.search-page .searchresult h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px;
  color: #000;
}

.search-page .searchresult h3 a {
  color: #ed1c24;
  text-decoration: none;
}

.search-page .searchresult h3 a:hover {
  color: #ed1c24;
  text-decoration: underline;
}

.search-page hr {
  display: none;
}

.suggestsearch {
  position: absolute !important;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 401;
}

.suggestsearch ul {
  margin: 0;
  padding: 0;
}

.suggestsearch ul li {
  list-style: none;
  display: block;
  border-bottom: 1px dotted #e6e6e6;
}

.suggestsearch ul li:before, .suggestsearch ul li:after {
  content: '';
  display: table;
  width: 100%;
  clear: both;
}

.suggestsearch ul li:hover, .suggestsearch ul li.selected {
  background: #e6e6e6;
}

.suggestsearch ul li.prdlist_icon {
  margin-bottom: 0;
  height: auto;
}

.suggestsearch ul li .product-img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
  float: left;
  background: #fff;
  border: 1px solid #e6e6e6;
}

.suggestsearch ul li .product-img img {
  max-height: 90%;
  max-width: 90%;
}

.suggestsearch ul li .prd-info {
  width: calc(100% - 100px);
  padding: 10px;
  float: left;
}

.suggestsearch ul li a {
  padding: 10px;
  display: block;
}

.suggestsearch ul li a .prd-info {
  text-align: left;
}

.suggestsearch ul li a .prd-info h3,
.suggestsearch ul li a .prd-info h4 {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 5px;
  line-height: 1.4em;
}

.suggestsearch ul li a .prd-info h3 {
  font-size: 14px;
  color: #c61017;
  font-weight: 700;
}

.suggestsearch ul li a .prd-info h4 {
  color: #c61017;
}

.suggestsearch ul li a .prd-info span {
  display: block;
}

.suggestsearch ul li a .prd-info span.price {
  color: #000;
}

.suggestsearch ul li a .prd-info span.price_original {
  color: #000;
  text-decoration: line-through;
}

.news-page {
  padding: 40px 0;
}

.newslist article {
  margin-bottom: 30px;
}

.newslist .newscol {
  position: relative;
  height: 100%;
}

.newslist .newscol:after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #3b9e18;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.newslist .newscol figure {
  position: relative;
}

.newslist .newscol figure .newsimg {
  display: block;
  position: relative;
  overflow: hidden;
}

.newslist .newscol figure .newsimg img {
  -webkit-transition: all, 10s;
  -khtml-transition: all, 10s;
  -moz-transition: all, 10s;
  -ms-transition: all, 10s;
  -o-transition: all, 10s;
  transition: all, 10s;
}

.newslist .newscol figure .newsimg:after {
  content: '\f0c1';
  font-family: 'fontawesome';
  background: rgba(59, 158, 24, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.125rem;
  align-content: center;
  text-transform: uppercase;
  font-style: italic;
  -webkit-transform: translateY(-100%);
  -khtml-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.newslist .newscol figure .newsimg time {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(13, 25, 54, 0.7);
  color: #fff;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 30px;
  padding: 0px 10px;
}

.newslist .newscol figure figcaption {
  padding: 20px 0;
}

.newslist .newscol figure figcaption .newsname {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
  min-height: 48px;
  line-height: 1.5;
}

.newslist .newscol figure figcaption .newsname a {
  color: #0d1936;
}

.newslist .newscol figure figcaption .newsdes {
  margin-bottom: 10px;
  font-size: 0.875rem;
  color: #999999;
}

.newslist .newscol figure figcaption .link {
  color: #3b9e18;
}

.newslist .newscol figure figcaption .link:after {
  content: '\f18e';
  font-family: 'fontawesome';
  margin-left: 5px;
  color: #000;
}

.newslist .newscol:hover:after {
  width: 100%;
}

.newslist .newscol:hover figure .newsimg img {
  -webkit-transform: scale3d(1.4, 1.4, 1);
  -khtml-transform: scale3d(1.4, 1.4, 1);
  -moz-transform: scale3d(1.4, 1.4, 1);
  -ms-transform: scale3d(1.4, 1.4, 1);
  -o-transform: scale3d(1.4, 1.4, 1);
  transform: scale3d(1.4, 1.4, 1);
  -webkit-transition: all, 10s;
  -khtml-transition: all, 10s;
  -moz-transition: all, 10s;
  -ms-transition: all, 10s;
  -o-transition: all, 10s;
  transition: all, 10s;
}

.newslist .newscol:hover figure .newsimg:after {
  -webkit-transform: translateY(0);
  -khtml-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.newslist .newscol:hover figure figcaption .link {
  letter-spacing: 1px;
}

.newslist .newscol:hover figure figcaption .link:after {
  color: #3b9e18;
}

.oversea article {
  margin-bottom: 30px;
}

.oversea .newscol figure {
  position: relative;
}

.oversea .newscol figure .newsimg {
  display: block;
  position: relative;
  overflow: hidden;
}

.oversea .newscol figure .newsimg img {
  -webkit-transition: all, 10s;
  -khtml-transition: all, 10s;
  -moz-transition: all, 10s;
  -ms-transition: all, 10s;
  -o-transition: all, 10s;
  transition: all, 10s;
}

.oversea .newscol figure .newsimg:after {
  content: '\f0c1';
  font-family: 'fontawesome';
  background: rgba(59, 158, 24, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2rem;
  align-content: center;
  text-transform: uppercase;
  font-style: italic;
  -webkit-transform: translateY(-100%);
  -khtml-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.oversea .newscol figure figcaption {
  padding: 20px 0;
}

.oversea .newscol figure figcaption:after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #3b9e18;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.oversea .newscol figure figcaption .newsname {
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.oversea .newscol figure figcaption .newsname a {
  color: #0d1936;
}

.oversea .newscol figure figcaption .link {
  color: #3b9e18;
}

.oversea .newscol figure figcaption .link:after {
  content: '\f18e';
  font-family: 'fontawesome';
  margin-left: 5px;
  color: #000;
}

.oversea .newscol figure:hover .newsimg img {
  -webkit-transform: scale3d(1.4, 1.4, 1);
  -khtml-transform: scale3d(1.4, 1.4, 1);
  -moz-transform: scale3d(1.4, 1.4, 1);
  -ms-transform: scale3d(1.4, 1.4, 1);
  -o-transform: scale3d(1.4, 1.4, 1);
  transform: scale3d(1.4, 1.4, 1);
  -webkit-transition: all, 10s;
  -khtml-transition: all, 10s;
  -moz-transition: all, 10s;
  -ms-transition: all, 10s;
  -o-transition: all, 10s;
  transition: all, 10s;
}

.oversea .newscol figure:hover .newsimg:after {
  -webkit-transform: translateY(0);
  -khtml-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.oversea .newscol figure:hover figcaption:after {
  width: 100%;
}

.oversea .newscol figure:hover figcaption .link {
  letter-spacing: 1px;
}

.oversea .newscol figure:hover figcaption .link:after {
  color: #3b9e18;
}

.pages .pagination {
  width: 100%;
  text-align: center;
}

.pages .pagination li {
  display: inline-block;
}

.pages .pagination li a, .pages .pagination li span {
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background: #0d1936;
  color: #fff;
  font-weight: 600;
  text-align: center;
}

.pages .pagination li.active a, .pages .pagination li.active span {
  background: #3b9e18;
}

.newsmain-img {
  margin-bottom: 20px;
  position: relative;
}

.newsmain-img img {
  width: 100%;
}

.newsmain-img figcaption {
  background: rgba(0, 0, 0, 0.5);
  padding: 15px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.newsmain-img figcaption .share img {
  width: unset;
}

.newspage-name {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 10px;
}

.newspage-name:after {
  content: '';
  display: block;
  width: 60px;
  height: 1px;
  background: #fff;
  margin-top: 5px;
}

.newsdetail {
  padding-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
}

.newsdetail time {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  background: #cef5c0;
  border-radius: 2px;
  border: 1px solid #3b9e18;
  color: #3b9e18;
  line-height: 1;
  padding: 3px 5px;
  margin-right: 15px;
}

.newsdetail time:before {
  content: '\f017';
  font-family: 'fontawesome';
  margin-right: 3px;
  font-weight: normal;
}

.newsdetail .share {
  display: inline-block;
  vertical-align: middle;
}

.register-info {
  border-radius: 5px;
  border: 2px dashed #ed1c24;
  background: #f7fdf5;
  padding: 20px 30px;
}

.register-info .module-title {
  color: black;
  font-weight: 600;
  font-size: 1rem;
  color: #3b9e18;
  margin-bottom: 15px;
}

.register-info .module-title:before {
  content: '\f044';
  font-family: 'fontawesome';
  font-weight: normal;
  margin-right: 5px;
}

.register-info .form-group {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.register-info .form-group label {
  width: 150px;
  padding: 10px 0;
}

.register-info .form-group input, .register-info .form-group textarea {
  width: calc(100% - 150px);
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  padding: 10px 15px;
}

.register-info .form-group input:hover, .register-info .form-group textarea:hover {
  border-color: #b3b3b3;
}

.register-info .form-group input:focus, .register-info .form-group textarea:focus {
  border-color: #3b9e18;
}

.register-info input[type="reset"] {
  display: none;
}

.register-info input[type="submit"] {
  display: inline-block;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 20px;
  line-height: 36px;
  background: #3b9e18;
  color: #fff;
  border: none;
  margin-left: 150px;
  margin-top: 10px;
}

.register-info input[type="submit"] .fa {
  margin-right: 5px;
}

.register-info input[type="submit"]:hover {
  background: #f14b52;
  color: #fff;
}

.btn-backpage {
  display: block;
  margin-top: 40px;
  line-height: 40px;
  font-weight: 600;
  color: #3b9e18;
  margin-bottom: 40px;
  background: #f2f2f2;
}

@media (max-width: 991px) {
  .btn-backpage {
    margin-top: 0;
  }
}

.btn-backpage:before {
  content: '\f104';
  font-family: 'fontawesome';
  margin: 0 10px;
}

.btn-backpage:hover {
  background: #0d1936;
  color: #fff;
}

.othertitle {
  font-size: 1.125rem;
  color: #3b9e18;
  font-weight: 700;
  margin-bottom: 15px;
  background: #f7f7f7;
  border-bottom: 2px solid #0d1936;
}

.othertitle span {
  display: inline-block;
  font-weight: 700;
  background: #0d1936;
  color: #fff;
  padding: 5px 10px;
}

.sidebar-category {
  margin-bottom: 40px;
}

.sidebar-category.only {
  margin-top: 40px;
}

.sidebar-category.cate2 {
  margin-top: 40px;
}

@media (max-width: 991px) {
  .sidebar-category.cate2 {
    margin-top: 0;
  }
}

.sidebar-category ul li {
  border-bottom: 1px solid #e6e6e6;
}

.sidebar-category ul li a {
  display: block;
  color: #666666;
  padding: 10px 0;
}

.sidebar-category ul li a:hover {
  color: #3b9e18;
  letter-spacing: 1px;
}

.sidebar-category ul li.active a {
  color: #3b9e18;
  font-weight: 700;
}

.sidebar-category ul li.active a:before {
  content: '\f105';
  font-family: 'fontawesome';
  margin-right: 5px;
}

.other-news {
  margin-bottom: 40px;
}

.other-news.newspost {
  margin-top: 40px;
}

@media (max-width: 991px) {
  .other-news.newspost {
    margin-top: 0;
  }
}

.other-news ul .news {
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 0;
}

.other-news ul .news figure {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.other-news ul .news figure .newsimg {
  width: 80px;
}

.other-news ul .news figure .newsimg img {
  max-width: 100%;
  max-height: 100%;
}

.other-news ul .news figure figcaption {
  width: calc(100% - 80px);
  padding-left: 10px;
}

.other-news ul .news figure figcaption .newsname {
  font-weight: 700;
  font-size: 0.875rem;
}

.other-news ul .news figure figcaption .newsname a {
  color: #333;
}

.other-news ul .news figure figcaption .newsname a:hover {
  color: #3b9e18;
}

.us-info {
  border-top: 1px dashed #3b9e18;
  margin-top: 15px;
  padding-top: 15px;
  margin-bottom: 30px;
}

.us-info p, .us-info ul {
  margin-bottom: 10px;
}

.us-info a {
  color: #333;
  font-weight: bold;
}

.us-info a:hover {
  color: #ed1c24;
}

.side-album {
  margin-bottom: 40px;
}

.side-album .albumlist {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -2px;
}

.side-album .albumlist .pic {
  width: 33.33%;
  padding: 2px;
}

.side-album .albumlist .pic a {
  display: block;
  overflow: hidden;
  position: relative;
}

.side-album .albumlist .pic a:before {
  content: '\f03e';
  font-family: 'fontawesome';
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(59, 158, 24, 0.5);
  -webkit-transform: translateY(-100%);
  -khtml-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
  z-index: 5;
}

.side-album .albumlist .pic a img {
  width: 100%;
  -webkit-transition: all, 5s;
  -khtml-transition: all, 5s;
  -moz-transition: all, 5s;
  -ms-transition: all, 5s;
  -o-transition: all, 5s;
  transition: all, 5s;
}

.side-album .albumlist .pic a:hover:before {
  -webkit-transform: translateY(0%);
  -khtml-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}

.side-album .albumlist .pic a:hover img {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  -khtml-transform: scale3d(1.2, 1.2, 1);
  -moz-transform: scale3d(1.2, 1.2, 1);
  -ms-transform: scale3d(1.2, 1.2, 1);
  -o-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
}

.side-album .btn-viewmore {
  color: #666666;
  text-transform: uppercase;
  font-style: italic;
  font-size: 0.8125rem;
  display: inline-block;
  margin-top: 10px;
}

.side-album .btn-viewmore:after {
  content: '\f105';
  font-family: 'fontawesome';
  margin-left: 5px;
}

.side-album .btn-viewmore:hover {
  color: #3b9e18;
  letter-spacing: 2px;
}

.news-video {
  margin-bottom: 40px;
}

.news-video .video {
  border-bottom: 1px solid #e6e6e6;
}

.news-video .video figure .videoimg {
  display: block;
  position: relative;
}

.news-video .video figure .videoimg:before {
  content: '\f04b';
  font-family: 'fontawesome';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #fff;
}

.news-video .video figure .videoimg img {
  width: 100%;
}

.news-video .video figure figcaption {
  padding: 10px 0;
}

.news-video .video figure figcaption .videoname {
  font-weight: 600;
  padding-left: 20px;
  position: relative;
  line-height: 1.5;
  font-size: 0.875rem;
}

.news-video .video figure figcaption .videoname:before {
  content: '\f03d';
  font-family: 'fontawesome';
  margin-right: 5px;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: normal;
  font-size: 0.75rem;
  color: #999999;
}

.news-video .video figure figcaption .videoname a {
  color: #333;
}

.news-video .video figure figcaption .videoname a:hover {
  color: #3b9e18;
}

.news-video .btn-viewmore {
  color: #666666;
  text-transform: uppercase;
  font-style: italic;
  font-size: 0.8125rem;
  display: inline-block;
  margin-top: 10px;
}

.news-video .btn-viewmore:after {
  content: '\f105';
  font-family: 'fontawesome';
  margin-left: 5px;
}

.news-video .btn-viewmore:hover {
  color: #ed1c24;
  letter-spacing: 2px;
}

.blog-page {
  padding: 40px 0;
}

.newsdetail {
  margin-bottom: 20px;
}

.newsdetail .newsinfo {
  margin-top: -20px;
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;
}

.newsdetail .newsinfo time, .newsdetail .newsinfo .share {
  display: inline-block;
}

.newsdetail .newsinfo time {
  margin-right: 15px;
  font-size: 0.75rem;
  background: #e6e6e6;
  color: #333;
  font-weight: 500;
  padding: 2px 8px;
  border-radius: 3px;
}

.other-news ul li a {
  display: block;
  color: #666666;
  padding: 10px 0;
  border-bottom: 1px dashed #e6e6e6;
}

.other-news ul li a:before {
  content: '\f1ea';
  font-family: 'fontawesome';
  margin-right: 5px;
}

.other-news ul li a:hover {
  color: #3b9e18;
}

.sortmenu {
  padding-top: 40px;
}

.sortmenu h4 {
  font-size: 1.75rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #333;
  margin-bottom: 30px;
  float: left;
}

.sortmenu h4:after {
  content: '';
  display: block;
  width: 30px;
  height: 3px;
  background: #3b9e18;
  margin-top: 10px;
}

.sortmenu select {
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  float: right;
}

.sortmenu select:hover {
  border-color: #0067ac;
}

.support-page {
  margin: 40px 0;
}

.support-page .nav-tabs {
  margin: 0;
  padding: 0;
  border: none;
  margin-bottom: 30px;
  text-align: center;
}

.support-page .nav-tabs .nav-item {
  display: inline-block;
  margin: 0;
  padding: 0;
  float: none;
}

.support-page .nav-tabs .nav-item + .nav-item {
  margin-left: 5px;
}

.support-page .nav-tabs .nav-item .nav-link {
  display: block;
  color: #333;
  padding: 7px 15px;
  border-radius: 50px;
  border: 1px solid #0067ac;
}

.support-page .nav-tabs .nav-item .nav-link a {
  color: #333;
}

.support-page .nav-tabs .nav-item .nav-link:hover {
  color: #ed1c24;
}

.support-page .nav-tabs .nav-item .nav-link.active {
  border-color: #ed1c24;
  background: #ed1c24;
  color: #fff;
}

.faqlist .faq {
  border: 1px solid #e6e6e6;
  margin-bottom: 15px;
  overflow: hidden;
}

.faqlist .faq .faqtitle {
  font-weight: 500;
  color: #333;
  padding: 15px 15px 15px 40px;
  position: relative;
  cursor: pointer;
}

.faqlist .faq .faqtitle:hover {
  color: #3b9e18;
}

.faqlist .faq .faqtitle:before {
  content: '\f059';
  font-family: 'fontawesome';
  margin-right: 10px;
  color: #0d1936;
  position: absolute;
  top: 15px;
  left: 15px;
}

.faqlist .faq .faqtitle:after {
  content: '\f105';
  font-family: 'fontawesome';
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 1.125rem;
  display: block;
  -webkit-transform: rotateZ(90deg);
  -khtml-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.faqlist .faq .faqtitle.active {
  color: #3b9e18;
  font-weight: 700;
}

.faqlist .faq .faqtitle.active:after {
  font-weight: normal;
  -webkit-transform: rotateZ(90deg) scale(-1, 1);
  -khtml-transform: rotateZ(90deg) scale(-1, 1);
  -moz-transform: rotateZ(90deg) scale(-1, 1);
  -ms-transform: rotateZ(90deg) scale(-1, 1);
  -o-transform: rotateZ(90deg) scale(-1, 1);
  transform: rotateZ(90deg) scale(-1, 1);
}

.faqlist .faq .faqcontent {
  display: none;
  border-top: 1px solid #e6e6e6;
  padding: 15px 15px 0 40px;
}

.send-nessage {
  margin: 40px 0;
  border: 1px solid #e6e6e6;
  background: #f7f7f7;
  padding: 10px 15px;
}

@media (max-width: 991px) {
  .send-nessage {
    margin-top: 0;
  }
}

.send-nessage .module-title {
  color: black;
  font-weight: 600;
  font-size: 1rem;
  color: #3b9e18;
  margin-bottom: 15px;
}

.send-nessage .module-title:before {
  content: '\f044';
  font-family: 'fontawesome';
  font-weight: normal;
  margin-right: 5px;
}

.send-nessage .form-group {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.send-nessage .form-group label {
  font-size: 0.875rem;
}

.send-nessage .form-group input, .send-nessage .form-group textarea {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 10px 15px;
}

.send-nessage .form-group input:hover, .send-nessage .form-group textarea:hover {
  border-color: #b3b3b3;
}

.send-nessage .form-group input:focus, .send-nessage .form-group textarea:focus {
  border-color: #0067ac;
}

.send-nessage input[type="reset"] {
  display: none;
}

.send-nessage input[type="submit"] {
  display: inline-block;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 20px;
  line-height: 36px;
  background: #3b9e18;
  color: #fff;
  border-radius: 0;
  border: none;
}

.send-nessage input[type="submit"] .fa {
  margin-right: 5px;
}

.send-nessage input[type="submit"]:hover {
  background: #f14b52;
  color: #fff;
}

.bookingform {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 20px;
  max-width: 600px;
  margin: 0px auto;
}

.bookingform input[type="reset"] {
  display: none;
}

.bookingform .form-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.bookingform .form-group label {
  width: 180px;
  font-size: 0.875rem;
  padding: 8px 0px;
}

.bookingform .form-group input, .bookingform .form-group ul {
  width: calc(100% - 180px);
}

.bookingform .form-group input, .bookingform .form-group select, .bookingform .form-group textarea {
  border: 1px solid #e6e6e6;
  padding: 8px 15px;
}

.bookingform .form-group select {
  padding: 7px 15px;
}

.bookingform .form-group.date ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.bookingform .form-group.date ul li {
  width: 33.33%;
}

.bookingform .form-group.date ul li select {
  width: 100%;
}

.bookingform .form-group.date ul li + li {
  margin-left: 10px;
}

.bookingform .form-group.nation label {
  width: 100%;
}

.bookingform .form-group.nation ul {
  width: 100%;
}

.bookingform .form-group.nation ul li {
  display: inline-block;
  margin-right: 10px;
}

.bookingform .form-group.text label {
  width: 100%;
}

.bookingform .form-group.text textarea {
  width: 100%;
}

.bookingform .form-group.dateb label {
  width: 100%;
}

.bookingform .form-group.dateb ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.bookingform .form-group.dateb ul li {
  width: 33.33%;
}

.bookingform .form-group.dateb ul li + li {
  margin-left: 10px;
}

.bookingform .form-group.dateb ul li input, .bookingform .form-group.dateb ul li select {
  width: 100%;
}

.bookingform .form-group.dateb ul li:last-child {
  position: relative;
}

.bookingform .form-group.dateb ul li:last-child:after {
  content: '\f073';
  font-family: 'fontawesome';
  position: absolute;
  right: 10px;
  bottom: 7px;
  color: #999999;
}

.bookingform .rcTable.rcSingle {
  margin-top: 10px;
}

.bookingform .riTextBox.riEnabled, .bookingform .riHover, .bookingform .riTextBox.riEnabled, .bookingform .riTextBox.riFocused {
  border: 1px solid #e6e6e6;
  padding: 8px 15px;
}

.bookingform .riTextBox.riEnabled:hover, .bookingform .riTextBox.riEnabled:active, .bookingform .riTextBox.riEnabled:focus, .bookingform .riHover:hover, .bookingform .riHover:active, .bookingform .riHover:focus, .bookingform .riTextBox.riEnabled:hover, .bookingform .riTextBox.riEnabled:active, .bookingform .riTextBox.riEnabled:focus, .bookingform .riTextBox.riFocused:hover, .bookingform .riTextBox.riFocused:active, .bookingform .riTextBox.riFocused:focus {
  border: 1px solid #e6e6e6 !important;
  padding: 8px 15px !important;
}

.bookingform .frm-btn input {
  width: auto;
}

.bookingform input[type="submit"] {
  display: inline-block;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 20px;
  line-height: 36px;
  background: #3b9e18;
  color: #fff;
  border: none;
  text-transform: uppercase;
}

.bookingform input[type="submit"] .fa {
  margin-right: 5px;
}

.bookingform input[type="submit"]:hover {
  background: #f14b52;
  color: #fff;
}

.bookingform input[type="submit"]:hover {
  background: #2a7211;
}

.student-feedback {
  margin-bottom: 40px;
}

.topbar {
  background: #3b9e18;
}

.topbar ul {
  padding: 5px 0;
  white-space: nowrap;
  text-align: center;
}

.topbar ul li {
  display: inline-block;
}

.topbar ul li a {
  display: block;
  line-height: 36px;
  border-radius: 50px;
  padding: 0px 25px;
  color: #fff;
}

.topbar ul li:hover a {
  background: rgba(255, 255, 255, 0.1);
}

.topbar ul li.active a {
  background: #fff;
  color: #3b9e18;
}

.blog-page .bloglist .blogcol {
  margin-bottom: 30px;
}

.blog-page .bloglist .blogcol figure .blogimg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 400px;
  position: relative;
}

.blog-page .bloglist .blogcol figure .blogimg:before {
  content: '\f0c1';
  font-family: 'fontawesome';
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: rgba(59, 158, 24, 0.5);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.blog-page .bloglist .blogcol figure .blogimg img {
  width: 100%;
  -webkit-transition: all, 10s;
  -khtml-transition: all, 10s;
  -moz-transition: all, 10s;
  -ms-transition: all, 10s;
  -o-transition: all, 10s;
  transition: all, 10s;
}

.blog-page .bloglist .blogcol figure figcaption {
  border: 1px solid #e6e6e6;
  border-top: none;
  text-align: center;
  padding: 50px 30px 30px;
  position: relative;
  z-index: 10;
}

.blog-page .bloglist .blogcol figure figcaption time {
  display: inline-block;
  line-height: 40px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background: #3b9e18;
  padding: 0px 40px;
  letter-spacing: 3px;
  position: absolute;
  top: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -khtml-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.blog-page .bloglist .blogcol figure figcaption .blogname {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 25px;
}

.blog-page .bloglist .blogcol figure figcaption .blogname a {
  color: #333;
}

.blog-page .bloglist .blogcol figure figcaption .blogname a:hover {
  color: #3b9e18;
}

.blog-page .bloglist .blogcol figure figcaption .blogdes {
  margin-bottom: 25px;
  color: #999999;
}

.blog-page .bloglist .blogcol figure figcaption .link {
  color: #3b9e18;
}

.blog-page .bloglist .blogcol figure figcaption .link:hover {
  letter-spacing: 2px;
}

.blog-page .bloglist .blogcol figure figcaption .link:after {
  content: '\f105';
  font-family: 'fontawesome';
  margin-left: 5px;
}

.blog-page .bloglist .blogcol figure:hover .blogimg img {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  -khtml-transform: scale3d(1.2, 1.2, 1);
  -moz-transform: scale3d(1.2, 1.2, 1);
  -ms-transform: scale3d(1.2, 1.2, 1);
  -o-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
}

.blog-page .bloglist .blogcol figure:hover .blogimg:before {
  opacity: 1;
}

.blog-page .bloglist .blogcol figure:hover figcaption .blogname a {
  color: #3b9e18;
}

.blog-page .bloglist .blogcol figure:hover figcaption .link {
  letter-spacing: 2px;
}

.feedback-slide .slick-prev, .feedback-slide .slick-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -khtml-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
  font-size: 0;
  opacity: 0.5;
  color: #333;
}

.feedback-slide .slick-prev:before, .feedback-slide .slick-next:before {
  content: '';
  font-family: 'fontawesome';
  font-size: 2rem;
}

.feedback-slide .slick-prev:hover, .feedback-slide .slick-next:hover {
  opacity: 1;
  color: #0d1936;
}

.feedback-slide .slick-prev {
  left: -20px;
}

.feedback-slide .slick-prev:before {
  content: '\f104';
}

.feedback-slide .slick-next {
  right: -20px;
}

.feedback-slide .slick-next:before {
  content: '\f105';
}

.feedback-slide .item {
  padding: 0px 15px;
}

.feedback-slide .item .feedback .content {
  padding: 15px 20px;
  border-radius: 5px;
  background: #3b9e18;
  color: #fff;
  position: relative;
  margin-bottom: 30px;
}

.feedback-slide .item .feedback .content:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-color: #3b9e18 transparent transparent #3b9e18;
  position: absolute;
  bottom: -20px;
  left: 40px;
}

.feedback-slide .item .feedback .user figure {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.feedback-slide .item .feedback .user figure .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.feedback-slide .item .feedback .user figure .img img {
  width: 100%;
}

.feedback-slide .item .feedback .user figure figcaption {
  width: calc(100% - 80px);
  padding-left: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.feedback-slide .item .feedback .user figure figcaption .name {
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  line-height: 1.5;
  width: 100%;
}

.feedback-slide .item .feedback .user figure figcaption .des {
  font-size: 0.875rem;
  color: #999999;
  width: 100%;
}

.gallery-page {
  padding: 40px 0;
}

.gallery-page .gallery-list .album {
  width: 33.33%;
}

.gallery-page .gallery-list .album .pic {
  display: block;
  position: relative;
  overflow: hidden;
}

.gallery-page .gallery-list .album .pic:before {
  content: '\f03e';
  font-family: 'fontawesome';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: rgba(59, 158, 24, 0.5);
  font-size: 1.75rem;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.gallery-page .gallery-list .album .pic img {
  width: 100%;
  -webkit-transition: all, 10s;
  -khtml-transition: all, 10s;
  -moz-transition: all, 10s;
  -ms-transition: all, 10s;
  -o-transition: all, 10s;
  transition: all, 10s;
}

.gallery-page .gallery-list .album .pic:hover img {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  -khtml-transform: scale3d(1.2, 1.2, 1);
  -moz-transform: scale3d(1.2, 1.2, 1);
  -ms-transform: scale3d(1.2, 1.2, 1);
  -o-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
}

.gallery-page .gallery-list .album .pic:hover:before {
  opacity: 1;
}

.gallery-page .gallery-list .video-col {
  margin-bottom: 30px;
}

.gallery-page .gallery-list .video-col figure .video-img {
  display: block;
  position: relative;
  overflow: hidden;
}

.gallery-page .gallery-list .video-col figure .video-img img {
  width: 100%;
  -webkit-transition: all, 10s;
  -khtml-transition: all, 10s;
  -moz-transition: all, 10s;
  -ms-transition: all, 10s;
  -o-transition: all, 10s;
  transition: all, 10s;
}

.gallery-page .gallery-list .video-col figure .video-img:before {
  content: '\f04b';
  font-family: 'fontawesome';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  font-size: 2.625rem;
  color: #fff;
  background: rgba(59, 158, 24, 0.5);
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.gallery-page .gallery-list .video-col figure figcaption {
  padding: 10px 0;
  font-weight: 600;
}

.gallery-page .gallery-list .video-col figure figcaption:before {
  content: '\f1c8';
  font-family: 'fontawesome';
  margin-right: 5px;
  font-weight: normal;
}

.gallery-page .gallery-list .video-col figure:hover .video-img:before {
  opacity: 1;
}

.landingpage {
  padding-top: 0;
}

.landing-page {
  width: 100%;
  height: 100vh;
  background: url(../img/landing/bg.jpg) center center no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .landing-page {
    height: unset;
    min-height: 100vh;
  }
}

.landing-page .sitelogo {
  padding-top: 25px;
}

.landing-page .footer {
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .landing-page .footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.landing-page .footer .social {
  float: left;
}

.landing-page .footer .social .label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  margin-right: 5px;
}

.landing-page .footer .social .social-link {
  display: inline-block;
  vertical-align: middle;
}

.landing-page .footer .social .social-link a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 50px;
  border: 2px solid #333;
  color: #333;
}

.landing-page .footer .social .social-link a + a {
  margin-left: 5px;
}

.landing-page .footer .copyright {
  float: right;
  color: #333;
  padding: 5px 0;
}

.object-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: calc(100vh - 152px);
}

@media (max-width: 767px) {
  .object-wrap {
    height: auto;
    margin: 20px 0;
  }
}

.object-wrap .book1 {
  position: relative;
  z-index: 5;
}

@media (max-width: 1199px) {
  .object-wrap .book1 {
    width: 250px;
  }
}

@media (max-width: 991px) {
  .object-wrap .book1 {
    width: 200px;
  }
}

.object-wrap .book2 {
  margin-left: -70px;
  position: relative;
  z-index: 10;
}

@media (max-width: 1199px) {
  .object-wrap .book2 {
    width: 250px;
    margin-left: -60px;
  }
}

@media (max-width: 991px) {
  .object-wrap .book2 {
    width: 200px;
    margin-left: -50px;
  }
}

.object-wrap .book3 {
  margin-left: -60px;
  position: relative;
  z-index: 3;
}

@media (max-width: 1199px) {
  .object-wrap .book3 {
    width: 230px;
  }
}

@media (max-width: 991px) {
  .object-wrap .book3 {
    width: 180px;
    margin-left: -70px;
  }
}

.object-wrap .ipad {
  position: relative;
  z-index: 5;
  margin-left: -50px;
  width: 266px;
  height: 304px;
}

@media (max-width: 767px) {
  .object-wrap .ipad {
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.object-wrap .ipad .img-slide {
  position: absolute;
  top: 27px;
  left: 36px;
  width: 195px;
  height: 246px;
}

.object-wrap .ipad .img-slide img {
  width: 195px;
  height: 246px;
  object-fit: cover;
}

.object-wrap .mobile-link {
  width: 100%;
}

.object-wrap .mobile-link a {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  color: #333;
  border: 1px solid #333;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.object-wrap .mobile-link a:after {
  content: '\f105';
  font-family: 'fontawesome';
  margin-left: 10px;
}
