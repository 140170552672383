.bannerwrap {
	.hermes {
		.tp-bullet {
			width: 12px;
			height: 12px;
			box-shadow: none;
			background: $white;
			&.selected {
				&:after {
					background: $green; } } } }
	.uranus.tparrows {
		// background: color-rgba($white,0.3)
		@include bor-rad(50%);
		&:before {
			@include fs(24rem); }
		// 	color: $green
		&:hover {
			background: color-rgba($black,0.1);
			&:before {
				color: $green; } } }
	.tp-caption {
		text-align: center; }
	.banner-caption {
		background: color-rgba($white,0.7);
		text-align: center;
		display: inline-block;
		// border-left: 5px solid $green
		padding: 25px 50px;
		color: $main;
		text-transform: uppercase;
		font-weight: 300;
		@include fs(36rem);
		line-height: 1.5; } }
