.headtitle {
	@include fs(44rem);
	font-weight: 600;
	color: $blue;
	text-align: center;
	margin-bottom: 40px; }
.pagetitle {
	@include fs(28rem);
	font-weight: 700;
	text-transform: uppercase;
	color: $main;
	margin-bottom: 30px;
	&:after {
		content: '';
		display: block;
		width: 30px;
		height: 3px;
		background: $green;
		margin-top: 10px; } }

.hometitle {
	@include fs(40rem);
	font-weight: 700;
	text-align: center;
	margin-bottom: 20px;
	@include media-breakpoint-down(lg) {
		@include fs(36rem); }
	@include media-breakpoint-down(md) {
		@include fs(32rem); }
	&:after {
		content: '';
		display: block;
		width: 80px;
		height: 3px;
		background: $green;
		margin: 15px auto 0; } }
.pagedes {
	@include fs(14rem);
	text-align: center;
	margin: 0 auto;
	margin-bottom: 30px;
	max-width: 80%;
	@include media-breakpoint-down(lg) {
		max-width: 90%; }
	@include media-breakpoint-down(md) {
		max-width: 100%; } }
.home-welcome {
	padding: 40px 0;
	.featurelist {
		.featurecol {
			background: $green;
			height: 100%;
			@include bor-rad(0 0 20px 0);
			@include media-breakpoint-down(lg) {
				margin-bottom: 30px;
				height: auto; }
			figure {
				@include flexbox;
				flex-wrap: wrap;
				padding: 15px;
				@include media-breakpoint-down(lg) {
 } }					// padding: 10px
			.featureimg {
				width: 45px;
				@include flexbox;
				justify-content: center;
				align-items: center;
				@include media-breakpoint-down(lg) {
 }					// width: 100%
				img {
					max-width: 100%;
					max-height: 100%; } }
			figcaption {
				width: calc(100% - 45px);
				padding-left: 15px;
				@include flexbox;
				flex-wrap: wrap;
				@include media-breakpoint-down(lg) {
					// width: 100%
 }					// padding-left: 0
				.number {
					width: 100%;
					@include fs(36rem);
					text-transform: uppercase;
					color: $white;
					font-weight: 600;
					line-height: 1;
					@include media-breakpoint-down(lg) {
 } }						// text-align: center
				.des {
					width: 100%;
					// +fs(16rem)
					font-weight: 600;
					color: #1e4310;
					@include media-breakpoint-down(lg) {
 } } } } } }						// text-align: center
.home-service {
	padding: 40px 0;
	color: $white;
	.servicelist {
		.servicecol {
			figure {
				.serviceimg {
					height: 100px;
					@include flexbox;
					justify-content: center;
					align-items: center;
					margin-bottom: 20px; }
				figcaption {
					text-align: center; } } } } }
.home-tuvan {
	padding: 40px 0;
	.tuvanlist {
		.row {
			margin: 0px -5px; }
		.col-xs-12 {
			padding: 0px 5px; }
		.tuvancol {
			margin-bottom: 10px;
			figure {
				@include pos(rel,null);
				overflow: hidden;
				.tuvanimg {
					display: block;
					height: 180px;
					@include transition(all,0.5s);
					@include media-breakpoint-down(md) {
						height: 100px; }
					@include media-breakpoint-down(sm) {
						height: 200px; } }
				figcaption {
					@include pos(abs, null 0 0 0);
					padding: 20px;
					background: linear-gradient(color-rgba($black,0),color-rgba($black,0.5));
					@include transition(all,0.5s);
					@include media-breakpoint-down(md) {
						padding: 5px; }
					.tuvancaption {
						@include fs(16rem);
						font-weight: 600;
						color: $white;
						text-shadow: 0 3px 10px color-rgba($black,0.1);
						@include media-breakpoint-down(md) {
							@include fs(12rem); } } } }
			&.big {
				figure {
					.tuvanimg {
						height: 370px;
						@include media-breakpoint-down(md) {
							height: 210px; } } } }
			&.text {
				figure {
					figcaption {
						@include pos(abs, 0 null 0 0);
						right: 0;
						background: none;
						@include flexbox;
						justify-content: center;
						align-items: center;
						padding: 0px 30px;
						color: $white;
						@include media-breakpoint-down(md) {
							padding: 5px;
							@include fs(12rem); } } } }
			&:hover {
				figure {
					.tuvanimg {
						@include transform(scale3d(1.2,1.2,1));
						filter: brightness(120%); }
					figcaption {
 } } } } } }						// background: linear-gradient(color-rgba($black,0),color-rgba($black,0.8))



.home-news {
	padding: 40px 0;
	color: $white;
	.homenews-list {
		.row {
			margin: 0px -10px; }
		.col-xs-12 {
			padding: 0px 10px; }
		.newscol {
			margin-bottom: 20px;
			figure {
				@include flexbox;
				flex-wrap: wrap;
				background: $green;
				.newsimg {
					display: block;
					width: 50%;
					height: 200px;
					@include media-breakpoint-down(xs) {
						width: 100%; } }
				figcaption {
					width: 50%;
					padding: 15px;
					@include media-breakpoint-down(lg) {
						padding: 10px; }
					@include media-breakpoint-down(xs) {
						width: 100%; }
					.newsname {
						@include fs(15rem);
						font-weight: 700;
						margin-bottom: 15px;
						@include media-breakpoint-down(lg) {
							@include fs(15rem); }
						&:after {
							content: '';
							display: block;
							width: 80px;
							height: 1px;
							margin-top: 10px;
							background: $white; }
						a {
							color: $white; } }
					.newsdes {
						color: $white;
						@include fs(14rem);
						margin-bottom: 15px;
						@include media-breakpoint-down(lg) {
							@include fs(14rem); } }
					.link {
						color: #1e6006;
						@include media-breakpoint-down(lg) {
							@include fs(13rem); }
						&:hover {
							color: $white;
							letter-spacing: 1px; } } } }
			&:nth-child(even) {
				figure {
					.newsimg {
						order: 2;
						@include media-breakpoint-down(xs) {
							order: 1; } }
					figcaption {
						order: 1;
						@include media-breakpoint-down(xs) {
							order: 2; } } } }
			&.big {
				figure {
					@include pos(rel,null);
					.newsimg {
						height: 420px;
						width: 100%; }
					figcaption {
						@include pos(abs, null 0 0 0);
						padding: 20px;
						background: linear-gradient(color-rgba($black,0),color-rgba($black,0.5));
						width: 100%;
						time {
							color: $green;
							display: block;
							&:before {
								content: '\f073';
								font-family: 'fontawesome';
								margin-right: 5px; } }
						.newsname {
							@include fs(20rem);
							font-weight: 600;
							margin-bottom: 0;
							&:after {
								display: none; }
							a {
								color: $white; } } } } } } } }
.home-feedback {
	padding: 40px 0;
	.hometitle {
		&:after {
			margin-left: 0; } }
	.feedback-slide {
		.slick-prev {
			top: -35px;
			right: 40px;
			left: unset;
			@include transform(translateY(-100%)); }
		.slick-next {
			top: -35px;
			right: 0;
			@include transform(translateY(-100%)); }
		.item {
			padding: 0; } } }

.home-partner {
	padding: 40px 0;
	.hometitle {
		&:after {
			margin-left: 0; } }
	.partnerslide {
		.slick-prev, .slick-next {
			@include pos(abs, 50% null null null);
			@include transform(translateY(-50%));
			z-index: 10;
			@include fs(0);
			opacity: 0.5;
			color: $main;
			&:before {
				content: '';
				font-family: 'fontawesome';
				@include fs(32rem); }
			&:hover {
				opacity: 1;
				color: $bluebg; } }
		.slick-prev {
			left: -20px;
			&:before {
				content: '\f104'; } }
		.slick-next {
			right: -20px;
			&:before {
				content: '\f105'; } }
		.slick-prev {
			top: -35px;
			right: 40px;
			left: unset;
			@include transform(translateY(-100%)); }
		.slick-next {
			top: -35px;
			right: 0;
			@include transform(translateY(-100%)); } }
	.partnerlist {
		.partnercol {
			border: 1px solid darken($white,10%);
			height: 100px;
			@include flexbox;
			justify-content: center;
			align-items: center;
			margin-bottom: 30px;
			img {
				max-width: 60%;
				max-height: 60%; } } } }
.home-form {
	padding: 40px 0;
	color: $white;
	.form-wrap {
		max-width: 70%;
		margin: 0 auto;
		@include media-breakpoint-down(lg) {
			max-width: 80%; }
		@include media-breakpoint-down(md) {
			max-width: 100%; } }
	.form-group {
		margin-bottom: 10px;
		input, select, textarea {
			background: color-rgba($white,0.5);
			border: none;
			@include bor-rad(5px);
			width: 100%;
			padding: 10px 15px;
			color: $black;
			&:hover, &:active, &:focus {
				background: color-rgba($white,0.8); } }
		select {
			padding: 9px 10px; } }
	.select-option {
		margin-bottom: 15px;
		label {
			display: inline-block;
			width: 100%;
			margin-bottom: 10px; }
		ul {
			@include flexbox;
			flex-wrap: wrap;
			li {
				width: 25%;
				margin-bottom: 10px;
				@include media-breakpoint-down(sm) {
					width: 50%; }
				@include media-breakpoint-down(xs) {
					width: 100%; } } }
		.checkbox, .cf {
			input[type="checkbox"] {
				display: none;
				+ label {
					display: inline-block;
					vertical-align: middle;
					color: $white;
					margin-bottom: 0;
					&:before {
						background: color-rgba($white,0.5);
						border: none; }
					&:after {
						color: $green; } }
				&:checked {
					+ label {
						&:before {
							background: $white; } } } } } }
	label, input[type="reset"] {
		display: none; }
	.frm-btn {
		text-align: center;
		margin-top: 15px;
		input[type="submit"] {
			display: inline-block;
			text-transform: uppercase;
			width: auto;
			color: $white;
			padding: 10px 60px;
			@include bor-rad(100px);
			background: $green;
			border: none;
			&:hover {
				background: lighten($green,10%); } } } }
textarea {
	min-height: 100px; }
