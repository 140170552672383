#map {
	iframe {
		pointer-events: none;
		&.active {
			pointer-events: all; } } }

.contact-page {
	padding: 40px 0 0; }
.contact-form {
	margin-bottom: 40px;
	.form-group {
		margin-bottom: 10px;
		@include flex;
		flex-wrap: wrap; }
	.frm-captcha {
		width: 100%;
		clear: both;
		padding: 0px 15px;
		label {
			width: 150px; }
		input {
			width: 100%; } }
	.frm-captcha-input {
		margin-right: 10px;
		width: 150px;
		@include media-breakpoint-down(xs) {
			width: 130px; } }
	label {
		@include fs(14rem);
		padding: 10px 0;
		color: darken($white,60%);
		margin-bottom: -2px; }
	input, select, textarea {
		width: 100%;
		border: 1px solid darken($white,10%);
		background: darken($white,5%);
		padding: 10px 15px;
		margin-bottom: -2px;
		@include bor-rad(3px); }
	input[type="reset"] {
		display: none; }
	.btnwrap, .frm-btnwrap {
		width: 100%;
		text-align: right; }
	.frm-btnwrap {
		display: block;
		border: none;
		padding: 0px 15px; }
	.frm-btn {
		display: inline-block; }
	input[type="submit"] {
		width: auto;
		color: $white;
		background: $green;
		padding: 10px 100px;
		border: none;
		&:hover {
			background: darken($green,10%); } } }

.contact-info {
	text-align: center;
	margin-bottom: 60px;
	@include pos(rel,null);
	padding: 20px 0;
	ul {
		@include pos(rel,null);
		z-index: 2;
		@include fs(16rem);
		line-height: 1.5;
		color: darken($white,60%);
		li {
			margin-bottom: 5px; } }
	.company {
		font-weight: bold;
		color: $green;
		@include fs(18rem);
		text-transform: uppercase;
		margin-bottom: 10px; }
	a {
		color: $green;
		&:hover {
			color: $green; } }
	&:before {
		content: '';
		@include pos(abs, 0 null 0 0);
		right: 0;
		background: $white;
		z-index: 1;
		box-shadow: 0 1px 10px color-rgba($black,0.2); }
	&:after {
		content: '';
		@include pos(abs, 0 null 0 0);
		right: 0;
		background: $white;
		box-shadow: 0 1px 10px color-rgba($black,0.2);
		@include transform(rotateZ(-1deg)); } }
